import React from 'react';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/DoctorInfo.css'; // Stil dosyasını içe aktar

const DoctorInfo = ({ appointments }) => {
  // Bu haftaki randevuları hesapla
  const thisWeekAppointments = appointments.filter(appointment => {
    const appointmentDate = moment(appointment.randevuTarihi);
    const startOfWeek = moment().startOf('week');
    const endOfWeek = moment().endOf('week');
    return appointmentDate.isBetween(startOfWeek, endOfWeek);
  });

  // Toplam randevu sayısını hesapla
  // const totalAppointments = appointments.length;
  const futureAppointments = appointments.filter(appointment => {
    const appointmentDate = moment(appointment.randevuTarihi);
    const now = moment();
    return appointmentDate.isAfter(now);
  });

  return (
    <div className="doctor-info card">
      <div className="row no-gutters">
        <div className="col-md-4">
          <img src="/doctorLogo.JPG" alt="Doctor Logo" className="card-img-top img-fluid" />
        </div>
        <div className="col-md-4 d-flex align-items-center justify-content-center">
          <div className="card-body">
            <h5 className="card-title">Prof. Dr. Burhan Kabay</h5>
            <div className="row">
              <div className="col">
                <p className="card-text label">Bu haftaki randevu sayısı: {thisWeekAppointments.length}</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <p className="card-text label">Toplam randevu sayısı: {futureAppointments.length}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 d-flex align-items-center justify-content-center">
          <div className="appointment-details-text">
            <p className="text-center font-weight-bold" style={{margin: 0, padding: '10px', background: 'white', borderRadius: '5px', fontWeight:'bold'}}>
              Randevu Detayları ve Düzenleme İçin İlgili Randevunun Üzerine Tıklayınız.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
  
}

export default DoctorInfo;
