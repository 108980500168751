import React from 'react';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/LeftSidebar.css'
import { useAuth } from '../contexts/AuthContext';

const LeftSidebar = () => {
  const { logout } = useAuth();
  return (
    <div className="left-sidebar p-3" style={{ backgroundColor: '#2c3e50', height: "100vh" }}>
      <div className="sidebar-menu list-group" style={{ backgroundColor: '#2c3e50' }}>
        {/* Menü öğeleri için list-group-item sınıfını kullanabiliriz */}
        <NavLink
          to="/panel"
          className={({ isActive }) => isActive ? "list-group-item list-group-item-action text-white active-link" : "list-group-item list-group-item-action text-white"}
          style={{ backgroundColor: '#2c3e50' }}
        >
          Panel
        </NavLink>
        <NavLink
          to="/randevu-olustur"
          className={({ isActive }) => isActive ? "list-group-item list-group-item-action text-white active-link" : "list-group-item list-group-item-action text-white"}
          style={{ backgroundColor: '#2c3e50' }}
        >
          Randevu Oluştur
        </NavLink>
        <NavLink
          to="/hasta-kayit/formu"
          className={({ isActive }) => isActive ? "list-group-item list-group-item-action text-white active-link" : "list-group-item list-group-item-action text-white"}
          style={{ backgroundColor: '#2c3e50' }}
        >
          Hasta Kayıt Formu
        </NavLink>
        <NavLink
          to="/hasta-kayit/raporu"
          className={({ isActive }) => isActive ? "list-group-item list-group-item-action text-white active-link" : "list-group-item list-group-item-action text-white"}
          style={{ backgroundColor: '#2c3e50' }}
        >
          Hasta Kayıt Raporu
        </NavLink>
        <NavLink
          to="/yatan-hasta-takip/formu"
          className={({ isActive }) => isActive ? "list-group-item list-group-item-action text-white active-link" : "list-group-item list-group-item-action text-white"}
          style={{ backgroundColor: '#2c3e50' }}
        >
          Yatan Hasta Takip Formu
        </NavLink>
        <NavLink
          to="/yatan-hasta-takip/raporu"
          className={({ isActive }) => isActive ? "list-group-item list-group-item-action text-white active-link" : "list-group-item list-group-item-action text-white"}
          style={{ backgroundColor: '#2c3e50' }}
        >
          Yatan Hasta Takip Raporu
        </NavLink>
        <NavLink
          to="/recete/recete"
          className={({ isActive }) => isActive ? "list-group-item list-group-item-action text-white active-link" : "list-group-item list-group-item-action text-white"}
          style={{ backgroundColor: '#2c3e50' }}
        >
          Reçete
        </NavLink>
        <NavLink
          to="/recete/raporu"
          className={({ isActive }) => isActive ? "list-group-item list-group-item-action text-white active-link" : "list-group-item list-group-item-action text-white"}
          style={{ backgroundColor: '#2c3e50' }}
        >
          Reçete Raporu
        </NavLink>
        <NavLink
          to="/tetkik-takip-istek/formu"
          className={({ isActive }) => isActive ? "list-group-item list-group-item-action text-white active-link" : "list-group-item list-group-item-action text-white"}
          style={{ backgroundColor: '#2c3e50' }}
        >
          Tetkik Takip İstek Formu
        </NavLink>
        <NavLink
          to="/tetkik-takip-istek/raporu"
          className={({ isActive }) => isActive ? "list-group-item list-group-item-action text-white active-link" : "list-group-item list-group-item-action text-white"}
          style={{ backgroundColor: '#2c3e50' }}
        >
          Tetkik Takip İstek Raporu
        </NavLink>
        <NavLink
          to="/uye-kayit/formu"
          className={({ isActive }) => isActive ? "list-group-item list-group-item-action text-white active-link" : "list-group-item list-group-item-action text-white"}
          style={{ backgroundColor: '#2c3e50' }}
        >
          Üye Kayıt Formu
        </NavLink>
        <NavLink
          to="/uye-kayit/raporu"
          className={({ isActive }) => isActive ? "list-group-item list-group-item-action text-white active-link" : "list-group-item list-group-item-action text-white"}
          style={{ backgroundColor: '#2c3e50' }}
        >
          Üye Kayıt Raporu
        </NavLink>
        <NavLink
          to="/kullanici-yonetimi"
          className={({ isActive }) => isActive ? "list-group-item list-group-item-action text-white active-link" : "list-group-item list-group-item-action text-white"}
          style={{ backgroundColor: '#2c3e50' }}
        >
          Kullanıcı Yönetimi
        </NavLink>
        <NavLink
          to="/eMail-Yonetimi"
          className={({ isActive }) => isActive ? "list-group-item list-group-item-action text-white active-link" : "list-group-item list-group-item-action text-white"}
          style={{ backgroundColor: '#2c3e50' }}
        >
          E-Postalar
        </NavLink>
        {/* Diğer NavLink öğeleri benzer şekilde */}
        {/* ... */}
      </div>
      <div className="user-info mt-4 d-flex justify-content-center">
        {/* <h5 className="user-name mb-3">John Doe</h5> */}
        <div className="user-actions">
          {/* <button className="btn btn-primary btn-sm mb-2">Şifre Değiştir</button>
          <button className="btn btn-secondary btn-sm">E-mail Değiştir</button> */}
          <button className="btn btn-danger" onClick={logout}>Çıkış Yap</button> {/* `onClick` olayında `logout` fonksiyonunu çağırın */}
        </div>
      </div>
    </div>
  );
}

export default LeftSidebar;
