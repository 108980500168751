// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes,} from 'react-router-dom';
import Panel from './pages/Panel';
import RandevuOlustur from './pages/RandevuOlustur';
import HastaKayitFormu from './pages/HastaKayitFormu';
import HastaKayitRaporu from './pages/HastaKayitRaporu';
import YatanHastaTakipFormu from './pages/YatanHastaTakipFormu';
import YatanHastaTakipRaporu from './pages/YatanHastaTakipRaporu';
import Recete from './pages/Recete';
import ReceteRaporu from './pages/ReceteRaporu';
import TetkikTakipIstekFormu from './pages/TetkikTakipIstekFormu';
import TetkikTakipIstekRaporu from './pages/TetkikTakipIstekRaporu';
import UyeKayitFormu from './pages/UyeKayitFormu';
import UyeTakipRaporu from './pages/UyeKayitRaporu';
import LoginPage from './pages/LoginPage';
import KullaniciYonetimi from './pages/KullaniciYonetimi';
import EmailList from './pages/EmailList';
import RegisterPage from './pages/RegisterPage';
import ProtectedRoute from './pages/ProtectedRoute';
// import { useAuth } from './contexts/AuthContext'; // AuthContext'i import et
import { AuthProvider } from './contexts/AuthContext';
// import LeftSidebar from './components/LeftSidebar';


const App = () => {
  // const { isAuthenticated } = useAuth();
  // const { logout } = useAuth();
  return (
    <AuthProvider> {/* AuthProvider ile context sağlama */}
    <Router>
      <div>
      
        {/*<NavigationBar />*/}
        <Routes>
          <Route path="/panel" element={
              <ProtectedRoute>
                <Panel />
              </ProtectedRoute>
            } />
          <Route path="/randevu-olustur" element={
              <ProtectedRoute>
                <RandevuOlustur />
              </ProtectedRoute>
            } />
          <Route path="/hasta-kayit/formu" element={
              <ProtectedRoute>
                <HastaKayitFormu />
              </ProtectedRoute>
            } />
          <Route path="/hasta-kayit/raporu" element={
              <ProtectedRoute>
                <HastaKayitRaporu />
              </ProtectedRoute>
            } />
          <Route path="/yatan-hasta-takip/formu" element={
              <ProtectedRoute>
                <YatanHastaTakipFormu />
              </ProtectedRoute>
            } />
          <Route path="/yatan-hasta-takip/raporu" element={
              <ProtectedRoute>
                <YatanHastaTakipRaporu />
              </ProtectedRoute>
            } />
          <Route path="/recete/recete" element={
              <ProtectedRoute>
                <Recete />
              </ProtectedRoute>
            } />
          <Route path="/recete/raporu" element={
              <ProtectedRoute>
                <ReceteRaporu />
              </ProtectedRoute>
            } />
          <Route path="/tetkik-takip-istek/formu" element={
              <ProtectedRoute>
                <TetkikTakipIstekFormu />
              </ProtectedRoute>
            } />
          <Route path="/tetkik-takip-istek/raporu" element={
              <ProtectedRoute>
                <TetkikTakipIstekRaporu />
              </ProtectedRoute>
            } />
          <Route path="/uye-kayit/formu" element={
              <ProtectedRoute>
                <UyeKayitFormu />
              </ProtectedRoute>
            } />
          <Route path="/uye-kayit/raporu" element={
              <ProtectedRoute>
                <UyeTakipRaporu />
              </ProtectedRoute>
            } />
          <Route path="/kullanici-yonetimi" element={
              <ProtectedRoute>
                <KullaniciYonetimi />
              </ProtectedRoute>
            } />
           <Route path="/eMail-Yonetimi" element={
              <ProtectedRoute>
                <EmailList />
              </ProtectedRoute>
            } />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/*" element={<LoginPage />} /> 
          {/* Diğer sayfaları ekleyin */}
        </Routes>

      </div>
    </Router>
    </AuthProvider>
  );
}

export default App;
