import { useEffect, useState } from 'react';
import LeftSidebar from '../components/LeftSidebar';
import apiManager from '../service/apiManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import './css/YatanHastaTakipRaporu.css'
import Modal from 'react-modal'

Modal.setAppElement('#root');

function formatDate(dateStr) {
  if (!dateStr) {
    // dateStr boş, null veya undefined ise
    return ""; // Veya istediğiniz bir placeholder
  }
  const date = new Date(dateStr);
  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  const year = date.getFullYear();

  day = day.length < 2 ? '0' + day : day;
  month = month.length < 2 ? '0' + month : month;

  return `${day}/${month}/${year}`;
}

const DetayPaneli = ({ kayit, kapat, onEmailSend }) => {
  const yazdirKayitDetaylari = () => {
    const yeniSekme = window.open('', '_blank');
    yeniSekme.document.open();

    let htmlContent = `
        <html>
            <head>
            <title>Seçilen Kayıt Detayı</title>
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">
            <style>
                body { padding: 20px; }
                .table-responsive {
                    display: block;
                    width: 100%;
                    overflow-x: auto;
                    -webkit-overflow-scrolling: touch;
                }
                .table {
                    width: 100% !important; /* Genişliği %100 olarak ayarla */
                    margin-bottom: 20px;
                    table-layout: fixed; /* Tablonun layoutunu sabitle */
                }
                .table th, .table td {
                    word-wrap: break-word; /* Uzun metinleri kır */
                    overflow-wrap: break-word; /* İçerik taşarsa alt satıra geç */
                }
                .dosyalar-section { margin-top: 20px; }
            </style>
        </head>
        <body>
                <h2 class="mb-3">Seçilen Kayıt Detayları</h2>
                <table class="table table-bordered">
                    <tbody>
                        <tr><th>Hasta Takip No</th><td>${kayit.hastaTakipNo}</td></tr>
                        <tr><th>Adı</th><td>${kayit.adi}</td></tr>
                        <tr><th>Soyadı</th><td>${kayit.soyadi}</td></tr>
                        <tr><th>Doğum Tarihi</th><td>${formatDate(kayit.dogumTarihi)}</td></tr>
                        <tr><th>Cinsiyet</th><td>${(kayit.cinsiyet)}</td></tr>
                        <tr><th>E-nabız</th><td>${kayit.eNabiz}</td></tr>
                        <tr><th>Yatış Tarihi</th><td>${formatDate(kayit.yatisTarihi)}</td></tr>
                        <tr><th>Çıkış Tarihi</th><td>${formatDate(kayit.cikisTarihi)}</td></tr>
                        <tr><th>TC Kimlik No</th><td>${kayit.tcKimlikNo}</td></tr>
                        <tr><th>Hastalık Kodu</th><td>${kayit.hastalikKodu}</td></tr>
                        <tr><th>Yapılan Ameliyat</th><td>${kayit.yapilanAmeliyat || ''}</td></tr>
                        <tr><th>Ameliyat Yapılan Hastane</th><td>${kayit.ameliyatYapilanHastane || ''}</td></tr>
                        <tr><th>Ameliyat Notu</th><td>${kayit.ameliyatNotu || ''}</td></tr>

                    </tbody>
                </table>
                <script>
                    window.onload = function() {
                        window.print();
                    };
                </script>
            </body>
        </html>
    `;

    yeniSekme.document.write(htmlContent);
    yeniSekme.document.close();
  };
  // Eğer kayıt null ise, boş bir div döndür
  if (!kayit) return <div></div>;

  return (
    <div className={`detay-panel ${kayit ? 'acik' : ''}`}>
      <div className="card-header">
        <h3 className="card-title mb-2">Seçilen Kayıt Detayları</h3>
        <div data-bs-theme="dark">
          <button className="btn btn-primary me-2" onClick={() => yazdirKayitDetaylari(kayit)}>Yazdır</button>
          <button className="btn btn-success me-2" onClick={() => onEmailSend(kayit)}>E-posta Gönder</button>
          <button onClick={kapat} class="btn btn-danger" aria-label="Close">Kapat</button>

        </div>
      </div>
      <div className="card-body" style={{ maxWidth: '100%', overflowWrap: 'break-word' }}>
        <p>Hasta Takip No: {kayit.hastaTakipNo}</p>
        <p>Adı: {kayit.adi}</p>
        <p>Soyadı: {kayit.soyadi}</p>
        <p>Doğum Tarihi: {formatDate(kayit.dogumTarihi)}</p>
        <p>Cinsiyet: {kayit.cinsiyet}</p>
        <p>E-nabız: {kayit.eNabiz}</p>
        <p>Yatış Tarihi: {formatDate(kayit.yatisTarihi)}</p>
        <p>Çıkış Tarihi: {formatDate(kayit.cikisTarihi)}</p>
        <p>TC Kimlik No: {kayit.tcKimlikNo}</p>
        <p>Hastalık Kodu: {kayit.hastalikKodu}</p>
        <p>Yapılan Ameliyat: {kayit.yapilanAmeliyat}</p>
        <p>Ameliyat Yapılan Hastane: {kayit.ameliyatYapilanHastane}</p>
        <p>Ameliyat Notu: {kayit.ameliyatNotu}</p>
        {/* Diğer detaylar */}
      </div>
    </div>
  );
};

const YatanHastaTakipRaporu = () => {
  const [kayitlar, setKayitlar] = useState([]);
  const [filtreler, setFiltreler] = useState(
    {
      hastaTakipNo: '',
      adi: '',
      soyadi: '',
      cinsiyet: '',
      tcKimlikNo: '',

    }
  );

  const [secilenKayit, setSecilenKayit] = useState(null);
  const [duzenlemeFormuGoster, setDuzenlemeFormuGoster] = useState(false);
  const [duzenlenecekKayit, setDuzenlenecekKayit] = useState({});
  const [baslangicYatisTarihi, setBaslangicYatisTarihi] = useState('');
  const [bitisYatisTarihi, setBitisYatisTarihi] = useState('');
  const [baslangicCikisTarihi, setBaslangicCikisTarihi] = useState('');
  const [bitisCikisTarihi, setBitisCikisTarihi] = useState('');
  const [baslangicDogumTarihi, setBaslangicDogumTarihi] = useState('');
  const [bitisDogumTarihi, setBitisDogumTarihi] = useState('');
  const [doctors, setDoctors] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedInPatientRegistration, setSelectedInPatientRegistration] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await apiManager.get('/yatan-hasta-kayit-raporu').then((data) => {
          if (data) {
            setKayitlar(data);
          } else {
            console.error('Veri yüklenemedi.');
          }
        });
      } catch (error) {
        console.error('Sunucu hatası:', error);
      }
    };

    fetchData();
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      // `apiManager` kullanarak `PATCH` isteğini yap
      await apiManager.patch(`/yatan-hasta-kayit/${duzenlenecekKayit.hastaTakipNo}`, duzenlenecekKayit);

      // Güncellenmiş kayıt bilgisi alındıktan sonra tüm kayıtları yeniden çek
      await fetchAllRecords();

      // Düzenleme formunu kapat
      setDuzenlemeFormuGoster(false);

      // Kullanıcıya başarılı güncelleme hakkında bilgi verin
      alert('Kayıt başarıyla güncellendi.');
    } catch (error) {
      console.error('Güncelleme sırasında hata oluştu:', error);
      alert('Güncelleme işlemi sırasında bir hata oluştu.');
    }
  };

  const fetchAllRecords = async () => {
    try {
      await apiManager.get('/yatan-hasta-kayit-raporu').then((data) => {
        if (data) {
          setKayitlar(data);
        } else {
          console.error('Veri yüklenemedi.');
        }
      });
    } catch (error) {
      console.error('Sunucu hatası:', error);
    }
  }

  useEffect(() => {
    const fetchDoctors = async () => {
        try {
            const response = await apiManager.get('/doctors');
            setDoctors(response);
            console.log(response)
        } catch (error) {
            console.error('Doktorlar yüklenirken bir hata oluştu:', error);

        }
    };

    fetchDoctors();
}, []);




  const handleDelete = async (kayitId) => {
    if (window.confirm(`Kaydı silmek istediğinize emin misiniz? ID: ${kayitId.hastaTakipNo}`)) {
      try {
        // `apiManager` kullanarak `DELETE` isteğini yap
        await apiManager.del(`/yatan-hasta-kayit/${kayitId.hastaTakipNo}`);

        console.log('Kayıt başarıyla silindi');
        await fetchAllRecords(); // Tüm kayıtları yeniden çek
      } catch (error) {
        console.error('Silme işlemi sırasında hata oluştu:', error);
      }
    }
  };



  const handleRowClick = (kayit) => {
    setSecilenKayit(kayit);
  };
  const kapatDetayPaneli = () => {
    setSecilenKayit(null);
  };
  const handleFilterChange = (e) => {
    setFiltreler({ ...filtreler, [e.target.name]: e.target.value.toLowerCase() });
  };
  const handleEdit = (kayit) => {
    // Tarih formatını düzelt
    const duzenlenmisKayit = {
      ...kayit,
      dogumTarihi: formatDateToInput(kayit.dogumTarihi),
      yatisTarihi: formatDateToInput(kayit.yatisTarihi),
      cikisTarihi: formatDateToInput(kayit.cikisTarihi)
    };

    setDuzenlenecekKayit(duzenlenmisKayit);
    setDuzenlemeFormuGoster(true);
  };
  const kapatGuncellemePaneli = () => {
    setDuzenlemeFormuGoster(false);
  };

  function formatDateToInput(dateStr) {
    if (!dateStr) return '';

    const date = new Date(dateStr);
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();

    month = month.length < 2 ? '0' + month : month;
    day = day.length < 2 ? '0' + day : day;

    return `${year}-${month}-${day}`;
  }
  const yazdirTumKayitlari = () => {
    const yeniSekme = window.open('', '_blank');
    yeniSekme.document.open();

    let htmlContent = `
        <html>
        <head>
            <title>Tüm Kayıtlar</title>
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">
            <style>
                body { font-size: 10px; }
                .table { width: 100%; table-layout: fixed; }
                .table th, .table td { word-wrap: break-word; overflow-wrap: break-word; }
                @media print {
                    body { font-size: 8px; }
                    .table { font-size: 8px; }
                }
            </style>
        </head>
        <body>
            <h2>Yatan Hasta Kayıt Raporları</h2>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Hasta Takip No</th>
                        <th>Adı</th>
                        <th>Soyadı</th>
                        <th>Doğum Tarihi</th>
                        <th>Cinsiyet</th>
                        <th>E-nabız</th>
                        <th>Yatış Tarihi</th>
                        <th>Çıkış Tarihi</th>
                        <th>TC Kimlik No</th>
                        <th>Hastalık Kodu</th>
                        <th>Yapılan Ameliyat</th>
                        <th>Ameliyat Yapılan Hastane</th>
                        <th>Ameliyat Notu</th>
                    </tr>
                </thead>
                <tbody>`;

    kayitlar.forEach(kayit => {
      htmlContent += `
                    <tr>
                        <td>${kayit.hastaTakipNo}</td>
                        <td>${kayit.adi}</td>
                        <td>${kayit.soyadi}</td>
                        <td>${formatDate(kayit.dogumTarihi)}</td>
                        <td>${kayit.cinsiyet}</td>
                        <td>${kayit.eNabiz}</td>
                        <td>${formatDate(kayit.yatisTarihi)}</td>
                        <td>${formatDate(kayit.cikisTarihi)}</td>
                        <td>${kayit.tcKimlikNo}</td>
                        <td>${kayit.hastalikKodu}</td>
                        <td>${kayit.yapilanAmeliyat || ''}</td>
                        <td>${kayit.ameliyatYapilanHastane || ''}</td>
                        <td>${kayit.ameliyatNotu || ''}</td>
                    </tr>`;
    });

    htmlContent += `
                </tbody>
            </table>
            <script>
                window.onload = function() {
                    window.print();
                };
            </script>
        </body>
        </html>`;

    yeniSekme.document.write(htmlContent);
    yeniSekme.document.close();
  };




  const filteredKayitlar = kayitlar.filter(kayit => {
    // Yatış, Çıkış ve Doğum Tarihlerini kontrol etmek için başlangıç ve bitiş tarihlerini ayarla
    let yatisTarihiBaslangic = baslangicYatisTarihi ? new Date(baslangicYatisTarihi) : new Date('1900-01-01');
    yatisTarihiBaslangic.setHours(0, 0, 0, 0);
    let yatisTarihiBitis = bitisYatisTarihi ? new Date(bitisYatisTarihi) : new Date('2100-12-31');
    yatisTarihiBitis.setHours(23, 59, 59, 999);

    let cikisTarihiBaslangic = baslangicCikisTarihi ? new Date(baslangicCikisTarihi) : new Date('1900-01-01');
    cikisTarihiBaslangic.setHours(0, 0, 0, 0);
    let cikisTarihiBitis = bitisCikisTarihi ? new Date(bitisCikisTarihi) : new Date('2100-12-31');
    cikisTarihiBitis.setHours(23, 59, 59, 999);

    let dogumTarihiBaslangic = baslangicDogumTarihi ? new Date(baslangicDogumTarihi) : new Date('1900-01-01');
    dogumTarihiBaslangic.setHours(0, 0, 0, 0);
    let dogumTarihiBitis = bitisDogumTarihi ? new Date(bitisDogumTarihi) : new Date('2100-12-31');
    dogumTarihiBitis.setHours(23, 59, 59, 999);

    const kayitYatisTarihi = new Date(kayit.yatisTarihi);
    const kayitCikisTarihi = kayit.cikisTarihi ? new Date(kayit.cikisTarihi) : null;
    const kayitDogumTarihi = new Date(kayit.dogumTarihi);

    const yatisTarihinde = kayitYatisTarihi >= yatisTarihiBaslangic && kayitYatisTarihi <= yatisTarihiBitis;
    const cikisTarihinde = (!kayit.cikisTarihi || kayitCikisTarihi >= cikisTarihiBaslangic) && (!kayit.cikisTarihi || kayitCikisTarihi <= cikisTarihiBitis);
    const dogumTarihinde = kayitDogumTarihi >= dogumTarihiBaslangic && kayitDogumTarihi <= dogumTarihiBitis;

    // Diğer filtreler için kontrol, boş veya null değerlerin dışlanmasıyla birlikte
    const digerFiltrelerUyuyor = Object.keys(filtreler).every(key => {
      if (['eNabiz', 'hastalikKodu', 'yapilanAmeliyat', 'ameliyatYapilanHastane', 'ameliyatNotu'].includes(key)) {
        if (filtreler[key] && (!kayit[key] || kayit[key].toString().toLowerCase().trim() === '')) return false;
      }
      if (!kayit[key]) return true; // Eğer kayıtta ilgili alan boş ise, bu filtreyi geç
      return kayit[key].toString().toLowerCase().includes(filtreler[key].toLowerCase());
    });

    return yatisTarihinde && cikisTarihinde && dogumTarihinde && digerFiltrelerUyuyor;
  });

  function formatInPatientRegistrationDetails(details) {
    // Tarihi daha anlaşılır bir formata çevirme
    const date = new Date(details.tarih);
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;

    return `
    <p>Adı: ${details.adi}</p>
    <p>Soyadı: ${details.soyadi}</p>
    <p>Doğum Tarihi: ${formatDate(details.dogumTarihi)}</p>
    <p>Cinsiyet: ${details.cinsiyet}</p>
    <p>E-nabız: ${details.eNabiz}</p>
    <p>Yatış Tarihi: ${formatDate(details.yatisTarihi)}</p>
    <p>Çıkış Tarihi: ${formatDate(details.cikisTarihi)}</p>
    <p>TC Kimlik No: ${details.tcKimlikNo}</p>
    <p>Hastalık Kodu: ${details.hastalikKodu}</p>
    <p>Yapılan Ameliyat: ${details.yapilanAmeliyat || ''}</p>
    <p>Ameliyat Yapılan Hastane: ${details.ameliyatYapilanHastane || ''}</p>
  <p>Ameliyat Notu: ${details.ameliyatNotu || ''}</p>`;
  }
  const sendInPatientRegistrationEmail = async (inPatientRegistrationEmail, inPatientRegistrationDetails, additionalComments) => {
    const formattedDetails = formatInPatientRegistrationDetails(inPatientRegistrationDetails);

    try {
      await apiManager.post('/email/send-inpatient-registration', {
        email: inPatientRegistrationEmail,
        subject: 'Yatan Hasta Takip Bilgileri',
        inPatientRegistrationDetails: formattedDetails, // Gönderilecek metin formatında reçete bilgileri
        additionalComments: additionalComments // Gönderilecek metin formatında reçete bilgileri
      });
      alert('E-posta başarıyla gönderildi.');
    } catch (error) {
      console.error('E-posta gönderilirken bir hata oluştu:', error);
    }
  };
  useEffect(() => {
    console.log(doctors);
  }, [doctors]);
  function InPatientRegistrationModal({ isOpen, onClose, onSend, doctors }) {
    const [selectedDoctorEmail, setSelectedDoctorEmail] = useState('');
    const [additionalComments, setAdditionalComments] = useState(''); // Ek açıklamalar için state
   
    const handleCommentsChange = (e) => setAdditionalComments(e.target.value);
    // useEffect(() => {
    //   console.log(pharmacies);
    // }, [pharmacies]);
    return (
      <Modal isOpen={isOpen} onRequestClose={onClose} style={{ content: { top: '50%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)', width: 'auto', maxWidth: '500px' }, overlay: { background: "rgba(0, 0, 0, 0.75)" } }}>
        <div className="modal-content">
          <div className="modal-header text-center">
            <h5 className="modal-title w-100 mb-4">Doktor Seçimi</h5>
          </div>
          <div className="modal-body">
            <div className="d-flex justify-content-center">
              <div className="w-100">
                <form>
                  <div className="form-group mb-3">
                    <label htmlFor="doctorSelect">Doktor Seçiniz</label>
                    <select id="doctorSelect" className="form-control" onChange={(e) => setSelectedDoctorEmail(e.target.value)} value={selectedDoctorEmail}>
                      <option value="">Seçiniz...</option>
                      {doctors && doctors.length > 0 && doctors.map((doctor) => (
                        <option key={doctor.uyeId} value={doctor.email}>{doctor.unvani}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-3">
                                        <label htmlFor="additionalComments">Ek Açıklamalar</label>
                                        <textarea id="additionalComments" className="form-control" rows="3" onChange={handleCommentsChange} value={additionalComments}></textarea>
                                    </div>
                </form>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-center mt-4">
            <button type="button" className="btn btn-primary  me-2" onClick={() => onSend(selectedDoctorEmail, additionalComments)}>Gönder</button>
            <button type="button" className="btn btn-secondary" onClick={onClose}>Kapat</button>
          </div>
        </div>
      </Modal>





    );
  }

  const handleSendEmailClick = (inPatientRegistration) => {
    setSelectedInPatientRegistration(inPatientRegistration);
    setIsModalOpen(true);
  };

  // Modal'dan gelen onSend işleyicisi...
  const handleModalSend = (doctorEmail, additionalComments) => {
    if (selectedInPatientRegistration && doctorEmail) {
      sendInPatientRegistrationEmail(doctorEmail, selectedInPatientRegistration, additionalComments);
      setIsModalOpen(false); // Modalı kapat
    } else {
      alert('Lütfen bir eczane seçiniz.');
    }
  };

  const handleEmailSend = (kayit) => {
    // E-posta gönderme işlemi için gerekli kodlar
    // Örneğin:
    setSelectedInPatientRegistration(kayit);
    setIsModalOpen(true);
  };



  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2 no-padding-left">
          <LeftSidebar />
        </div>
        <div className="col-md-10 ">
          <h2 style={{ backgroundColor: '#2c3e50', color: 'white', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', fontSize: '1.6rem' }}>Yatan Hasta Takip Raporu</h2>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px', padding: '5px 10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9' }}>
            <p style={{ margin: 0 }}>Kayıt Detayları ve Yazdırma İçin İlgili Kaydın Üzerine Tıklayınız.</p>
            <button type="button" onClick={yazdirTumKayitlari} className="btn btn-primary">Tüm Kayıtları Yazdır</button>
          </div>
          <div className="filter-section" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            {/* Doğum Tarihi için Başlangıç ve Bitiş Tarihi Seçicileri */}
            <div style={{ marginRight: '20px' }}>
              <label htmlFor="baslangicDogumTarihi">Doğum Tarihi Başlangıç:</label>
              <input
                type="date"
                id="baslangicDogumTarihi"
                value={baslangicDogumTarihi}
                onChange={(e) => setBaslangicDogumTarihi(e.target.value)}
                className="form-control"
              />
            </div>
            <div style={{ marginRight: '50px' }}>
              <label htmlFor="bitisDogumTarihi">Doğum Tarihi Bitiş:</label>
              <input
                type="date"
                id="bitisDogumTarihi"
                value={bitisDogumTarihi}
                onChange={(e) => setBitisDogumTarihi(e.target.value)}
                className="form-control"
              />
            </div>
            {/* Yatış Tarihi için Başlangıç ve Bitiş Tarihi Seçicileri */}
            <div style={{ marginRight: '20px' }}>
              <label htmlFor="baslangicYatisTarihi">Yatış Tarihi Başlangıç:</label>
              <input
                type="date"
                id="baslangicYatisTarihi"
                value={baslangicYatisTarihi}
                onChange={(e) => setBaslangicYatisTarihi(e.target.value)}
                className="form-control"
              />
            </div>
            <div style={{ marginRight: '50px' }}>
              <label htmlFor="bitisYatisTarihi">Yatış Tarihi Bitiş:</label>
              <input
                type="date"
                id="bitisYatisTarihi"
                value={bitisYatisTarihi}
                onChange={(e) => setBitisYatisTarihi(e.target.value)}
                className="form-control"
              />
            </div>

            {/* Çıkış Tarihi için Başlangıç ve Bitiş Tarihi Seçicileri */}
            <div style={{ marginRight: '20px' }}>
              <label htmlFor="baslangicCikisTarihi">Çıkış Tarihi Başlangıç:</label>
              <input
                type="date"
                id="baslangicCikisTarihi"
                value={baslangicCikisTarihi}
                onChange={(e) => setBaslangicCikisTarihi(e.target.value)}
                className="form-control"
              />
            </div>
            <div>
              <label htmlFor="bitisCikisTarihi">Çıkış Tarihi Bitiş:</label>
              <input
                type="date"
                id="bitisCikisTarihi"
                value={bitisCikisTarihi}
                onChange={(e) => setBitisCikisTarihi(e.target.value)}
                className="form-control"
              />
            </div>
          </div>

          {/* Düzenleme Formu */}
          {duzenlemeFormuGoster && (
            <div className="update-panel card">
              <div className="card-header">
                <h3>Kayıt Güncelle</h3>
              </div>
              <div className="card-body">
                {/* Form elemanları */}
                <form onSubmit={handleUpdate} className="row g-3">
                  <div className="col-md-6">
                    <label htmlFor="adi" className="form-label">Adı</label>
                    <input type="text" className="form-control" id="adi" value={duzenlenecekKayit.adi} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, adi: e.target.value })} />

                    <label htmlFor="soyadi" className="form-label mt-2">Soyadı</label>
                    <input type="text" className="form-control" id="soyadi" value={duzenlenecekKayit.soyadi} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, soyadi: e.target.value })} />

                    <label htmlFor="dogumTarihi" className="form-label mt-2">Doğum Tarihi</label>
                    <input type="date" className="form-control" id="dogumTarihi" value={duzenlenecekKayit.dogumTarihi} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, dogumTarihi: e.target.value })} />

                    <label htmlFor="eNabiz" className="form-label mt-2">E-nabız</label>
                    <input type="text" className="form-control" id="eNabiz" value={duzenlenecekKayit.eNabiz} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, eNabiz: e.target.value })} />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="cinsiyet" className="form-label mt-2">Cinsiyet</label>
                    <select className="form-control" id="cinsiyet" value={duzenlenecekKayit.cinsiyet} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, cinsiyet: e.target.value })}>
                      <option value="Erkek">Erkek</option>
                      <option value="Kadın">Kadın</option>
                    </select>

                    <label htmlFor="tcKimlikNo" className="form-label mt-2">TC Kimlik No</label>
                    <input type="text" className="form-control" id="tcKimlikNo" value={duzenlenecekKayit.tcKimlikNo} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, tcKimlikNo: e.target.value })} />

                    <label htmlFor="yatisTarihi" className="form-label mt-2">Yatış Tarihi</label>
                    <input type="date" className="form-control" id="yatisTarihi" value={duzenlenecekKayit.yatisTarihi} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, yatisTarihi: e.target.value })} />

                    <label htmlFor="cikisTarihi" className="form-label mt-2">Çıkış Tarihi</label>
                    <input type="date" className="form-control" id="cikisTarihi" value={duzenlenecekKayit.cikisTarihi} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, cikisTarihi: e.target.value })} />
                  </div>

                  <div className="col-12">
                    <button type="submit" className="btn btn-primary me-2">Güncelle</button>
                    <button type="button" onClick={kapatGuncellemePaneli} className="btn btn-danger">Kapat</button>
                  </div>
                </form>

              </div>
            </div>
          )}

          <DetayPaneli kayit={secilenKayit} kapat={kapatDetayPaneli} onEmailSend={handleEmailSend} />

          {/* Tablo */}
          <div className="table-responsive" style={{ maxHeight: '75vh', overflowX: 'auto' }}>
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th>İşlemler</th>
                  <th>
                    Hasta Takip No
                    <input type="text" name="hastaTakipNo" onChange={handleFilterChange} className="form-control" />
                  </th>
                  <th>
                    Adı
                    <input type="text" name="adi" onChange={handleFilterChange} className="form-control" />
                  </th>
                  <th>
                    Soyadı
                    <input type="text" name="soyadi" onChange={handleFilterChange} className="form-control" />
                  </th>
                  <th>Doğum Tarihi</th>
                  <th>Cinsiyet<input type="text" name="cinsiyet" onChange={handleFilterChange} className="form-control" /></th>
                  <th>E-nabız<input type="text" name="eNabiz" onChange={handleFilterChange} className="form-control" /></th>
                  <th>Yatış Tarihi</th>
                  <th>Çıkış Tarihi</th>
                  <th>
                    TC Kimlik No
                    <input type="text" name="tcKimlikNo" onChange={handleFilterChange} className="form-control" />
                  </th>
                  <th>Hastalık Kodu<input type="text" name="hastalikKodu" onChange={handleFilterChange} className="form-control" /></th>
                  <th>Yapılan Ameliyat<input type="text" name="yapilanAmeliyat" onChange={handleFilterChange} className="form-control" /></th>
                  <th>Ameliyat Yapılan Hastane<input type="text" name="ameliyatYapilanHastane" onChange={handleFilterChange} className="form-control" /></th>
                  <th>Ameliyat Notu<input type="text" name="ameliyatNotu" onChange={handleFilterChange} className="form-control" /></th>
                </tr>
              </thead>
              <tbody>
                {filteredKayitlar.map((kayit, index) => (
                  <tr key={index} onClick={() => handleRowClick(kayit)} className="clickable-row">
                    <td>
                      <button onClick={(e) => { e.stopPropagation(); handleEdit(kayit); }} className="btn btn-primary btn-sm me-1">
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button onClick={(e) => { e.stopPropagation(); handleDelete(kayit); }} className="btn btn-danger btn-sm">
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </td>
                    <td>{kayit.hastaTakipNo}</td>
                    <td>{kayit.adi ? (kayit.adi.length > 10 ? kayit.adi.substring(0, 10) + '...' : kayit.adi) : ''}</td>
                    <td>{kayit.soyadi ? (kayit.soyadi.length > 10 ? kayit.soyadi.substring(0, 10) + '...' : kayit.soyadi) : ''}</td>
                    <td>{formatDate(kayit.dogumTarihi)}</td>
                    <td>{kayit.cinsiyet}</td>
                    <td>{kayit.eNabiz ? (kayit.eNabiz.length > 10 ? kayit.eNabiz.substring(0, 10) + '...' : kayit.eNabiz) : ''}</td>
                    <td>{formatDate(kayit.yatisTarihi)}</td>
                    <td>{formatDate(kayit.cikisTarihi)}</td>
                    <td>{kayit.tcKimlikNo}</td>
                    <td>{kayit.hastalikKodu ? (kayit.hastalikKodu.length > 10 ? kayit.hastalikKodu.substring(0, 10) + '...' : kayit.hastalikKodu) : ''}</td>
                    <td>{kayit.yapilanAmeliyat ? (kayit.yapilanAmeliyat.length > 10 ? kayit.yapilanAmeliyat.substring(0, 10) + '...' : kayit.yapilanAmeliyat) : ''}</td>
                    <td>{kayit.ameliyatYapilanHastane ? (kayit.ameliyatYapilanHastane.length > 10 ? kayit.ameliyatYapilanHastane.substring(0, 10) + '...' : kayit.ameliyatYapilanHastane) : ''}</td>
                    <td>{kayit.ameliyatNotu ? (kayit.ameliyatNotu.length > 10 ? kayit.ameliyatNotu.substring(0, 10) + '...' : kayit.ameliyatNotu) : ''}</td>

                  </tr>
                ))}
              </tbody>
            </table>
            <InPatientRegistrationModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              onSend={handleModalSend}
              doctors={doctors} // Bu satır çok önemli
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default YatanHastaTakipRaporu;
