// src/pages/HastaKayitFormu.js
import React, { useState, useEffect } from 'react';
import LeftSidebar from '../components/LeftSidebar';
import './css/HastaKayitRaporu.css';
import './css/HastaKayitFormu.css'
import apiManager from '../service/apiManager';
import Swal from 'sweetalert2';



const HastaKayitFormu = () => {

  // State'ler
  const [hastaTakipNo, setHastaTakipNo] = useState('');
  const [tarih, setTarih] = useState(null);
  const [adi, setAdi] = useState('');
  const [soyadi, setSoyadi] = useState('');
  const [dogumTarihi, setDogumTarihi] = useState(null);
  const [cinsiyet, setCinsiyet] = useState('');
  const [eNabiz, setENabiz] = useState('');
  const [tcKimlikNo, setTcKimlikNo] = useState('');
  const [aciklama, setAciklama] = useState('');
  const [fizikMuayene, setFizikMuayene] = useState('');
  const [goruntulemeLab, setGoruntulemeLab] = useState('');
  const [sonucKarar, setSonucKarar] = useState('');
  const [dosyalar, setDosyalar] = useState([]);

  const handleFileChange = (e) => {
    setDosyalar(prevFiles => [...prevFiles, ...e.target.files]);
};
const handleFileRemove = (indexToRemove) => {
  setDosyalar(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
};
  // Form gönderildiğinde yapılacak işlemler
  useEffect(() => {
    const fetchHastaTakipNo = async () => {
      try {
        await apiManager.get('/yeni-hasta-takip-no').then((data) => {
            if (data) {
                setHastaTakipNo(data.hastaTakipNo);
            } else {
                console.error('Veri yüklenemedi.');
            }
        });
    } catch (error) {
        console.error('Sunucu hatası:', error);
    }
    };
    

    fetchHastaTakipNo();
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // FormData nesnesi oluşturuldu
    const formData = new FormData();
    formData.append('hastaTakipNo', hastaTakipNo);
    formData.append('tarih', tarih);
    formData.append('adi', adi);
    formData.append('soyadi', soyadi);
    formData.append('dogumTarihi', dogumTarihi);
    formData.append('cinsiyet', cinsiyet);
    formData.append('eNabiz', eNabiz);
    formData.append('tcKimlikNo', tcKimlikNo);
    formData.append('aciklama', aciklama);
    formData.append('fizikMuayene', fizikMuayene);
    formData.append('goruntulemeLab', goruntulemeLab);
    formData.append('sonucKarar', sonucKarar);
    // Dosyaları FormData'ya ekleyin
    for (let i = 0; i < dosyalar.length; i++) {
        formData.append('dosyalar', dosyalar[i]);
    }

    try {
        await apiManager.post('/hasta-kayit', formData ).then(data=>{
          if (data) {
              console.log('Hasta kaydı başarılı.');
              alert('Form başarıyla oluşturuldu.');
              window.location.reload();
          } else {
              console.error('Hata oluştu.');
          }
        })
      } catch (error) {
          console.error('Sunucu hatası:', error);
      }  

        };



  const handleReset = () => {
    setTarih('');
        setDogumTarihi(null);
        setAdi('');
        setSoyadi('');
        setCinsiyet('');
        setENabiz('');
        setTcKimlikNo('');
        setSoyadi('');
        setAciklama('');
        setFizikMuayene('');
        setGoruntulemeLab('');
        setSonucKarar('');
        setDosyalar([]);
  };

  return (
    <div className="container-fluid">
      <div className="row">
      <div className="col-md-2 no-padding-left">
  <LeftSidebar />
</div>
        <div className="col-md-10">
        <h2 style={{ backgroundColor: '#2c3e50',color:'white', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', fontSize:'1.6rem'}}>Hasta Kayıt Formu</h2>
          <form onSubmit={handleSubmit} className="row g-3">
            <div className="col-md-6">
              {/* İlk sütundaki form elemanları */}
              {/* ... Mevcut form elemanları ... */}
              <div className="mb-3">
                <label className="form-label">Hasta Takip No:<span className="required">*</span></label>
                <input type="text" className="form-control" style={{ width: '90%' }} value={hastaTakipNo} onChange={(e) => setHastaTakipNo(e.target.value)} readOnly/>
              </div>
              <div className="mb-3">
                <label className="form-label">Tarih:<span className="required">*</span></label>
                <input type="date" className="form-control" style={{ width: '90%' }} value={tarih} onChange={(e) => setTarih(e.target.value)} required/>
              </div>
              <div className="mb-3">
                <label className="form-label">Adı:<span className="required">*</span></label>
                <input type="text" className="form-control" style={{ width: '90%' }} value={adi} onChange={(e) => setAdi(e.target.value)} required/>
              </div>
              <div className="mb-3">
                <label className="form-label">Soyadı<span className="required">*</span>:</label>
                <input type="text" className="form-control" style={{ width: '90%' }} value={soyadi} onChange={(e) => setSoyadi(e.target.value)} required/>
              </div>
              <div className="mb-3">
                <label className="form-label">Doğum Tarihi:<span className="required">*</span></label>
                <input type="date" className="form-control" style={{ width: '90%' }} value={dogumTarihi} onChange={(e) => setDogumTarihi(e.target.value)} required/>
              </div>
              <div className="mb-3">
                <label className="form-label">Cinsiyet:<span className="required">*</span></label>
                <select className="form-select" style={{ width: '90%' }} value={cinsiyet} onChange={(e) => setCinsiyet(e.target.value)}required>
                  <option value="">Seçiniz...</option>
                  <option value="Erkek">Erkek</option>
                  <option value="Kadın">Kadın</option>
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label">E-nabız:</label>
                <input type="text" className="form-control" style={{ width: '90%' }} value={eNabiz} onChange={(e) => setENabiz(e.target.value)} />
              </div>
              
            </div>
            <div className="col-md-6">
              {/* İkinci sütundaki form elemanları */}
              {/* ... Mevcut form elemanları ... */}
              <div className="mb-3">
                <label className="form-label">TC Kimlik No:<span className="required">*</span></label>
                <input type="text" className="form-control" style={{ width: '90%' }} value={tcKimlikNo} onChange={(e) => setTcKimlikNo(e.target.value)} required/>
              </div>
              <div className="mb-3">
                <label className="form-label">Açıklama:</label>
                <textarea className="form-control" style={{ width: '90%' }} value={aciklama} onChange={(e) => setAciklama(e.target.value)} />
              </div>
              <div className="mb-3">
                <label className="form-label">Fizik Muayene:</label>
                <textarea className="form-control" style={{ width: '90%' }} value={fizikMuayene} onChange={(e) => setFizikMuayene(e.target.value)} />
              </div>
              <div className="mb-3">
                <label className="form-label">Görüntüleme/Lab:</label>
                <textarea className="form-control" style={{ width: '90%' }} value={goruntulemeLab} onChange={(e) => setGoruntulemeLab(e.target.value)} />
              </div>
              <div className="mb-3">
                <label className="form-label">Sonuç / Karar:</label>
                <textarea className="form-control" style={{ width: '90%' }} value={sonucKarar} onChange={(e) => setSonucKarar(e.target.value)} />
              </div>
              <div className="mb-3">
              <label htmlFor="file-upload" className="form-label">Hasta Dosyaları:</label>
              <input id="file-upload" type="file" className="form-control" multiple onChange={handleFileChange} />
              {dosyalar.length > 0 && (
                <div className="mt-2">
                  <strong>Seçilen Dosyalar:</strong>
                  <ul>
                    {dosyalar.map((file, index) => (
                      <li key={index}>
                        {file.name} <button type="button" className="btn btn-danger btn-sm" onClick={() => handleFileRemove(index)}>Sil</button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            </div>
            <div className="col-12">
              <button type="submit" className="btn btn-primary me-2">Formu Oluştur</button>
              <button type="reset" onClick={handleReset} className="btn btn-secondary">Sıfırla</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default HastaKayitFormu;
