import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/tr';
import apiManager from '../service/apiManager';

moment.locale('tr');
const localizer = momentLocalizer(moment);


const SimpleCalendar = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  // State'i ve modal gösterme/kapatma fonksiyonlarını tanımlayın
  // const [showEditModal, setShowEditModal] = useState(false);
  const [duzenlemeFormuGoster, setDuzenlemeFormuGoster] = useState(false);
  const [duzenlenecekKayit, setDuzenlenecekKayit] = useState({});
  // ...
  // Düzenle butonu için olay işleyici
  // const handleFormChange = (e) => {
  //   const { name, value } = e.target; // input alanının adı ve değeri
  //   setDuzenlenecekKayit(prevState => ({
  //     ...prevState,
  //     [name]: value // Dinamik olarak state'i güncelle
  //   }));
  // };
  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        // API'den randevu verilerini çek
        const data = await apiManager.get('/randevular', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}` // Token'ı Authorization header'ına ekle
          },
        });
  
        // Randevu verilerini takvim etkinliklerine dönüştür
        const newEvents = data.map(appointment => {
          const startDate = new Date(appointment.randevuTarihi);
          const startTime = appointment.randevuSaati.split(':');
          startDate.setHours(parseInt(startTime[0]), parseInt(startTime[1]));
  
          const endDate = new Date(startDate);
          endDate.setMinutes(endDate.getMinutes() + 30); // Randevu süresi 30 dakika olarak ayarlandı
  
          return {
            id: appointment.randevuID, // Her randevu için benzersiz ID
            title: appointment.hastaAdi + ' ' + appointment.hastaSoyadi,
            start: startDate,
            end: endDate,
            allDay: false,
            hastaAdi: appointment.hastaAdi,
            hastaSoyadi: appointment.hastaSoyadi,
            randevuTarihi: appointment.randevuTarihi,
            randevuSaati: appointment.randevuSaati,
            doktor: appointment.doktor,
            hizmet: appointment.hizmet,
            telefonNumarasi: appointment.telefonNumarasi,
            hastaEposta: appointment.hastaEposta
          };
        });
  
        setEvents(newEvents);
      } catch (error) {
        console.error('Randevu verileri çekilirken bir hata oluştu:', error);
      }
    };
  
    fetchAppointments();
  }, []);
  

  const handleUpdate = async (e) => {
    e.preventDefault(); // Form submit işlemini engelle
  
    try {
      // `apiManager` kullanarak `PATCH` isteğini yap
      await apiManager.patch(`/randevular/${duzenlenecekKayit.randevuID}`, duzenlenecekKayit);
  
      await fetchAllRecords(); // Kayıtları yeniden çek
      setDuzenlemeFormuGoster(false); // Düzenleme formunu gizle
      alert('Kayıt başarıyla güncellendi.');
    } catch (error) {
      console.error('Güncelleme sırasında hata oluştu:', error);
      alert('Güncelleme işlemi sırasında bir hata oluştu.');
    }
  };
  const fetchAllRecords = async () => {
    try {
      // API'den randevu verilerini çek
      const data = await apiManager.get('/randevular', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}` // Token'ı Authorization header'ına ekle
        },
      });
  
      const newEvents = data.map(appointment => {
        const startDate = new Date(appointment.randevuTarihi);
        const startTime = appointment.randevuSaati.split(':');
        startDate.setHours(parseInt(startTime[0]), parseInt(startTime[1]));
  
        const endDate = new Date(startDate);
        endDate.setMinutes(endDate.getMinutes() + 30); // Randevu süresi 30 dakika olarak ayarlandı
  
        return {
          id: appointment.randevuID,
          title: appointment.hastaAdi + ' ' + appointment.hastaSoyadi,
          start: startDate,
          end: endDate,
          allDay: false,
          hastaAdi: appointment.hastaAdi,
          hastaSoyadi: appointment.hastaSoyadi,
          randevuTarihi: appointment.randevuTarihi,
          randevuSaati: appointment.randevuSaati,
          doktor: appointment.doktor,
          hizmet: appointment.hizmet,
          telefonNumarasi: appointment.telefonNumarasi,
          hastaEposta: appointment.hastaEposta
        };
      });
  
      setEvents(newEvents); // Takvimin events state'ini güncelle
    } catch (error) {
      console.error('Veri yüklenirken hata oluştu:', error);
    }
  };
  

  const messages = {
    allDay: 'Tüm gün',
    previous: '<',
    next: '>',
    today: 'Bugün',
    month: 'Ay',
    week: 'Hafta',
    day: 'Gün',
    agenda: 'Ajanda',
    date: 'Tarih',
    time: 'Saat',
    event: 'Etkinlik', // Etkinlik başlığı için
    showMore: total => `+ ${total} daha`
  };
  const handleSelectEvent = event => {
    setSelectedEvent(event);
    // Randevu detaylarını burada görüntülemek için gereken kodu ekleyebilirsiniz.
  };
  const handleEditClick = (selectedEvent) => {
    const randevuSaatiParts = selectedEvent.randevuSaati.split(':');
    const formattedRandevuSaati = `${randevuSaatiParts[0]}:${randevuSaatiParts[1]}`; // Saniye kısmını at
    const duzenlenmisKayit = {
      ...selectedEvent,
      randevuTarihi: formatDateToInput(selectedEvent.randevuTarihi),
      randevuSaati: formattedRandevuSaati,

    };

    setDuzenlenecekKayit({ ...duzenlenmisKayit }); // Seçilen randevuyu düzenlenecek kayıt olarak ayarla
    setDuzenlemeFormuGoster(true); // Düzenleme formunu göster
  };

  const deleteAppointment = async (appointment) => {
    if (window.confirm('Bu randevuyu silmek istediğinizden emin misiniz?')) {
      try {
        // `apiManager` kullanarak `DELETE` isteğini yap
        await apiManager.del(`/randevular/${appointment.id}`);
  
        // Başarılı silme işleminden sonra UI'dan da kaldır
        setEvents(events.filter(event => event.id !== appointment.id));
        setSelectedEvent(null); // Detay penceresini kapat
      } catch (error) {
        console.error('Randevu silinirken bir hata oluştu:', error);
      }
    }
  };
  function formatDateToInput(dateStr) {
    if (!dateStr) return '';

    const date = new Date(dateStr);
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();

    month = month.length < 2 ? '0' + month : month;
    day = day.length < 2 ? '0' + day : day;

    return `${year}-${month}-${day}`;
  }

  const eventStyleGetter = (event) => {
    const style = {
      // Varsayılan stil ayarları
      backgroundColor: '#3174ad', // Mavi renk
      color: 'white',
      borderRadius: '0px',
      border: 'none',
    };
  
    // Hasta soyadı TIDYCAL olan randevular için özel stil
    if (event.hastaSoyadi === 'TIDYCAL') {
      style.backgroundColor = 'orange'; // Turuncu renk
    }
  
    return {
      style,
    };
  };

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        messages={messages}
        style={{ height: 500 }}
        onSelectEvent={handleSelectEvent}
        eventPropGetter={eventStyleGetter} // Burada fonksiyonu prop olarak geçiriyoruz
      />
      <div className="randevu-detaylari">
        {selectedEvent && (
          <div>
            {duzenlemeFormuGoster && (
             <div className="card mt-3">
             <div className="card-header" style={{ backgroundColor: '#2c3e50'}}>
               <h5 className="mb-0 text-white" >Randevu Güncelle</h5>
             </div>
             <form onSubmit={handleUpdate} className="card-body">
               <div className="row g-3">
                 <div className="col-md-3">
                   <label htmlFor="hastaAdi" className="form-label">Adı:</label>
                   <input type="text" className="form-control" id="hastaAdi" value={duzenlenecekKayit.hastaAdi} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, hastaAdi: e.target.value })} placeholder="Adı" />
                 </div>
                 <div className="col-md-3">
                   <label htmlFor="hastaSoyadi" className="form-label">Soyadı:</label>
                   <input type="text" className="form-control" id="hastaSoyadi" value={duzenlenecekKayit.hastaSoyadi} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, hastaSoyadi: e.target.value })} placeholder="Soyadı" />
                 </div>
                 <div className="col-md-3">
                   <label htmlFor="randevuTarihi" className="form-label">Randevu Tarihi:</label>
                   <input type="date" className="form-control" id="randevuTarihi" value={duzenlenecekKayit.randevuTarihi} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, randevuTarihi: e.target.value })} />
                 </div>
                 <div className="col-md-3">
                   <label htmlFor="doktor" className="form-label">Doktor:</label>
                   <input type="text" className="form-control" id="doktor" value={duzenlenecekKayit.doktor} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, doktor: e.target.value })} placeholder="Doktor" />
                 </div>
                 <div className="col-md-3">
                   <label htmlFor="telefonNumarasi" className="form-label">Telefon Numarası:</label>
                   <input type="text" className="form-control" id="telefonNumarasi" value={duzenlenecekKayit.telefonNumarasi} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, telefonNumarasi: e.target.value })} placeholder="Telefon Numarası" />
                 </div>
                 <div className="col-md-3">
                   <label htmlFor="randevuSaati" className="form-label">Randevu Saati:</label>
                   <input type="text" className="form-control" id="randevuSaati" value={duzenlenecekKayit.randevuSaati} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, randevuSaati: e.target.value })} placeholder="Randevu Saati" />
                 </div>
                 <div className="col-md-3">
                   <label htmlFor="hastaEposta" className="form-label">E-Posta:</label>
                   <input type="text" className="form-control" id="hastaEposta" value={duzenlenecekKayit.hastaEposta} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, hastaEposta: e.target.value })} placeholder="E-Posta" />
                 </div>
                 <div className="col-md-3">
                   <label htmlFor="hizmet" className="form-label">Hizmet:</label>
                   <textarea className="form-control" id="hizmet" value={duzenlenecekKayit.hizmet} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, hizmet: e.target.value })} placeholder="Hizmet"></textarea>
                 </div>
                 <div className="col-12 text-end">
                   <button type="submit" className="btn btn-primary btn-sm me-1">Güncelle</button>
                   <button onClick={() => setDuzenlemeFormuGoster(false)} className="btn btn-secondary btn-sm">İptal</button>
                 </div>
               </div>
             </form>
           </div>
           
            )}


            {/* Mevcut detaylar */}
            <div className="card my-4">
              <div className="card-header" style={{ backgroundColor: '#2c3e50'}}>
                <h2 className="h5 m-0 text-white" >Randevu Detayları</h2>
              </div>
              <div className="card-body">
                <p className="mb-2"><strong>Tarih:</strong> {selectedEvent.start.toLocaleDateString()}</p>
                <p className="mb-2"><strong>Saat:</strong> {selectedEvent.start.toLocaleTimeString()}</p>
                <p className="mb-2"><strong>Hasta Adı Soyadı:</strong> {selectedEvent.title}</p>
                <p className="mb-2"><strong>Hizmet:</strong> {selectedEvent.hizmet}</p>
                <p className="mb-2"><strong>Telefon Numarası:</strong> {selectedEvent.telefonNumarasi}</p>
                <p className="mb-4"><strong>E-posta:</strong> {selectedEvent.hastaEposta}</p>
                <div className="d-flex justify-content-end">
                  <button onClick={() => handleEditClick(selectedEvent)} className="btn btn-primary btn-sm me-2">Düzenle</button>
                  <button onClick={() => deleteAppointment(selectedEvent)} className="btn btn-danger btn-sm me-2">Sil</button>
                  <button onClick={() => setSelectedEvent(null)} className="btn btn-secondary btn-sm">Kapat</button>
                </div>
              </div>
            </div>

          </div>
        )}
      </div>
    </div>
  );
};


export default SimpleCalendar;
