import { useState, useEffect } from 'react';
import LeftSidebar from '../components/LeftSidebar';
import apiManager from '../service/apiManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import './css/TetkikTakipIstekRaporu.css'
import Modal from 'react-modal'

Modal.setAppElement('#root');

function formatDate(dateStr) {
    const date = new Date(dateStr);
    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();
    const year = date.getFullYear();

    day = day.length < 2 ? '0' + day : day;
    month = month.length < 2 ? '0' + month : month;

    return `${day}/${month}/${year}`;
}

const DetayPaneli = ({ kayit, kapat, onEmailSend }) => {
    const yazdirKayitDetaylari = () => {
        const yeniSekme = window.open('', '_blank');
        yeniSekme.document.open();

        let htmlContent = `
            <html>
                <head>
                <title>Seçilen Kayıt Detayı</title>
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">
                <style>
                    body { padding: 20px; }
                    .table-responsive {
                        display: block;
                        width: 100%;
                        overflow-x: auto;
                        -webkit-overflow-scrolling: touch;
                    }
                    .table {
                        width: 100% !important; /* Genişliği %100 olarak ayarla */
                        margin-bottom: 20px;
                        table-layout: fixed; /* Tablonun layoutunu sabitle */
                    }
                    .table th, .table td {
                        word-wrap: break-word; /* Uzun metinleri kır */
                        overflow-wrap: break-word; /* İçerik taşarsa alt satıra geç */
                    }
                    .dosyalar-section { margin-top: 20px; }
                </style>
            </head>
            <body>
                    <h2 class="mb-3">Seçilen Kayıt Detayları</h2>
                    <table class="table table-bordered">
                        <tbody>
                        <tr><th>Hasta Takip No</th><td>${kayit.hastaTakipNo}</td></tr>
                        <tr><th>Doğum Tarihi</th><td>${formatDate(kayit.tarih)}</td></tr>
                            <tr><th>Adı</th><td>${kayit.adi}</td></tr>
                            <tr><th>Soyadı</th><td>${kayit.soyadi}</td></tr>
                            <tr><th>Hemotolojik Testler</th><td>${kayit.hemotolojikTestler ? Array.isArray(kayit.hemotolojikTestler) ? kayit.hemotolojikTestler.join(', ') : JSON.parse(kayit.hemotolojikTestler).join(', ') : ''}</td></tr>
                            <tr><th>Koagulasyon Testleri</th><td>${kayit.koagulasyonTestleri ? Array.isArray(kayit.koagulasyonTestleri) ? kayit.koagulasyonTestleri.join(', ') : JSON.parse(kayit.koagulasyonTestleri).join(', ') : ''}</td></tr>
                            <tr><th>Bio Kimyasal Testleri</th><td>${kayit.bioKimyasalTestler ? Array.isArray(kayit.bioKimyasalTestler) ? kayit.bioKimyasalTestler.join(', ') : JSON.parse(kayit.bioKimyasalTestler).join(', ') : ''}</td></tr>
                            <tr><th>Kardiyak Testleri</th><td>${kayit.kardiyakTestler ? Array.isArray(kayit.kardiyakTestler) ? kayit.kardiyakTestler.join(', ') : JSON.parse(kayit.kardiyakTestler).join(', ') : ''}</td></tr>
                            <tr><th>Hormon Testleri</th><td>${kayit.hormonTestleri ? Array.isArray(kayit.hormonTestleri) ? kayit.hormonTestleri.join(', ') : JSON.parse(kayit.hormonTestleri).join(', ') : ''}</td></tr>
                            <tr><th>Triod Testleri</th><td>${kayit.triodTestleri ? Array.isArray(kayit.triodTestleri) ? kayit.triodTestleri.join(', ') : JSON.parse(kayit.triodTestleri).join(', ') : ''}</td></tr>
                            <tr><th>Tümör Markerlar</th><td>${kayit.tumorMarkerlar ? Array.isArray(kayit.tumorMarkerlar) ? kayit.tumorMarkerlar.join(', ') : JSON.parse(kayit.tumorMarkerlar).join(', ') : ''}</td></tr>
                            <tr><th>Hepatit Markerlar</th><td>${kayit.hepatitMarkerlar ? Array.isArray(kayit.hepatitMarkerlar) ? kayit.hepatitMarkerlar.join(', ') : JSON.parse(kayit.hepatitMarkerlar).join(', ') : ''}</td></tr>
                            <tr><th>Mikrobiolojik Testler</th><td>${kayit.mikrobiolojikTestler ? Array.isArray(kayit.mikrobiolojikTestler) ? kayit.mikrobiolojikTestler.join(', ') : JSON.parse(kayit.mikrobiolojikTestler).join(', ') : ''}</td></tr>
                            <tr><th>Kültür Testleri</th><td>${kayit.kulturTestleri ? Array.isArray(kayit.kulturTestleri) ? kayit.kulturTestleri.join(', ') : JSON.parse(kayit.kulturTestleri).join(', ') : ''}</td></tr>
                        </tbody>
                    </table>
                    <script>
                        window.onload = function() {
                            window.print();
                        };
                    </script>
                </body>
            </html>
        `;

        yeniSekme.document.write(htmlContent);
        yeniSekme.document.close();
    };
    if (!kayit) return <div></div>;

    return (
        <div className={`detay-panel ${kayit ? 'acik' : ''}`}>
            <div className="card-header">
                <h3 className="card-title mb-2">Seçilen Kayıt Detayları</h3>
                <div data-bs-theme="dark">
                    <button className="btn btn-primary me-2" onClick={() => yazdirKayitDetaylari(kayit)}>Yazdır</button>
                    <button className="btn btn-success me-2" onClick={() => onEmailSend(kayit)}>E-posta Gönder</button>
                    <button onClick={kapat} class="btn btn-danger" aria-label="Close">Kapat</button>

                </div>
            </div>
            <div className="card-body" style={{ maxWidth: '100%', overflowWrap: 'break-word' }} >
                <p>Hasta Takip No: {kayit.hastaTakipNo}</p>
                <p>Tarih: {formatDate(kayit.tarih)}</p>
                <p>Adı: {kayit.adi}</p>
                <p>Soyadı: {kayit.soyadi}</p>
                <p>Hemotolojik Testler: {kayit.hemotolojikTestler ? Array.isArray(kayit.hemotolojikTestler) ? kayit.hemotolojikTestler.join(', ') : JSON.parse(kayit.hemotolojikTestler).join(', ') : ''}</p>
                <p>Koagulasyon Testleri: {kayit.koagulasyonTestleri ? Array.isArray(kayit.koagulasyonTestleri) ? kayit.koagulasyonTestleri.join(', ') : JSON.parse(kayit.koagulasyonTestleri).join(', ') : ''}</p>
                <p>Bio Kimyasal Testleri: {kayit.bioKimyasalTestler ? Array.isArray(kayit.bioKimyasalTestler) ? kayit.bioKimyasalTestler.join(', ') : JSON.parse(kayit.bioKimyasalTestler).join(', ') : ''}</p>
                <p>Kardiyak Testleri: {kayit.kardiyakTestler ? Array.isArray(kayit.kardiyakTestler) ? kayit.kardiyakTestler.join(', ') : JSON.parse(kayit.kardiyakTestler).join(', ') : ''}</p>
                <p>Hormon Testleri: {kayit.hormonTestleri ? Array.isArray(kayit.hormonTestleri) ? kayit.hormonTestleri.join(', ') : JSON.parse(kayit.hormonTestleri).join(', ') : ''}</p>
                <p>Triod Testleri: {kayit.triodTestleri ? Array.isArray(kayit.triodTestleri) ? kayit.triodTestleri.join(', ') : JSON.parse(kayit.triodTestleri).join(', ') : ''}</p>
                <p>Tümör Markerlar: {kayit.tumorMarkerlar ? Array.isArray(kayit.tumorMarkerlar) ? kayit.tumorMarkerlar.join(', ') : JSON.parse(kayit.tumorMarkerlar).join(', ') : ''}</p>
                <p>Hepatit Markerlar: {kayit.hepatitMarkerlar ? Array.isArray(kayit.hepatitMarkerlar) ? kayit.hepatitMarkerlar.join(', ') : JSON.parse(kayit.hepatitMarkerlar).join(', ') : ''}</p>
                <p>Mikrobiolojik Testler: {kayit.mikrobiolojikTestler ? Array.isArray(kayit.mikrobiolojikTestler) ? kayit.mikrobiolojikTestler.join(', ') : JSON.parse(kayit.mikrobiolojikTestler).join(', ') : ''}</p>
                <p>Kültür Testleri: {kayit.kulturTestleri ? Array.isArray(kayit.kulturTestleri) ? kayit.kulturTestleri.join(', ') : JSON.parse(kayit.kulturTestleri).join(', ') : ''}</p>
                {/* Diğer test kategorilerini burada listeleyin */}
            </div>
        </div>
    );
};

const TetkikTakipIstekRaporu = () => {
    const [secilenHemotolojikTestler, setSecilenHemotolojikTestler] = useState([]);
    const [kullanilabilirHemotolojikTestler, setKullanilabilirTestler] = useState([
        "Hemogram(24 parametre)", "Sedimentasyon", "Direkt Coombs", "indirekt Coombs", "Kan Grubu Tayini", "Periferik Yayma"
    ]);
    const [secilenKoagulasyonTestleri, setSecilenKoagulasyonTestleri] = useState([]);
    const [kullanilabilirKoagulasyonTestleri, setKullanilabilirKoagulasyonTestleri] = useState([
        "APTT", "Protombin Zamani", "Fibrinojen", "Kanama Zamanı", "Pıhtılaşma Zamanı"
    ]);
    // Bio Kimyasal Testler için state ve handler
    const [secilenBioKimyasalTestler, setSecilenBioKimyasalTestler] = useState([]);
    const [kullanilabilirBioKimyasalTestler, setKullanilabilirBioKimyasalTestler] = useState([
        "Glikoz(Açlık)", "Glikoz(Tokluk)", "HbA1C(Glikolize hemoglobin)", "Üre", "Kreatinin", "GFR", "Ürük Asit", "TotalProtein", "Albümin",
        "Globulin", "BilirubinTotal(Total)", "BilirubinTotal(Direkt)", "Trigliserid", "HDL", "LDL", "VLDL", "ALT", "AST", "GGT", "ALP", "LDH", "Amilaz",
        "Lipaz", "ASO", "CRP", "RF", "Sodyum", "Potasyum", "Klor", "Kalsiyum", "Magnezyum", "Fosfor"                             // ve diğerleri...
    ]);
    // Kardiyak Testler için state ve handler
    const [secilenKardiyakTestler, setSecilenKardiyakTestler] = useState([]);
    const [kullanilabilirKardiyakTestler, setKullanilabilirKardiyakTestler] = useState([
        "CK", "CK-MB(PT)", "Troponin I"
    ]);
    // Hormon Testleri için state ve handler
    const [secilenHormonTestleri, setSecilenHormonTestleri] = useState([]);
    const [kullanilabilirHormonTestleri, setKullanilabilirHormonTestleri] = useState([
        "Beta-hCG", "FSH", "LH", "Progesteron", "Prolaktin", "DHEA-SO4", "17-OH Progesteron", "Testesteron(Total)", "Testesteron(Serbest)",
        "Kortizol", "insülin(Aclik)", "insülin(Tokluk)", "VitaminB12", "25-OH Vitamin D", "Ferritin", "Folat"                          // ve diğerleri...
    ]);
    // Triod Testleri için state ve handler
    const [secilenTriodTestleri, setSecilenTriodTestleri] = useState([]);
    const [kullanilabilirTriodTestleri, setKullanilabilirTriodTestleri] = useState([
        "TSH", "Serbast T3", "Serbest T4", "Antri Tiroglubulin", "Anti TPO", "Parathormon"
    ]);
    // Tümör Markerları için state ve handler
    const [secilenTumorMarkerlar, setSecilenTumorMarkerlar] = useState([]);
    const [kullanilabilirTumorMarkerlar, setKullanilabilirTumorMarkerlar] = useState([
        "AFP", "CA125", "CA 15-3", "CA 19-9", "CA 72-4", "CEA", "PSA(Total)", "PSA(Serbest)"
    ]);
    // Hepatit Markerları için state ve handler
    const [secilenHepatitMarkerlar, setSecilenHepatitMarkerlar] = useState([]);
    const [kullanilabilirHepatitMarkerlar, setKullanilabilirHepatitMarkerlar] = useState([
        "Tam idrar Tetkiki", "Mikroalbumin(spot)", "Mikroalbumin(24 saat)", "idrarda Protein(24 saat)",
        "idrarda Protein(spot)", "Gaita Mikroskopik Incelenmesi", "Gaitada Gizli Kan",
        "Gaitada Reduktan Madde", "Helicobacter Pylori Antijeni(Gaita)", "Kıl Kurdu(Enterobius Vermicularis)"
    ]);
    // Mikrobiolojik Testler için state ve handler
    const [secilenMikrobiolojikTestler, setSecilenMikrobiolojikTestler] = useState([]);
    const [kullanilabilirMikrobiolojikTestler, setKullanilabilirMikrobiolojikTestler] = useState([
        "Brucella agl.(rose bengal)", "Brucella tüp(wright) agl", "Brucella tüp agl.(coobs)",
        "Brucella tüp agl(2-Merkaptoetanol)", "Helicobacter pylori anikoru(serum)", "Salmonella Tüp agl(grubel-widal)",
        "Sifiliz(VDRL)Kaset Test"
    ]);
    // Kültür Testleri için state ve handler
    const [secilenKulturTestleri, setSecilenKulturTestleri] = useState([]);
    const [kullanilabilirKulturTestleri, setKullanilabilirKulturTestleri] = useState([
        "Balgam Külturü", "Boğaz Külturü", "Gaita Külturü", "idrar Kültürü", "Yara Kültürü"
    ]);
    const [kayitlar, setKayitlar] = useState([]);
    const [filtreler, setFiltreler] = useState(
        {
            hastaTakipNo: '',
            adi: '',
            soyadi: '',

        }
    );
    const [secilenKayit, setSecilenKayit] = useState(null);
    const [duzenlemeFormuGoster, setDuzenlemeFormuGoster] = useState(false);
    const [duzenlenecekKayit, setDuzenlenecekKayit] = useState({});
    const [baslangicTarihi, setBaslangicTarihi] = useState('');
    const [bitisTarihi, setBitisTarihi] = useState('');
    const [labs, setLabs] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTestRequest, setSelectedTestRequest] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await apiManager.get('/tetkik-takip-istek-raporu').then((data) => {
                    if (data) {
                        setKayitlar(data);
                    } else {
                        console.error('Veri yüklenemedi.');
                    }
                });
            } catch (error) {
                console.error('Sunucu hatası:', error);
            }
        };

        fetchData();
    }, []);

    const handleUpdate = async (e) => {
        e.preventDefault();

        const formattedDate = formatDateToInput(duzenlenecekKayit.tarih);

        // Güncellenecek kaydı oluştur
        const updatedRecord = {
            ...duzenlenecekKayit,
            tarih: formattedDate
        };

        try {
            // `axios` kullanarak API yöneticiniz aracılığıyla PATCH isteğini gönderin
            await apiManager.patch(`/tetkik-takip-kayit/${duzenlenecekKayit.hastaTakipNo}`, updatedRecord);

            // Güncellenmiş kayıt bilgisi alındıktan sonra tüm kayıtları yeniden çek
            await fetchAllRecords(); // Bu fonksiyonunuzu ve işlevselliğini doğru şekilde tanımladığınızdan emin olun

            // Düzenleme formunu kapat
            setDuzenlemeFormuGoster(false);

            // Kullanıcıya başarılı güncelleme hakkında bilgi ver
            alert('Kayıt başarıyla güncellendi.');
        } catch (error) {
            console.error('Güncelleme sırasında hata oluştu:', error);
            alert('Güncelleme işlemi sırasında bir hata oluştu.');
        }
    };

    const fetchAllRecords = async () => {
        try {
            await apiManager.get('/tetkik-takip-istek-raporu').then((data) => {
                if (data) {
                    setKayitlar(data);
                } else {
                    console.error('Veri yüklenemedi.');
                }
            });
        } catch (error) {
            console.error('Sunucu hatası:', error);
        }
    }
    useEffect(() => {
        const fetchPharmacies = async () => {
            try {
                const response = await apiManager.get('/labs');
                setLabs(response);
                console.log(response)
            } catch (error) {
                console.error('Labaratuvarlar yüklenirken bir hata oluştu:', error);

            }
        };

        fetchPharmacies();
    }, []);

    const handleDelete = async (kayitId) => {
        if (window.confirm(`Kaydı silmek istediğinize emin misiniz? ID: ${kayitId.hastaTakipNo}`)) {
            try {
                // `apiManager` kullanarak `DELETE` isteğini yap
                await apiManager.del(`/tetkik-takip-kayit/${kayitId.hastaTakipNo}`);

                console.log('Kayıt başarıyla silindi');
                await fetchAllRecords(); // Tüm kayıtları yeniden çek
            } catch (error) {
                console.error('Silme işlemi sırasında hata oluştu:', error);
            }
        }
    };



    const handleRowClick = (kayit) => {
        setSecilenKayit(kayit);
    };
    const kapatDetayPaneli = () => {
        setSecilenKayit(null);
    };
    const handleFilterChange = (e) => {
        setFiltreler({ ...filtreler, [e.target.name]: e.target.value.toLowerCase() });
    };
    const handleEdit = (kayit) => {
        // Tarih formatını düzelt
        const duzenlenmisKayit = {
            ...kayit,
            hemotolojikTestler: Array.isArray(kayit.hemotolojikTestler) ? kayit.hemotolojikTestler : JSON.parse(kayit.hemotolojikTestler || '[]'),
            koagulasyonTestleri: Array.isArray(kayit.koagulasyonTestleri) ? kayit.koagulasyonTestleri : JSON.parse(kayit.koagulasyonTestleri || '[]'),
            bioKimyasalTestler: Array.isArray(kayit.bioKimyasalTestler) ? kayit.bioKimyasalTestler : JSON.parse(kayit.bioKimyasalTestler || '[]'),
            kardiyakTestler: Array.isArray(kayit.kardiyakTestler) ? kayit.kardiyakTestler : JSON.parse(kayit.kardiyakTestler || '[]'),
            hormonTestleri: Array.isArray(kayit.hormonTestleri) ? kayit.hormonTestleri : JSON.parse(kayit.hormonTestleri || '[]'),
            triodTestleri: Array.isArray(kayit.triodTestleri) ? kayit.triodTestleri : JSON.parse(kayit.triodTestleri || '[]'),
            tumorMarkerlar: Array.isArray(kayit.tumorMarkerlar) ? kayit.tumorMarkerlar : JSON.parse(kayit.tumorMarkerlar || '[]'),
            hepatitMarkerlar: Array.isArray(kayit.hepatitMarkerlar) ? kayit.hepatitMarkerlar : JSON.parse(kayit.hepatitMarkerlar || '[]'),
            mikrobiolojikTestler: Array.isArray(kayit.mikrobiolojikTestler) ? kayit.mikrobiolojikTestler : JSON.parse(kayit.mikrobiolojikTestler || '[]'),
            kulturTestleri: Array.isArray(kayit.kulturTestleri) ? kayit.kulturTestleri : JSON.parse(kayit.kulturTestleri || '[]')
        };

        setDuzenlenecekKayit(duzenlenmisKayit);
        setDuzenlemeFormuGoster(true);
    };
    const kapatGuncellemePaneli = () => {
        setDuzenlemeFormuGoster(false);
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDuzenlenecekKayit(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    function formatDateToInput(dateStr) {
        if (!dateStr) return '';

        const date = new Date(dateStr);
        const year = date.getFullYear();
        let month = (date.getMonth() + 1).toString();
        let day = date.getDate().toString();

        month = month.length < 2 ? '0' + month : month;
        day = day.length < 2 ? '0' + day : day;

        return `${year}-${month}-${day}`;
    }
    const yazdirTumKayitlari = () => {
        const yeniSekme = window.open('', '_blank');
        yeniSekme.document.open();

        let htmlContent = `
            <html>
            <head>
                <title>Tüm Kayıtlar</title>
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">
                <style>
                    body { font-size: 10px; }
                    .table { width: 100%; table-layout: fixed; }
                    .table th, .table td { word-wrap: break-word; overflow-wrap: break-word; }
                    @media print {
                        body { font-size: 8px; }
                        .table { font-size: 8px; }
                    }
                </style>
            </head>
            <body>
                <h2>Tetkik Takip İstek Raporları</h2>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Hasta Takip No</th>
                            <th>Tarih</th>
                            <th>Adı</th>
                            <th>Soyadı</th>
                            <th>Hemotolojik Testler</th>
                            <th>Koagulasyon Testleri</th>
                            <th>Bio Kimyasal Testleri</th>
                            <th>Kardiyak Testleri</th>
                            <th>Hormon Testleri</th>
                            <th>Triod Testleri</th>
                            <th>Tümör Markerlar</th>
                            <th>Hepatit Markerlar</th>
                            <th>Mikrobiolojik Testler</th>
                            <th>Kültür Testler</th>
                        </tr>
                    </thead>
                    <tbody>`;

        kayitlar.forEach(kayit => {
            htmlContent += `
                        <tr>
                            <td>${kayit.hastaTakipNo}</td>
                            <td>${formatDate(kayit.tarih)}</td>
                            <td>${kayit.adi}</td>
                            <td>${kayit.soyadi}</td>
                            <td>${kayit.hemotolojikTestler ? Array.isArray(kayit.hemotolojikTestler) ? kayit.hemotolojikTestler.join(', ') : JSON.parse(kayit.hemotolojikTestler).join(', ') : ''}</td>
                            <td>${kayit.koagulasyonTestleri ? Array.isArray(kayit.koagulasyonTestleri) ? kayit.koagulasyonTestleri.join(', ') : JSON.parse(kayit.koagulasyonTestleri).join(', ') : ''}</td>
                            <td>${kayit.bioKimyasalTestler ? Array.isArray(kayit.bioKimyasalTestler) ? kayit.bioKimyasalTestler.join(', ') : JSON.parse(kayit.bioKimyasalTestler).join(', ') : ''}</td>
                            <td>${kayit.kardiyakTestler ? Array.isArray(kayit.kardiyakTestler) ? kayit.kardiyakTestler.join(', ') : JSON.parse(kayit.kardiyakTestler).join(', ') : ''}</td>
                            <td>${kayit.hormonTestleri ? Array.isArray(kayit.hormonTestleri) ? kayit.hormonTestleri.join(', ') : JSON.parse(kayit.hormonTestleri).join(', ') : ''}</td>
                            <td>${kayit.triodTestleri ? Array.isArray(kayit.triodTestleri) ? kayit.triodTestleri.join(', ') : JSON.parse(kayit.triodTestleri).join(', ') : ''}</td>
                            <td>${kayit.tumorMarkerlar ? Array.isArray(kayit.tumorMarkerlar) ? kayit.tumorMarkerlar.join(', ') : JSON.parse(kayit.tumorMarkerlar).join(', ') : ''}</td>
                            <td>${kayit.hepatitMarkerlar ? Array.isArray(kayit.hepatitMarkerlar) ? kayit.hepatitMarkerlar.join(', ') : JSON.parse(kayit.hepatitMarkerlar).join(', ') : ''}</td>
                            <td>${kayit.mikrobiolojikTestler ? Array.isArray(kayit.mikrobiolojikTestler) ? kayit.mikrobiolojikTestler.join(', ') : JSON.parse(kayit.mikrobiolojikTestler).join(', ') : ''}</td>
                            <td>${kayit.kulturTestleri ? Array.isArray(kayit.kulturTestleri) ? kayit.kulturTestleri.join(', ') : JSON.parse(kayit.kulturTestleri).join(', ') : ''}</td>
                        </tr>`;
        });

        htmlContent += `
                    </tbody>
                </table>
                <script>
                    window.onload = function() {
                        window.print();
                    };
                </script>
            </body>
            </html>`;

        yeniSekme.document.write(htmlContent);
        yeniSekme.document.close();
    };
    const tarihFiltreliKayitlar = kayitlar.filter(kayit => {
        const kayitTarihi = new Date(kayit.tarih);
        let basTarihi = baslangicTarihi ? new Date(baslangicTarihi) : new Date('1900-01-01');
        let bitTarihi = bitisTarihi ? new Date(bitisTarihi) : new Date('2100-12-31');
    
        // Başlangıç tarihini günün başlangıcı olarak ayarla
        basTarihi.setHours(0,0,0,0);
    
        // Bitiş tarihini günün sonu olarak ayarla
        bitTarihi.setHours(23,59,59,999);
    
        return kayitTarihi >= basTarihi && kayitTarihi <= bitTarihi;
    });
    
    
    const filterRecords = (records, filterValues) => {
        return records.filter((record) => {
          // Tüm filtreler için doğrulama
          return Object.keys(filterValues).every((key) => {
            const filterValue = filterValues[key].toLowerCase();
            // Filtre değeri boş ise, filtre uygulanmaz
            if (!filterValue) return true;
      
            // Kayıttaki değer
            const recordValue = record[key] ? record[key].toString().toLowerCase() : '';
      
            // Birden fazla filtre terimi için destek (virgülle ayrılmış)
            const filterTerms = filterValue.split(',').map(term => term.trim());
      
            // Her bir filtre teriminin kayıttaki değerde bulunup bulunmadığını kontrol et
            return filterTerms.every(term => recordValue.includes(term));
          });
        });
      };
      


      const filteredKayitlar = filterRecords(tarihFiltreliKayitlar, filtreler);

    const handleTestSelection = (e, testCategory) => {
        const selectedTests = [...duzenlenecekKayit[testCategory], e.target.value];
        setDuzenlenecekKayit(prevState => ({
            ...prevState,
            [testCategory]: selectedTests
        }));
    };
    function formatTestRequestDetails(details) {
        // Tarihi daha anlaşılır bir formata çevirme
        const date = new Date(details.tarih);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;

        return `
        <p>Adı: ${details.adi}</p>
        <p>Soyadı: ${details.soyadi}</p>
        <p>Hemotolojik Testler: ${details.hemotolojikTestler ? Array.isArray(details.hemotolojikTestler) ? details.hemotolojikTestler.join(', ') : JSON.parse(details.hemotolojikTestler).join(', ') : ''}</p>
        <p>Koagulasyon Testleri: ${details.koagulasyonTestleri ? Array.isArray(details.koagulasyonTestleri) ? details.koagulasyonTestleri.join(', ') : JSON.parse(details.koagulasyonTestleri).join(', ') : ''}</p>
        <p>Bio Kimyasal Testleri: ${details.bioKimyasalTestler ? Array.isArray(details.bioKimyasalTestler) ? details.bioKimyasalTestler.join(', ') : JSON.parse(details.bioKimyasalTestler).join(', ') : ''}</p>
        <p>Kardiyak Testleri: ${details.kardiyakTestler ? Array.isArray(details.kardiyakTestler) ? details.kardiyakTestler.join(', ') : JSON.parse(details.kardiyakTestler).join(', ') : ''}</p>
        <p>Hormon Testleri: ${details.hormonTestleri ? Array.isArray(details.hormonTestleri) ? details.hormonTestleri.join(', ') : JSON.parse(details.hormonTestleri).join(', ') : ''}</p>
        <p>Triod Testleri: ${details.triodTestleri ? Array.isArray(details.triodTestleri) ? details.triodTestleri.join(', ') : JSON.parse(details.triodTestleri).join(', ') : ''}</p>
        <p>Tümör Markerlar: ${details.tumorMarkerlar ? Array.isArray(details.tumorMarkerlar) ? details.tumorMarkerlar.join(', ') : JSON.parse(details.tumorMarkerlar).join(', ') : ''}</p>
        <p>Hepatit Markerlar: ${details.hepatitMarkerlar ? Array.isArray(details.hepatitMarkerlar) ? details.hepatitMarkerlar.join(', ') : JSON.parse(details.hepatitMarkerlar).join(', ') : ''}</p>
        <p>Mikrobiolojik Testler: ${details.mikrobiolojikTestler ? Array.isArray(details.mikrobiolojikTestler) ? details.mikrobiolojikTestler.join(', ') : JSON.parse(details.mikrobiolojikTestler).join(', ') : ''}</p>
        <p>Kültür Testleri: ${details.kulturTestleri ? Array.isArray(details.kulturTestleri) ? details.kulturTestleri.join(', ') : JSON.parse(details.kulturTestleri).join(', ') : ''}</p>`;
    }
    const sendTestRequestEmail = async (labEmail, TestRequestDetails, additionalComments) => {
        const formattedDetails = formatTestRequestDetails(TestRequestDetails);
        console.log(TestRequestDetails)
        console.log(formattedDetails)

        try {
            await apiManager.post('/email/send-test-request', {
                email: labEmail,
                subject: 'Tetkik Takip Bilgileri',
                TestRequestDetails: formattedDetails, // Gönderilecek metin formatında reçete bilgileri
                additionalComments: additionalComments
            });
            alert('E-posta başarıyla gönderildi.');
        } catch (error) {
            console.error('E-posta gönderilirken bir hata oluştu:', error);
        }
    };
    useEffect(() => {
        console.log(labs);
    }, [labs]);
    function TestRequestModal({ isOpen, onClose, onSend, labs }) {
        const [selectedlabEmail, setSelectedLabEmail] = useState('');
        const [additionalComments, setAdditionalComments] = useState(''); // Ek açıklamalar için state
        const handleCommentsChange = (e) => setAdditionalComments(e.target.value);
        // useEffect(() => {
        //     console.log(labs);
        // }, [labs]);
        return (
            <Modal isOpen={isOpen} onRequestClose={onClose} style={{ content: { top: '50%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)', width: 'auto', maxWidth: '500px' }, overlay: { background: "rgba(0, 0, 0, 0.75)" } }}>
  <div className="modal-content">
    <div className="modal-header text-center">
      <h5 className="modal-title w-100 mb-4">Laboratuvar Seçimi</h5>
    </div>
    <div className="modal-body">
      <div className="d-flex justify-content-center">
        <div className="w-100">
          <form>
            <div className="form-group mb-3">
              <label htmlFor="labSelect">Laboratuvar Seçiniz</label>
              <select id="labSelect" className="form-control" onChange={(e) => setSelectedLabEmail(e.target.value)} value={selectedlabEmail}>
                <option value="">Seçiniz...</option>
                {labs && labs.length > 0 && labs.map((lab) => (
                  <option key={lab.uyeId} value={lab.email}>{lab.unvani}</option>
                ))}
              </select>
            </div>
            <div className="form-group mb-3">
                                        <label htmlFor="additionalComments">Ek Açıklamalar</label>
                                        <textarea id="additionalComments" className="form-control" rows="3" onChange={handleCommentsChange} value={additionalComments}></textarea>
                                    </div>
          </form>
        </div>
      </div>
    </div>
    <div className="modal-footer justify-content-center mt-4">
      <button type="button" className="btn btn-primary me-2" onClick={() => onSend(selectedlabEmail, additionalComments)}>Gönder</button>
      <button type="button" className="btn btn-secondary" onClick={onClose}>Kapat</button>
    </div>
  </div>
</Modal>

        );
    }

    const handleSendEmailClick = (testRequest) => {
        setSelectedTestRequest(testRequest);
        setIsModalOpen(true);
    };

    // Modal'dan gelen onSend işleyicisi...
    const handleModalSend = (labEmail, additionalComments) => {
        if (selectedTestRequest && labEmail) {
            sendTestRequestEmail(labEmail, selectedTestRequest, additionalComments);
            setIsModalOpen(false); // Modalı kapat
        } else {
            alert('Lütfen bir eczane seçiniz.');
        }
    };

    const handleEmailSend = (kayit) => {
        // E-posta gönderme işlemi için gerekli kodlar
        // Örneğin:
        setSelectedTestRequest(kayit);
        setIsModalOpen(true);
    };



    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-2 no-padding-left">
                    <LeftSidebar />
                </div>
                <div className="col-md-10">
                <h2 style={{ backgroundColor: '#2c3e50',color:'white', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', fontSize:'1.6rem'}}>Tetkik Takip İstek Raporu</h2>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px', padding: '5px 10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9' }}>
                        <p style={{ margin: 0 }}>Kayıt Detayları ve Yazdırma İçin İlgili Kaydın Üzerine Tıklayınız.</p>
                        <button type="button" onClick={yazdirTumKayitlari} className="btn btn-primary">Tüm Kayıtları Yazdır</button>
                    </div>
                    <div className="filter-section" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <div style={{ marginRight: '20px' }}>
                            <label htmlFor="baslangicTarihi">Başlangıç Tarihi:</label>
                            <input
                                type="date"
                                id="baslangicTarihi"
                                value={baslangicTarihi}
                                onChange={(e) => setBaslangicTarihi(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div>
                            <label htmlFor="bitisTarihi">Bitiş Tarihi:</label>
                            <input
                                type="date"
                                id="bitisTarihi"
                                value={bitisTarihi}
                                onChange={(e) => setBitisTarihi(e.target.value)}
                                className="form-control"
                            />
                        </div>
                    </div>
                    {/* DetayPaneli ve Düzenleme Formu */}
                    <DetayPaneli kayit={secilenKayit} kapat={kapatDetayPaneli} onEmailSend={handleEmailSend} />
                    {/* Düzenleme formunu ve işlevlerini buraya ekleyin */}
                    {
                        duzenlemeFormuGoster && (
                            <div className="update-panel card mb-3">
                                <div className="card-header">
                                    <h3>Kayıt Güncelle</h3>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={handleUpdate} className="row g-3">
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label htmlFor="hastaTakipNo" className="form-label">Hasta Takip No:</label>
                                                <input type="text" className="form-control" id="hastaTakipNo" value={duzenlenecekKayit.hastaTakipNo} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="tarih" className="form-label">Tarih:</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    id="tarih"
                                                    name="tarih"
                                                    value={formatDateToInput(duzenlenecekKayit.tarih)}
                                                    onChange={handleInputChange}
                                                />

                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="adi" className="form-label">Adı:</label>
                                                <input type="text" className="form-control" id="adi" name="adi" value={duzenlenecekKayit.adi} onChange={handleInputChange} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="soyadi" className="form-label">Soyadı:</label>
                                                <input type="text" className="form-control" id="soyadi" name="soyadi" value={duzenlenecekKayit.soyadi} onChange={handleInputChange} />
                                            </div>
                                            {/* Hemotolojik Testler */}
                                            <div className="mb-3">
                                                <label htmlFor="hemotolojikTestler" className="form-label">Hemotolojik Testler:</label>
                                                <select className="form-select" id="hemotolojikTestler" onChange={(e) => handleTestSelection(e, 'hemotolojikTestler')}>
                                                    <option value="">Seçiniz</option>
                                                    {kullanilabilirHemotolojikTestler.map((test, index) => (
                                                        <option key={index} value={test}>{test}</option>
                                                    ))}
                                                </select>
                                                <input type="text" className="form-control" id="hemotolojikTestler" value={duzenlenecekKayit.hemotolojikTestler?.join(', ')} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, hemotolojikTestler: e.target.value.split(', ') })} />
                                                <div className="test-list mt-2">
                                                    {secilenHemotolojikTestler.map((test, index) => (
                                                        <span key={index} className="badge bg-primary me-2">{test}</span>
                                                    ))}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="koagulasyonTestleri" className="form-label">Koagülasyon Testleri:</label>
                                                    <select className="form-select" id="koagulasyonTestleri" onChange={(e) => handleTestSelection(e, 'koagulasyonTestleri')}>
                                                        <option value="">Seçiniz</option>
                                                        {kullanilabilirKoagulasyonTestleri.map((test, index) => (
                                                            <option key={index} value={test}>{test}</option>
                                                        ))}
                                                    </select>
                                                    <input type="text" className="form-control" id="koagulasyonTestleriValue" value={duzenlenecekKayit.koagulasyonTestleri?.join(', ')} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, koagulasyonTestleri: e.target.value.split(', ') })} />
                                                    <div className="test-list mt-2">
                                                        {secilenKoagulasyonTestleri.map((test, index) => (
                                                            <span key={index} className="badge bg-secondary me-2">{test}</span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-4">

                                            {/* Bio Kimyasal Testler */}
                                            <div className="mb-3">
                                                <label htmlFor="bioKimyasalTestler" className="form-label">Bio Kimyasal Testler:</label>
                                                <select className="form-select" id="bioKimyasalTestler" onChange={(e) => handleTestSelection(e, 'bioKimyasalTestler')}>
                                                    <option value="">Seçiniz</option>
                                                    {kullanilabilirBioKimyasalTestler.map((test, index) => (
                                                        <option key={index} value={test}>{test}</option>
                                                    ))}
                                                </select>
                                                <input type="text" className="form-control" id="bioKimyasalTestlerValue" value={duzenlenecekKayit.bioKimyasalTestler?.join(', ')} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, bioKimyasalTestler: e.target.value.split(', ') })} />
                                                <div className="test-list mt-2">
                                                    {secilenBioKimyasalTestler.map((test, index) => (
                                                        <span key={index} className="badge bg-success me-2">{test}</span>
                                                    ))}
                                                </div>
                                            </div>

                                            {/* Kardiyak Testler */}
                                            <div className="mb-3">
                                                <label htmlFor="kardiyakTestler" className="form-label">Kardiyak Testler:</label>
                                                <select className="form-select" id="kardiyakTestler" onChange={(e) => handleTestSelection(e, 'kardiyakTestler')}>
                                                    <option value="">Seçiniz</option>
                                                    {kullanilabilirKardiyakTestler.map((test, index) => (
                                                        <option key={index} value={test}>{test}</option>
                                                    ))}
                                                </select>
                                                <input type="text" className="form-control" id="kardiyakTestlerValue" value={duzenlenecekKayit.kardiyakTestler?.join(', ')} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, kardiyakTestler: e.target.value.split(', ') })} />
                                                <div className="test-list mt-2">
                                                    {secilenKardiyakTestler.map((test, index) => (
                                                        <span key={index} className="badge bg-info me-2">{test}</span>
                                                    ))}
                                                </div>
                                            </div>
                                            {/* Hormon Testleri */}
                                            <div className="mb-3">
                                                <label htmlFor="hormonTestleri" className="form-label">Hormon Testleri:</label>
                                                <select className="form-select" id="hormonTestleri" onChange={(e) => handleTestSelection(e, 'hormonTestleri')}>
                                                    <option value="">Seçiniz</option>
                                                    {kullanilabilirHormonTestleri.map((test, index) => (
                                                        <option key={index} value={test}>{test}</option>
                                                    ))}
                                                </select>
                                                <input type="text" className="form-control" id="hormonTestleriValue" value={duzenlenecekKayit.hormonTestleri?.join(', ')} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, hormonTestleri: e.target.value.split(', ') })} />
                                                <div className="test-list mt-2">
                                                    {secilenHormonTestleri.map((test, index) => (
                                                        <span key={index} className="badge bg-warning me-2">{test}</span>
                                                    ))}
                                                </div>
                                            </div>
                                            {/* Triod Testleri */}
                                            <div className="mb-3">
                                                <label htmlFor="triodTestleri" className="form-label">Triod Testleri:</label>
                                                <select className="form-select" id="triodTestleri" onChange={(e) => handleTestSelection(e, 'triodTestleri')}>
                                                    <option value="">Seçiniz</option>
                                                    {kullanilabilirTriodTestleri.map((test, index) => (
                                                        <option key={index} value={test}>{test}</option>
                                                    ))}
                                                </select>
                                                <input type="text" className="form-control" id="triodTestleriValue" value={duzenlenecekKayit.triodTestleri?.join(', ')} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, triodTestleri: e.target.value.split(', ') })} />
                                                <div className="test-list mt-2">
                                                    {secilenTriodTestleri.map((test, index) => (
                                                        <span key={index} className="badge bg-dark me-2">{test}</span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Üçüncü sütun */}
                                        <div className="col-md-4">


                                            {/* Tümör Markerlar */}
                                            <div className="mb-3">
                                                <label htmlFor="tumorMarkerlar" className="form-label">Tümör Markerlar:</label>
                                                <select className="form-select" id="tumorMarkerlar" onChange={(e) => handleTestSelection(e, 'tumorMarkerlar')}>
                                                    <option value="">Seçiniz</option>
                                                    {kullanilabilirTumorMarkerlar.map((test, index) => (
                                                        <option key={index} value={test}>{test}</option>
                                                    ))}
                                                </select>
                                                <input type="text" className="form-control" id="tumorMarkerlarValue" value={duzenlenecekKayit.tumorMarkerlar?.join(', ')} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, tumorMarkerlar: e.target.value.split(', ') })} />
                                                <div className="test-list mt-2">
                                                    {secilenTumorMarkerlar.map((test, index) => (
                                                        <span key={index} className="badge bg-danger me-2">{test}</span>
                                                    ))}
                                                </div>
                                            </div>

                                            {/* Hepatit Markerlar */}
                                            <div className="mb-3">
                                                <label htmlFor="hepatitMarkerlar" className="form-label">Hepatit Markerlar:</label>
                                                <select className="form-select" id="hepatitMarkerlar" onChange={(e) => handleTestSelection(e, 'hepatitMarkerlar')}>
                                                    <option value="">Seçiniz</option>
                                                    {kullanilabilirHepatitMarkerlar.map((test, index) => (
                                                        <option key={index} value={test}>{test}</option>
                                                    ))}
                                                </select>
                                                <input type="text" className="form-control" id="hepatitMarkerlarValue" value={duzenlenecekKayit.hepatitMarkerlar?.join(', ')} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, hepatitMarkerlar: e.target.value.split(', ') })} />
                                                <div className="test-list mt-2">
                                                    {secilenHepatitMarkerlar.map((test, index) => (
                                                        <span key={index} className="badge bg-secondary me-2">{test}</span>
                                                    ))}
                                                </div>
                                            </div>

                                            {/* Mikrobiolojik Testler */}
                                            <div className="mb-3">
                                                <label htmlFor="mikrobiolojikTestler" className="form-label">Mikrobiolojik Testler:</label>
                                                <select className="form-select" id="mikrobiolojikTestler" onChange={(e) => handleTestSelection(e, 'mikrobiolojikTestler')}>
                                                    <option value="">Seçiniz</option>
                                                    {kullanilabilirMikrobiolojikTestler.map((test, index) => (
                                                        <option key={index} value={test}>{test}</option>
                                                    ))}
                                                </select>
                                                <input type="text" className="form-control" id="mikrobiolojikTestlerValue" value={duzenlenecekKayit.mikrobiolojikTestler?.join(', ')} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, mikrobiolojikTestler: e.target.value.split(', ') })} />
                                                <div className="test-list mt-2">
                                                    {secilenMikrobiolojikTestler.map((test, index) => (
                                                        <span key={index} className="badge bg-success me-2">{test}</span>
                                                    ))}
                                                </div>
                                            </div>

                                            {/* Kültür Testleri */}
                                            <div className="mb-3">
                                                <label htmlFor="kulturTestleri" className="form-label">Kültür Testleri:</label>
                                                <select className="form-select" id="kulturTestleri" onChange={(e) => handleTestSelection(e, 'kulturTestleri')}>
                                                    <option value="">Seçiniz</option>
                                                    {kullanilabilirKulturTestleri.map((test, index) => (
                                                        <option key={index} value={test}>{test}</option>
                                                    ))}
                                                </select>
                                                <input type="text" className="form-control" id="kulturTestleriValue" value={duzenlenecekKayit.kulturTestleri?.join(', ')} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, kulturTestleri: e.target.value.split(', ') })} />
                                                <div className="test-list mt-2">
                                                    {secilenKulturTestleri.map((test, index) => (
                                                        <span key={index} className="badge bg-info me-2">{test}</span>
                                                    ))}
                                                </div>
                                            </div>

                                            {/* Diğer test kategorileri için de aynı yapıyı kullanabilir ve gerektiği şekilde kopyalayıp yapıştırabilirsiniz. */}
                                        </div>
                                        {/* Koagülasyon Testleri */}






                                        <div className="col-12">
                                            <button type="submit" className="btn btn-primary me-2">Güncelle</button>
                                            <button type="button" onClick={kapatGuncellemePaneli} className="btn btn-danger">Kapat</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )
                    }

                    {/* Filtreleme formu ve tablo */}
                    <div className="table-responsive" style={{ maxHeight: '75vh', overflowX: 'auto' }}>
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>İşlemler</th>
                                    <th>Hasta Takip No<input type="text" name="hastaTakipNo" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>Tarih</th>
                                    <th>Adı<input type="text" name="adi" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>Soyadı<input type="text" name="soyadi" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>Hemotolojik Testler<input type="text" name="hemotolojikTestler" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>Koagülasyon Testleri<input type="text" name="koagulasyonTestleri" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>Bio Kimyasal Testler<input type="text" name="bioKimyasalTestler" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>Kardiyak Testler<input type="text" name="kardiyakTestler" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>Hormon Testleri<input type="text" name="hormonTestleri" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>Triod Testleri<input type="text" name="triodTestleri" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>Tümör Markerlar<input type="text" name="tumorMarkerlar" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>Hepatit Markerlar<input type="text" name="hepatitMarkerlar" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>Mikrobiolojik Testler<input type="text" name="mikrobiolojikTestler" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>Kültür Testleri<input type="text" name="kulturTestleri" onChange={handleFilterChange} className="form-control" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredKayitlar.map((kayit, index) => (
                                    <tr key={index} onClick={() => handleRowClick(kayit)} className="clickable-row">
                                        <td>
                                            {/* Düzenle ve Sil butonları */}
                                            <button onClick={(e) => { e.stopPropagation(); handleEdit(kayit); }} className="btn btn-primary btn-sm btn-extra-sm me-1">
                                                <FontAwesomeIcon icon={faEdit} />
                                            </button>
                                            <button onClick={(e) => { e.stopPropagation(); handleDelete(kayit); }} className="btn btn-danger btn-sm btn-extra-sm">
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </button>
                                        </td>
                                        <td>{kayit.hastaTakipNo}</td>
                                        <td>{formatDate(kayit.tarih)}</td>
                                        <td>{kayit.adi ? (kayit.adi.length > 10 ? kayit.adi.substring(0, 10) + '...' : kayit.adi) : ''}</td>
                                        <td>{kayit.soyadi ? (kayit.soyadi.length > 10 ? kayit.soyadi.substring(0, 10) + '...' : kayit.soyadi) : ''}</td>
                                        <td>{kayit.hemotolojikTestler ? Array.isArray(kayit.hemotolojikTestler) ? kayit.hemotolojikTestler.join(', ') : JSON.parse(kayit.hemotolojikTestler).join(', ') : ''}</td>
                                        <td>{kayit.koagulasyonTestleri ? Array.isArray(kayit.koagulasyonTestleri) ? kayit.koagulasyonTestleri.join(', ') : JSON.parse(kayit.koagulasyonTestleri).join(', ') : ''}</td>
                                        <td>{kayit.bioKimyasalTestler ? Array.isArray(kayit.bioKimyasalTestler) ? kayit.bioKimyasalTestler.join(', ') : JSON.parse(kayit.bioKimyasalTestler).join(', ') : ''}</td>
                                        <td>{kayit.kardiyakTestler ? Array.isArray(kayit.kardiyakTestler) ? kayit.kardiyakTestler.join(', ') : JSON.parse(kayit.kardiyakTestler).join(', ') : ''}</td>
                                        <td>{kayit.hormonTestleri ? Array.isArray(kayit.hormonTestleri) ? kayit.hormonTestleri.join(', ') : JSON.parse(kayit.hormonTestleri).join(', ') : ''}</td>
                                        <td>{kayit.triodTestleri ? Array.isArray(kayit.triodTestleri) ? kayit.triodTestleri.join(', ') : JSON.parse(kayit.triodTestleri).join(', ') : ''}</td>
                                        <td>{kayit.tumorMarkerlar ? Array.isArray(kayit.tumorMarkerlar) ? kayit.tumorMarkerlar.join(', ') : JSON.parse(kayit.tumorMarkerlar).join(', ') : ''}</td>
                                        <td>{kayit.hepatitMarkerlar ? Array.isArray(kayit.hepatitMarkerlar) ? kayit.hepatitMarkerlar.join(', ') : JSON.parse(kayit.hepatitMarkerlar).join(', ') : ''}</td>
                                        <td>{kayit.mikrobiolojikTestler ? Array.isArray(kayit.mikrobiolojikTestler) ? kayit.mikrobiolojikTestler.join(', ') : JSON.parse(kayit.mikrobiolojikTestler).join(', ') : ''}</td>
                                        <td>{kayit.kulturTestleri ? Array.isArray(kayit.kulturTestleri) ? kayit.kulturTestleri.join(', ') : JSON.parse(kayit.kulturTestleri).join(', ') : ''}</td>

                                        {/* Diğer alanları buraya ekleyin */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <TestRequestModal
                            isOpen={isModalOpen}
                            onClose={() => setIsModalOpen(false)}
                            onSend={handleModalSend}
                            labs={labs} // Bu satır çok önemli
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TetkikTakipIstekRaporu;
