// RegisterPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const RegisterPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:5000/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, role: 'user' }), // role'i varsayılan olarak 'user' olarak ayarlayabilirsiniz
      });

      if (response.ok) {
        alert('Kayıt başarılı! Giriş yapabilirsiniz.');
        navigate('http://localhost:3000/login'); // Kullanıcıyı giriş sayfasına yönlendir
      } else {
        alert('Kayıt sırasında bir hata oluştu.');
      }
    } catch (error) {
      console.error('Kayıt işlemi sırasında bir hata oluştu:', error);
    }
  };

  return (
    <div>
      <h2>Kayıt Ol</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>E-posta:</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div>
          <label>Şifre:</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </div>
        <button type="submit">Kayıt Ol</button>
      </form>
    </div>
  );
};

export default RegisterPage;
