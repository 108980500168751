import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext'; // Yolun doğru olduğundan emin olun

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated, loading } = useContext(AuthContext);

    if (loading) {
        return <div>Yükleniyor...</div>; // Yükleme göstergesi veya benzeri bir içerik
    }

    return isAuthenticated ? children : <Navigate to="/login" replace />;
};



export default ProtectedRoute;

