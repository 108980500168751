// src/pages/Panel.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState,useEffect } from 'react';
import LeftSidebar from '../components/LeftSidebar';
import DoctorInfo from '../components/DoctorInfo';
// import ScheduleFilter from '../components/ScheduleFilter';
// import ScheduleHeader from '../components/ScheduleHeader';
// import ScheduleChart from '../components/ScheduleChart';
import UpcomingAppointments from '../components/UpcomingAppointments';
// import HastaKayitFormu from './HastaKayitFormu'
// import HastaKayitRaporu from './HastaKayitRaporu'
// import './Panel.css'; // Stil dosyasını içeriye dahil edin
import ScheduleCalendar from '../components/ScheduleCalendar';
import apiManager from '../service/apiManager';

const Panel = () => {
 
  const [appointments, setAppointments] = useState([]);
  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        await apiManager.get('/randevular').then((data) => {
            if (data) {
                setAppointments(data);
            } else {
                console.error('Veri yüklenemedi.');
            }
        });
    } catch (error) {
        console.error('Sunucu hatası:', error);
    }
    };
    
  
    fetchAppointments();
  }, []);
  
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2 no-padding-left">
  <LeftSidebar />
</div>
        <div className="col-md-10"> {/* Ana içerik alanı için genişlik */}
          <DoctorInfo appointments={appointments} />
          <div className="row mt-3">
            <div className="col-lg-9"> {/* ScheduleCalendar için genişletildi */}
              <ScheduleCalendar appointments={appointments} className="schedule-calendar" />
            </div>
            <div className="col-lg-3"> {/* UpcomingAppointments için daraltıldı */}
              <UpcomingAppointments appointments={appointments} className="upcoming-appointments" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Panel;
