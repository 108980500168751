// src/pages/RandevuOlustur.js
import React, { useState,useEffect,useRef } from 'react';
import LeftSidebar from '../components/LeftSidebar';
import apiManager from '../service/apiManager';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css'; // Flatpickr stil dosyası
import './css/flatPickr.css'

const RandevuOlustur = () => {
  const [hizmet, setHizmet] = useState('');
  const [doktor, setDoktor] = useState('Prof. Dr. Burhan Kabay');
  const [randevuTarihi, setRandevuTarihi] = useState(null);
  const [hastaAdi, setHastaAdi] = useState('');
  const [hastaSoyadi, setHastaSoyadi] = useState('');
  const [telefonNumarasi, setTelefonNumarasi] = useState('');
  const [hastaEposta, setHastaEposta] = useState('');
  const [randevuSaati, setRandevuSaati] = useState('');
  const randevuSaatiRef = useRef(null); // input için ref oluştur

  useEffect(() => {
    flatpickr(randevuSaatiRef.current, {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
      onChange: (selectedDates, dateStr, instance) => {
        setRandevuSaati(dateStr); // Kullanıcı seçimiyle randevuSaati'ni güncelle
      },
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!hizmet) {
      alert('Lütfen İstediğini Hizmeti Seçiniz.');
      return;
    }
    if (!doktor) {
      alert('Lütfen Doktor Seçimini Yapınız.');
      return;
    }
    if (!randevuTarihi) {
      alert('Lütfen randevu tarihini seçiniz.');
      return;
    }
    if (!randevuSaati) {
      alert('Lütfen Randevu Saatini Seçiniz.');
      return;
    }
    if (!hastaAdi) {
      alert('Lütfen Hasta Adı Alanını Doldurunuz.');
      return;
    }
    if (!hastaSoyadi) {
      alert('Lütfen Hasta Soyadı Alanını Doldurunuz.');
      return;
    }
    if (!telefonNumarasi) {
      alert('Lütfen Telefon Numarası Giriniz.');
      return;
    }


    const randevuData = {
      hizmet,
      doktor,
      randevuTarihi: randevuTarihi.toISOString().split('T')[0],
      randevuSaati,
      hastaAdi,
      hastaSoyadi,
      telefonNumarasi,
      hastaEposta
    };

    try {
      await apiManager.post('/randevu-olustur', randevuData ).then(data=>{
        if (data) {
            console.log('Hasta kaydı başarılı.');
            alert('Randevunuz Oluşturuldu.');
            window.location.reload();
        } else {
            console.error('Hata oluştu.');
        }
      })
    } catch (error) {
        console.error('Sunucu hatası:', error);
    } 
  };
  
  // Formu sıfırla
// setHizmet('');
// setRandevuTarihi(null);
// setHastaAdi('');
// setHastaSoyadi('');
// setTelefonNumarasi('');
// setHastaEposta('');
// setRandevuSaati('');
 

  
  const handleReset = () => {
    setHizmet('');
    setRandevuTarihi(null);
    setHastaAdi('');
    setHastaSoyadi('');
    setTelefonNumarasi('');
    setHastaEposta('');
    setRandevuSaati('');
  };


  return (
    <div className="container-fluid">
      <div className="row">
      <div className="col-md-2 no-padding-left">
  <LeftSidebar />
</div>
        <div className="col-md-10">
        <h2 style={{ backgroundColor: '#2c3e50',color:'white', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', fontSize:'1.6rem'}}>Randevu Oluşturma Formu</h2>
          <form onSubmit={handleSubmit} className="row g-3">
            <div className="col-md-6">
              {/* İlk sütundaki form elemanları */}
              <div className="mb-3">
                <label className="form-label">Hizmet Seçimi:<span className="required">*</span></label>
                <select className="form-select" style={{ width: '90%' }} value={hizmet} onChange={(e) => setHizmet(e.target.value)} required>
                  {/* options */}
                  <option value="" hidden>
                Seçiniz...
              </option>
              <option value="Muayene">Muayene</option>
              <option value="Online Danışmanlık">Online Danışmanlık</option>
                </select>
              </div>
              <div className="mb-3">
                <label className="form-label">Doktor:<span className="required">*</span></label>
                <input type="text" className="form-control" style={{ width: '90%' }} value={doktor} readOnly />
              </div>
              <div className="mb-3">
                <label className="form-label">Randevu Tarihi:<span className="required">*</span></label>
                <input type="date" className="form-control" style={{ width: '90%' }} value={randevuTarihi ? randevuTarihi.toISOString().split('T')[0] : ''} onChange={(e) => setRandevuTarihi(new Date(e.target.value))} required />
              </div>
              <div className="mb-3">
            <label className="form-label">Randevu Saati:<span className="required">*</span></label>
            <input ref={randevuSaatiRef} type="text" className="form-control" style={{ width: '90%' }} value={randevuSaati} placeholder="Tıklayınız..." required />
          </div>
              {/* <div className="mb-3">
                <label className="form-label">Randevu Saati:<span className="required">*</span></label>
                <input type="time" className="form-control" style={{ width: '90%' }} value={randevuSaati} onChange={(e) => setRandevuSaati(e.target.value)} required />
              </div> */}
            </div>
            <div className="col-md-6">
              {/* İkinci sütundaki form elemanları */}
              <div className="mb-3">
                <label className="form-label">Hasta Adı:<span className="required">*</span></label>
                <input type="text" className="form-control" style={{ width: '90%' }} value={hastaAdi} onChange={(e) => setHastaAdi(e.target.value)} required />
              </div>
              <div className="mb-3">
                <label className="form-label">Hasta Soyadı:<span className="required">*</span></label>
                <input type="text" className="form-control" style={{ width: '90%' }} value={hastaSoyadi} onChange={(e) => setHastaSoyadi(e.target.value)} required />
              </div>
              <div className="mb-3">
                <label className="form-label">Telefon Numarası:<span className="required">*</span></label>
                <input type="tel" className="form-control" style={{ width: '90%' }} value={telefonNumarasi} onChange={(e) => setTelefonNumarasi(e.target.value)} required />
              </div>
              <div className="mb-3">
                <label className="form-label">Hasta Eposta:</label>
                <input type="email" className="form-control" style={{ width: '90%' }} value={hastaEposta} onChange={(e) => setHastaEposta(e.target.value)} />
              </div>
            </div>
            <div className="col-12">
              <button type="submit" className="btn btn-primary me-2">Randevu Oluştur</button>
              <button type="reset" onClick={handleReset} className="btn btn-secondary">Sıfırla</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RandevuOlustur;
