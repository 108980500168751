import { useEffect, useState } from 'react';
import LeftSidebar from '../components/LeftSidebar';
import apiManager from '../service/apiManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal'

Modal.setAppElement('#root');

function formatDate(dateStr) {
  const date = new Date(dateStr);
  let day = date.getDate().toString();
  let month = (date.getMonth() + 1).toString();
  const year = date.getFullYear();

  day = day.length < 2 ? '0' + day : day;
  month = month.length < 2 ? '0' + month : month;

  return `${day}/${month}/${year}`;
}
const DetayPaneli = ({ kayit, kapat, receteImza, onEmailSend }) => {
  const yazdirKayitDetaylari = () => {
    const yeniSekme = window.open('', '_blank');
    yeniSekme.document.open();

    let htmlContent = `
        <html>
            <head>
            <title>Seçilen Kayıt Detayı</title>
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">
            <style>
                body { padding: 20px; }
                .table-responsive {
                    display: block;
                    width: 100%;
                    overflow-x: auto;
                    -webkit-overflow-scrolling: touch;
                }
                .table {
                    width: 100% !important; /* Genişliği %100 olarak ayarla */
                    margin-bottom: 20px;
                    table-layout: fixed; /* Tablonun layoutunu sabitle */
                }
                .table th, .table td {
                    word-wrap: break-word; /* Uzun metinleri kır */
                    overflow-wrap: break-word; /* İçerik taşarsa alt satıra geç */
                }
                .dosyalar-section { margin-top: 20px; }
            </style>
        </head>
        <body>
                <h2 class="mb-3">Seçilen Kayıt Detayları</h2>
                <table class="table table-bordered">
                    <tbody>
                        <tr><th>Hasta Takip No</th><td>${kayit.hastaTakipNo}</td></tr>
                        <tr><th>Tarih</th><td>${formatDate(kayit.tarih)}</td></tr>
                        <tr><th>Adı</th><td>${kayit.adi}</td></tr>
                        <tr><th>Soyadı</th><td>${kayit.soyadi}</td></tr>
                        <tr><th>Tc Kimlik No</th><td>${(kayit.tcKimlikNo)}</td></tr>
                        <tr><th>Adres</th><td>${kayit.adres}</td></tr>
                        <tr><th>Dr. Adı Soyadı</th><td>${kayit.drAdiSoyadi}</td></tr>
                        <tr><th>Diploma No</th><td>${kayit.tescilNo}</td></tr>
                        <tr><th>Tescil No</th><td>${kayit.tani}</td></tr>
                        <tr><th>Tanı</th><td>${kayit.ilaclar}</td></tr>
                        <tr><th>Reçete No</th><td>${kayit.receteNo}</td></tr>
                    </tbody>
                </table>
                <div class="dosyalar-section">
                    <h4>Reçete İmza</h4>
                    ${dosyalarVeLinkleriOlustur(receteImza)}
                </div>
                <script>
                    window.onload = function() {
                        window.print();

                    };
                </script>
            </body>
        </html>
    `;

    yeniSekme.document.write(htmlContent);
    yeniSekme.document.close();
  };
  const dosyalarVeLinkleriOlustur = (dosyalar) => {
    if (!dosyalar || dosyalar.length === 0) return '<p>Bu kayda ait dosya bulunmamaktadır.</p>';

    let dosyaListesiHTML = '<ul class="list-group">';
    dosyalar.forEach(dosya => {
      dosyaListesiHTML += `<li class="list-group-item">${dosya.dosyaAdi} - <a href="${dosya.url}" target="_blank">Görüntüle</a></li>`;
    });
    dosyaListesiHTML += '</ul>';
    return dosyaListesiHTML;
  };
  // Eğer kayıt null ise, boş bir div döndür
  if (!kayit) return <div></div>;

  return (
    <div className={`detay-panel ${kayit ? 'acik' : ''}`}>
      <div className="card-header">
        <h3 className="card-title mb-2">Seçilen Kayıt Detayları</h3>
        <div data-bs-theme="dark">
          <button className="btn btn-primary me-2" onClick={() => yazdirKayitDetaylari(kayit, receteImza)}>Yazdır</button>
          <button className="btn btn-success me-1" onClick={() => onEmailSend(kayit)}>E-posta Gönder</button> {/* E-posta Gönder butonu eklendi */}
          <button onClick={kapat} className="btn btn-danger" aria-label="Close">Kapat</button>
        </div>
      </div>
      <div className="card-body" style={{ maxWidth: '100%', overflowWrap: 'break-word' }}>
        <p>Hasta Takip No: {kayit.hastaTakipNo}</p>
        <p>Tarih: {formatDate(kayit.tarih)}</p>
        <p>Adı: {kayit.adi}</p>
        <p>Soyadı: {kayit.soyadi}</p>
        <p>Tc Kimlik No: {(kayit.tcKimlikNo)}</p>
        <p>Adres: {kayit.adres}</p>
        <p>Dr. Adı Soyadı: {kayit.drAdiSoyadi}</p>
        <p>Diploma No: {kayit.diplomaNo}</p>
        <p>Tescil No: {kayit.tescilNo}</p>
        <p>Tanı: {kayit.tani}</p>
        <p>İlaçlar: {kayit.ilaclar}</p>
        <p>Reçete No: {kayit.receteNo}</p>
        <h5>Reçete İmza</h5>
        {receteImza && receteImza.length > 0 ? (
          <ul>
            {receteImza.map((imza, index) => (
              <li key={index}>
                {imza.dosyaAdi} - <a href={imza.url} target="_blank" rel="noopener noreferrer">Görüntüle</a>
              </li>
            ))}
          </ul>
        ) : (
          <p>Bu kayda ait dosya bulunmamaktadır.</p>
        )}
        {/* Diğer detaylar */}
      </div>
    </div>
  );
};
const ReceteRaporu = () => {

  const [kayitlar, setKayitlar] = useState([]);
  const [filtreler, setFiltreler] = useState({
    hastaTakipNo: '',
    adi: '',
    soyadi: '',
    tcKimlikNo: '',
    adres: '',
    drAdiSoyadi: '',
    diplomaNo: '',
    tescilNo: '',
    tani: '',
    ilaclar: '',
    receteNo: '',
  });

  const [secilenKayit, setSecilenKayit] = useState(null);
  const [duzenlemeFormuGoster, setDuzenlemeFormuGoster] = useState(false);
  const [duzenlenecekKayit, setDuzenlenecekKayit] = useState({});
  const [secilenReceteImzasi, setSecilenReceteImzasi] = useState([]);
  const [baslangicTarihi, setBaslangicTarihi] = useState('');
  const [bitisTarihi, setBitisTarihi] = useState('');
  const [pharmacies, setPharmacies] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPrescription, setSelectedPrescription] = useState(null);



  useEffect(() => {
    const fetchData = async () => {
      try {
        await apiManager.get('/recete-raporu').then((data) => {
          if (data) {
            setKayitlar(data);
          } else {
            console.error('Veri yüklenemedi.');
          }
        });
      } catch (error) {
        console.error('Sunucu hatası:', error);
      }
    };

    fetchData();
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      // `duzenlenecekKayit` nesnesini ve ilgili endpoint'i kullanarak PATCH isteğini gönderin
      await apiManager.patch(`/recete/${duzenlenecekKayit.hastaTakipNo}`, duzenlenecekKayit);

      // Güncellenmiş kayıt bilgisi alındıktan sonra tüm kayıtları yeniden çekme işlemi
      await fetchAllRecords(); // Bu fonksiyonunuzu ve işlevselliğini doğru şekilde tanımladığınızdan emin olun

      // Düzenleme formunu kapatma işlemi
      setDuzenlemeFormuGoster(false);

      // Kullanıcıya başarılı güncelleme hakkında bilgi verme
      alert('Kayıt başarıyla güncellendi.');
    } catch (error) {
      console.error('Güncelleme sırasında hata oluştu:', error);
      alert('Güncelleme işlemi sırasında bir hata oluştu.');
    }
  };
  useEffect(() => {
    const fetchDosyalar = async () => {
      if (!secilenKayit) {
        setSecilenReceteImzasi([]);
        return;
      }

      try {
        // `apiManager` kullanarak `GET` isteğini yap
        const receteImza = await apiManager.get(`/recete-imzalari/${secilenKayit.hastaTakipNo}`);
        setSecilenReceteImzasi(receteImza);
      } catch (error) {
        console.error('Sunucu hatası:', error);
        setSecilenReceteImzasi([]);
      }
    };

    fetchDosyalar();
  }, [secilenKayit]);

  const fetchAllRecords = async () => {
    try {
      await apiManager.get('/recete-raporu').then((data) => {
        if (data) {
          setKayitlar(data);
        } else {
          console.error('Veri yüklenemedi.');
        }
      });
    } catch (error) {
      console.error('Sunucu hatası:', error);
    }
  }

  useEffect(() => {
    const fetchPharmacies = async () => {
      try {
        const response = await apiManager.get('/pharmacies');
        setPharmacies(response);
        console.log(response)
      } catch (error) {
        console.error('Eczaneler yüklenirken bir hata oluştu:', error);

      }
    };

    fetchPharmacies();
  }, []);

  const handleDelete = async (kayitId) => {
    if (window.confirm(`Kaydı silmek istediğinize emin misiniz? ID: ${kayitId.hastaTakipNo}`)) {
      try {
        // `apiManager` kullanarak `DELETE` isteğini yap
        await apiManager.del(`/recete/${kayitId.hastaTakipNo}`);

        console.log('Kayıt başarıyla silindi');
        await fetchAllRecords(); // Tüm kayıtları yeniden çek
      } catch (error) {
        console.error('Silme işlemi sırasında hata oluştu:', error);
      }
    }
  };



  const handleRowClick = (kayit) => {
    setSecilenKayit(kayit);
  };
  const kapatDetayPaneli = () => {
    setSecilenKayit(null);
  };
  const handleFilterChange = (e) => {
    setFiltreler({ ...filtreler, [e.target.name]: e.target.value.toLowerCase() });
  };
  const handleEdit = (kayit) => {
    // Tarih formatını düzelt
    const duzenlenmisKayit = {
      ...kayit,
      tarih: formatDateToInput(kayit.tarih)
    };

    setDuzenlenecekKayit(duzenlenmisKayit);
    setDuzenlemeFormuGoster(true);
  };
  const kapatGuncellemePaneli = () => {
    setDuzenlemeFormuGoster(false);
  };

  function formatDateToInput(dateStr) {
    if (!dateStr) return '';

    const date = new Date(dateStr);
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();

    month = month.length < 2 ? '0' + month : month;
    day = day.length < 2 ? '0' + day : day;

    return `${year}-${month}-${day}`;
  }
  const yazdirTumKayitlari = () => {
    const yeniSekme = window.open('', '_blank');
    yeniSekme.document.open();

    let htmlContent = `
        <html>
        <head>
            <title>Tüm Kayıtlar</title>
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">
            <style>
                body { font-size: 10px; }
                .table { width: 100%; table-layout: fixed; }
                .table th, .table td { word-wrap: break-word; overflow-wrap: break-word; }
                @media print {
                    body { font-size: 8px; }
                    .table { font-size: 8px; }
                }
            </style>
        </head>
        <body>
            <h2>Reçete Raporları</h2>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Hasta Takip No</th>
                        <th>Tarih</th>
                        <th>Adı</th>
                        <th>Soyadı</th>
                        <th>Tc Kimlik No</th>
                        <th>Adres</th>
                        <th>Dr. Adı Soyadı</th>
                        <th>Diploma No</th>
                        <th>Tescil No</th>
                        <th>Tanı</th>
                        <th>Reçete No</th>
                    </tr>
                </thead>
                <tbody>`;

    kayitlar.forEach(kayit => {
      htmlContent += `
                    <tr>
                        <td>${kayit.hastaTakipNo}</td>
                        <td>${formatDate(kayit.tarih)}</td>
                        <td>${kayit.adi}</td>
                        <td>${kayit.soyadi}</td>
                        <td>${(kayit.tcKimlikNo)}</td>
                        <td>${kayit.adres}</td>
                        <td>${kayit.drAdiSoyadi}</td>
                        <td>${kayit.tescilNo}</td>
                        <td>${kayit.tani}</td>
                        <td>${kayit.ilaclar}</td>
                        <td>${kayit.receteNo}</td>
                    </tr>`;
    });

    htmlContent += `
                </tbody>
            </table>
            <script>
                window.onload = function() {
                    window.print();

                };
            </script>
        </body>
        </html>`;

    yeniSekme.document.write(htmlContent);
    yeniSekme.document.close();
  };

  // const sendPrescriptionEmail = async (emailInfo) => {
  //   try {
  //     const response = await apiManager.post('/email/send-prescription', emailInfo);
  //     alert('E-posta başarıyla gönderildi.');
  //     console.log('Yanıt:', response.data);
  //   } catch (error) {
  //     console.error('Hata oluştu:', error);
  //     alert('E-posta gönderilirken bir hata oluştu.');
  //   }
  // };

  const filteredKayitlar = kayitlar.filter(kayit => {
    const kayitTarihi = new Date(kayit.tarih);
    const basTarihi = baslangicTarihi ? new Date(baslangicTarihi) : new Date('1900-01-01');
    const bitTarihi = bitisTarihi ? new Date(bitisTarihi) : new Date('2100-12-31');

    // Tarih aralığı kontrolü
    const tarihAraliginda = kayitTarihi >= basTarihi && kayitTarihi <= bitTarihi;

    // Diğer filtreler için kontrol
    const digerFiltrelerUyuyor = Object.keys(filtreler).every(key =>
      kayit[key].toString().toLowerCase().includes(filtreler[key])
    );

    return tarihAraliginda && digerFiltrelerUyuyor;
  });
  function formatPrescriptionDetails(details) {
    // Tarihi daha anlaşılır bir formata çevirme
    const date = new Date(details.tarih);
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;

    return `
  <p>Tarih: ${formattedDate}</p>
  <p>Adı: ${details.adi}</p>
  <p>Soyadı: ${details.soyadi}</p>
  <p>TC Kimlik No: ${details.tcKimlikNo}</p>
  <p>Adres: ${details.adres}</p>
  <p>Dr. Adı Soyadı: ${details.drAdiSoyadi}</p>
  <p>Diploma No: ${details.diplomaNo}</p>
  <p>Tescil No: ${details.tescilNo}</p>
  <p>Tanı: ${details.tani}</p>
  <p>İlaçlar: ${details.ilaclar}</p>
  <p>Reçete No: ${details.receteNo}</p>`;
  }
  const sendPrescriptionEmail = async (pharmacyEmail, prescriptionDetails, additionalComments) => {
    const formattedDetails = formatPrescriptionDetails(prescriptionDetails);

    try {
      await apiManager.post('/email/send-prescription', {
        email: pharmacyEmail,
        subject: 'Reçete Bilgileri',
        prescriptionDetails: formattedDetails, // Gönderilecek metin formatında reçete bilgileri
        additionalComments: additionalComments // Gönderilecek metin formatında reçete bilgileri
      });
      alert('E-posta başarıyla gönderildi.');
    } catch (error) {
      console.error('E-posta gönderilirken bir hata oluştu:', error);
    }
  };
  useEffect(() => {
    console.log(pharmacies);
  }, [pharmacies]);
  function PrescriptionModal({ isOpen, onClose, onSend, pharmacies }) {
    const [selectedPharmacyEmail, setSelectedPharmacyEmail] = useState('');
    const [additionalComments, setAdditionalComments] = useState(''); // Ek açıklamalar için state
   
    const handleCommentsChange = (e) => setAdditionalComments(e.target.value);
    // useEffect(() => {
    //   console.log(pharmacies);
    // }, [pharmacies]);
    return (
      <Modal isOpen={isOpen} onRequestClose={onClose} style={{ content: { top: '50%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)', width: 'auto', maxWidth: '500px' }, overlay: { background: "rgba(0, 0, 0, 0.75)" } }}>
        <div className="modal-content">
          <div className="modal-header text-center">
            <h5 className="modal-title w-100 mb-4">Eczane Seçimi</h5>
          </div>
          <div className="modal-body">
            <div className="d-flex justify-content-center">
              <div className="w-100">
                <form>
                  <div className="form-group mb-3">
                    <label htmlFor="pharmacySelect">Eczane Seçiniz</label>
                    <select id="pharmacySelect" className="form-control" onChange={(e) => setSelectedPharmacyEmail(e.target.value)} value={selectedPharmacyEmail}>
                      <option value="">Seçiniz...</option>
                      {pharmacies && pharmacies.length > 0 && pharmacies.map((pharmacy) => (
                        <option key={pharmacy.uyeId} value={pharmacy.email}>{pharmacy.unvani}</option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-3">
                                        <label htmlFor="additionalComments">Ek Açıklamalar</label>
                                        <textarea id="additionalComments" className="form-control" rows="3" onChange={handleCommentsChange} value={additionalComments}></textarea>
                                    </div>
                </form>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-center mt-4">
            <button type="button" className="btn btn-primary  me-2" onClick={() => onSend(selectedPharmacyEmail, additionalComments)}>Gönder</button>
            <button type="button" className="btn btn-secondary" onClick={onClose}>Kapat</button>
          </div>
        </div>
      </Modal>





    );
  }

  const handleSendEmailClick = (prescription) => {
    setSelectedPrescription(prescription);
    setIsModalOpen(true);
  };

  // Modal'dan gelen onSend işleyicisi...
  const handleModalSend = (pharmacyEmail, additionalComments) => {
    if (selectedPrescription && pharmacyEmail) {
      sendPrescriptionEmail(pharmacyEmail, selectedPrescription, additionalComments);
      setIsModalOpen(false); // Modalı kapat
    } else {
      alert('Lütfen bir eczane seçiniz.');
    }
  };

  const handleEmailSend = (kayit) => {
    // E-posta gönderme işlemi için gerekli kodlar
    // Örneğin:
    setSelectedPrescription(kayit);
    setIsModalOpen(true);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2 no-padding-left">
          <LeftSidebar />
        </div>
        <div className="col-md-10">
          <h2 style={{ backgroundColor: '#2c3e50', color: 'white', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', fontSize: '1.6rem' }}>Reçete Raporu</h2>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px', padding: '5px 10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9' }}>
            <p style={{ margin: 0 }}>Kayıt Detayları, Yazdırma ve E-Posta Göndermek İçin İlgili Kaydın Üzerine Tıklayınız.</p>
            <button type="button" onClick={yazdirTumKayitlari} className="btn btn-primary">Tüm Kayıtları Yazdır</button>
          </div>
          {/* <button onClick={() => sendPrescriptionEmail({
  to: 'alici@example.com',
  subject: 'Reçete Bilgileriniz',
  prescriptionDetails: 'Buraya seçilen reçete detayları eklenecek'
})}>Reçete Gönder</button> */}
          <div className="filter-section" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <div style={{ marginRight: '20px' }}>
              <label htmlFor="baslangicTarihi">Başlangıç Tarihi:</label>
              <input
                type="date"
                id="baslangicTarihi"
                value={baslangicTarihi}
                onChange={(e) => setBaslangicTarihi(e.target.value)}
                className="form-control"
              />
            </div>
            <div>
              <label htmlFor="bitisTarihi">Bitiş Tarihi:</label>
              <input
                type="date"
                id="bitisTarihi"
                value={bitisTarihi}
                onChange={(e) => setBitisTarihi(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
          {duzenlemeFormuGoster && (
            <div className="update-panel card">
              <div className="card-header">
                <h3>Kayıt Güncelle</h3>
              </div>
              <div className="card-body">
                <form onSubmit={handleUpdate} className="row g-3">
                  <div className="col-md-6">
                    <label htmlFor="adi" className="form-label">Adı</label>
                    <input type="text" className="form-control" id="adi" value={duzenlenecekKayit.adi} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, adi: e.target.value })} />

                    <label htmlFor="soyadi" className="form-label">Soyadı</label>
                    <input type="text" className="form-control" id="soyadi" value={duzenlenecekKayit.soyadi} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, soyadi: e.target.value })} />

                    <label htmlFor="tarih" className="form-label">Tarih</label>
                    <input type="date" className="form-control" id="tarih" value={duzenlenecekKayit.tarih} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, tarih: e.target.value })} />

                    <label htmlFor="tcKimlikNo" className="form-label">TC Kimlik No</label>
                    <input type="text" className="form-control" id="tcKimlikNo" value={duzenlenecekKayit.tcKimlikNo} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, tcKimlikNo: e.target.value })} />
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="adres" className="form-label">Adres</label>
                    <input type="text" className="form-control" id="adres" value={duzenlenecekKayit.adres} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, adres: e.target.value })} />

                    <label htmlFor="drAdiSoyadi" className="form-label">Dr. Adı Soyadı</label>
                    <input type="text" className="form-control" id="drAdiSoyadi" value={duzenlenecekKayit.drAdiSoyadi} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, drAdiSoyadi: e.target.value })} />

                    <label htmlFor="diplomaNo" className="form-label">Diploma No</label>
                    <input type="text" className="form-control" id="diplomaNo" value={duzenlenecekKayit.diplomaNo} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, diplomaNo: e.target.value })} />

                    <label htmlFor="tescilNo" className="form-label">Tescil No</label>
                    <input type="text" className="form-control" id="tescilNo" value={duzenlenecekKayit.tescilNo} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, tescilNo: e.target.value })} />
                  </div>

                  <div className="col-12">
                    <label htmlFor="tani" className="form-label">Tanı</label>
                    <textarea className="form-control" id="tani" value={duzenlenecekKayit.tani} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, tani: e.target.value })}></textarea>

                    <label htmlFor="ilaclar" className="form-label">İlaçlar</label>
                    <textarea className="form-control" id="ilaclar" value={duzenlenecekKayit.ilaclar} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, ilaclar: e.target.value })}></textarea>

                    <label htmlFor="receteNo" className="form-label">Reçete No</label>
                    <input type="text" className="form-control" id="receteNo" value={duzenlenecekKayit.receteNo} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, receteNo: e.target.value })} />
                  </div>

                  <div className="col-12">
                    <button type="submit" className="btn btn-primary me-2">Güncelle</button>
                    <button type="button" onClick={kapatGuncellemePaneli} className="btn btn-danger">Kapat</button>
                  </div>
                </form>

              </div>
            </div>
          )}

          <DetayPaneli kayit={secilenKayit} kapat={kapatDetayPaneli} receteImza={secilenReceteImzasi} onEmailSend={handleEmailSend} />

          <div className="table-responsive" style={{ maxHeight: '75vh', overflowX: 'auto' }}>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>İşlemler</th>
                  <th>Hasta Takip No<input type="text" name="hastaTakipNo" onChange={handleFilterChange} className="form-control" /></th>
                  <th>Tarih</th>
                  <th>Adı<input type="text" name="adi" onChange={handleFilterChange} className="form-control" /></th>
                  <th>Soyadı<input type="text" name="soyadi" onChange={handleFilterChange} className="form-control" /></th>
                  <th>TC Kimlik No<input type="text" name="tcKimlikNo" onChange={handleFilterChange} className="form-control" /></th>
                  <th>Adres<input type="text" name="adres" onChange={handleFilterChange} className="form-control" /></th>
                  <th>Dr. Adı Soyadı<input type="text" name="drAdiSoyadi" onChange={handleFilterChange} className="form-control" /></th>
                  <th>Diploma No<input type="text" name="diplomaNo" onChange={handleFilterChange} className="form-control" /></th>
                  <th>Tescil No<input type="text" name="tescilNo" onChange={handleFilterChange} className="form-control" /></th>
                  <th>Tanı<input type="text" name="tani" onChange={handleFilterChange} className="form-control" /></th>
                  <th>İlaçlar<input type="text" name="ilaclar" onChange={handleFilterChange} className="form-control" /></th>
                  <th>Reçete No<input type="text" name="receteNo" onChange={handleFilterChange} className="form-control" /></th>
                  <th>Reçete İmza</th>
                </tr>
              </thead>

              <tbody>
                {filteredKayitlar.map((kayit, index) => (
                  <tr key={index} onClick={() => handleRowClick(kayit)} className="clickable-row">
                    <td>
                      <button onClick={(e) => { e.stopPropagation(); handleEdit(kayit); }} className="btn btn-primary btn-sm me-1">
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button onClick={(e) => { e.stopPropagation(); handleDelete(kayit); }} className="btn btn-danger btn-sm">
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </td>
                    <td>{kayit.hastaTakipNo}</td>
                    <td>{formatDate(kayit.tarih)}</td>
                    <td>{kayit.adi ? (kayit.adi.length > 10 ? kayit.adi.substring(0, 10) + '...' : kayit.adi) : ''}</td>
                    <td>{kayit.soyadi ? (kayit.soyadi.length > 10 ? kayit.soyadi.substring(0, 10) + '...' : kayit.soyadi) : ''}</td>
                    <td>{kayit.tcKimlikNo}</td>
                    <td>{kayit.adres ? (kayit.adres.length > 10 ? kayit.adres.substring(0, 10) + '...' : kayit.adres) : ''}</td>
                    <td>{kayit.drAdiSoyadi ? (kayit.drAdiSoyadi.length > 10 ? kayit.drAdiSoyadi.substring(0, 10) + '...' : kayit.drAdiSoyadi) : ''}</td>
                    <td>{kayit.diplomaNo ? (kayit.diplomaNo.length > 10 ? kayit.diplomaNo.substring(0, 10) + '...' : kayit.diplomaNo) : ''}</td>
                    <td>{kayit.tescilNo ? (kayit.tescilNo.length > 10 ? kayit.tescilNo.substring(0, 10) + '...' : kayit.tescilNo) : ''}</td>
                    <td>{kayit.tani ? (kayit.tani.length > 10 ? kayit.tani.substring(0, 10) + '...' : kayit.tani) : ''}</td>
                    <td>{kayit.ilaclar ? (kayit.ilaclar.length > 10 ? kayit.ilaclar.substring(0, 10) + '...' : kayit.ilaclar) : ''}</td>
                    <td>{kayit.receteNo ? (kayit.receteNo.length > 10 ? kayit.receteNo.substring(0, 10) + '...' : kayit.receteNo) : ''}</td>
                    <td>
                      {kayit.dosyaSayisi > 0 ?
                        `${kayit.dosyaSayisi} Dosya` :
                        'Dosya Yok'
                      }
                    </td>
                  </tr>
                ))}
              </tbody>

            </table>
            <PrescriptionModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              onSend={handleModalSend}
              pharmacies={pharmacies} // Bu satır çok önemli
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReceteRaporu;
