// AuthContext.js

import React, { createContext, useState ,useEffect, useContext} from 'react';


export const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);
export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true); // Yükleme durumu için yeni state

    useEffect(() => {
        const verifyToken = async () => {
            setLoading(true); // Yükleme durumunu etkinleştir
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await fetch('/verifyToken', {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
    
                    if (response.ok) {
                        setIsAuthenticated(true);
                    } else {
                        // Token geçersizse veya yanıt OK değilse
                        setIsAuthenticated(false);
                        localStorage.removeItem('token'); // Token'ı silerek oturumu sonlandır
                    }
                } catch (error) {
                    console.error('Token doğrulama sırasında hata oluştu:', error);
                    setIsAuthenticated(false);
                    localStorage.removeItem('token'); // Hata durumunda token'ı sil
                }
            }
            setLoading(false); // Yükleme durumunu devre dışı bırak
        };
    
        verifyToken();
    }, []);
    

    const login = () => {
        setIsAuthenticated(true);
        localStorage.setItem("isAuthenticated", "true");
    };
    
    const logout = () => {
        setIsAuthenticated(false); // Kullanıcı durumunu güncelle
        localStorage.removeItem('token'); // localStorage'dan token'ı kaldır
        // Gerekirse, burada diğer kullanıcı bilgilerini de temizleyebilirsiniz.
      };
    // Yükleme durumunu context değerlerine ekle
    return (
        <AuthContext.Provider value={{ isAuthenticated, loading, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};


