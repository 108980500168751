// LoginPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import './css/LoginPage.css';
import apiManager from '../service/apiManager';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        // `apiManager` kullanarak `POST` isteğini yap
        const data = await apiManager.post('/login', { email, password });

        localStorage.setItem('token', data.token); // Token'ı localStorage'a kaydet
        login(); // Kullanıcının kimlik doğrulama durumunu güncelle
        navigate('/panel'); // Admin paneline yönlendir
    } catch (error) {
        console.error('Giriş işlemi sırasında bir hata oluştu:', error.message);
        alert('Hatalı E-posta veya Hatalı Şifre'); // Kullanıcıya hata mesajını göster
    }
};
  
  return (
    <div className="login-background">
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card shadow">
              <div className="card-body">
                <h2 className="card-title text-center mb-4">Prof. Dr. Burhan Kabay</h2>
                <h3 className="text-center">Yönetim Paneli Giriş</h3>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email:</label>
                    <input type="email" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">Şifre:</label>
                    <input type="password" className="form-control" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                  </div>
                  <div className="d-grid gap-2">
                    <button type="submit" className="btn btn-primary">Giriş Yap</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
