import { useEffect, useState } from 'react';
import LeftSidebar from '../components/LeftSidebar';
import apiManager from '../service/apiManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faPlus, faKey } from '@fortawesome/free-solid-svg-icons';
import './css/KullaniciYonetimi.css'


const KullaniciYonetimi = () => {
  const [kayitlar, setKayitlar] = useState([]);
  const [secilenKayit, setSecilenKayit] = useState(null);
  const [duzenlemeFormuGoster, setDuzenlemeFormuGoster] = useState(false);
  const [duzenlenecekKayit, setDuzenlenecekKayit] = useState({});
  const [kullaniciEklePaneliGoster, setKullaniciEklePaneliGoster] = useState(false);
  const [yeniKullanici, setYeniKullanici] = useState({ email: '', password: '' });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await apiManager.get('/users').then((data) => {
          if (data) {
            setKayitlar(data);
          } else {
            console.error('Veri yüklenemedi.');
          }
        });
      } catch (error) {
        console.error('Sunucu hatası:', error);
      }
    };

    fetchData();
  }, []);
const handleUpdate = async (e) => {
  e.preventDefault();

  const updateInfo = {
    email: duzenlenecekKayit.email,
    currentPassword: duzenlenecekKayit.currentPassword,
    newPassword: duzenlenecekKayit.newPassword,
  };

  try {
    // apiManager üzerinden PATCH isteği gönder
    await apiManager.patch(`/users/${duzenlenecekKayit.id}`, updateInfo);

    // Güncellenmiş kayıt bilgisi alındıktan sonra tüm kayıtları yeniden çekmek için örnek bir fonksiyon (fetchAllRecords) çağırılabilir.
    // Örneğin:
    await fetchAllRecords();

    // Düzenleme formunu ve seçilen dosyaları sıfırla
    setDuzenlemeFormuGoster(false);


    alert('Şifre başarıyla güncellendi.');
  } catch (error) {
      console.error('Güncelleme sırasında hata oluştu:', error);
      // Hatanın HTTP durum kodunu kontrol et
      if (error.response && error.response.status === 401) {
        alert('Mevcut şifre yanlış.'); // Bu satır mevcut şifrenin yanlış olduğunu belirten özel durum
      } else {
        alert('Güncelleme işlemi sırasında bir hata oluştu. Mevcut Şifrenizi Kontrol Ediniz.'); // Genel hata mesajı
      }
  }
};






  const fetchAllRecords = async () => {
    try {
      await apiManager.get('/users').then((data) => {
        if (data) {
          setKayitlar(data);
        } else {
          console.error('Veri yüklenemedi.');
        }
      });
    } catch (error) {
      console.error('Sunucu hatası:', error);
    }
  }




  const handleDelete = async (id) => {
    if (window.confirm(`Kullanıcıyı silmek istediğinize emin misiniz? ID: ${id}`)) {
        try {
            // İlk olarak veritabanındaki toplam kullanıcı sayısını kontrol et
            const response = await apiManager.get('/users/count');
            const userCount = response.kullaniciSayisi; // API'den dönen yanıtı doğru bir şekilde işle

            if (userCount <= 1) {
                // Eğer veritabanında kalan son kayıtsa, silme işlemini engelle ve kullanıcıya bilgi ver
                alert('Bu, veritabanındaki son kayıttır ve silinemez.');
                return; // Fonksiyonu burada sonlandır
            }

            // Veritabanında birden fazla kayıt varsa, silme işlemine devam et
            await apiManager.del(`/users/${id}`);
            console.log('Kayıt başarıyla silindi');
            await fetchAllRecords(); // Tüm kayıtları yeniden çek
        } catch (error) {
            console.error('Silme işlemi sırasında hata oluştu:', error);
            alert('Silme işlemi sırasında bir hata oluştu.');
        }
    }
};






  const handleRowClick = (kayit) => {
    setSecilenKayit(kayit);
  };

  const handleEdit = (kayit) => {
    setDuzenlenecekKayit(kayit); // Düzenlenecek kaydı ayarla
    setDuzenlemeFormuGoster(true); // Güncelleme formunu göster
  };
  const kapatGuncellemePaneli = () => {
    setDuzenlemeFormuGoster(false);
  };

  const handleSifreDegistir = async (kullaniciId, yeniSifre) => {
    // Şifreyi değiştirmek için API'ye istek gönder
  };

  const handleKullaniciSil = async (kullaniciId) => {
    // Kullanıcıyı silmek için API'ye istek gönder
  };
  const handleKullaniciEkle = async (e) => {
    e.preventDefault();
    try {
      // API yöneticisi üzerinden POST isteğini yap
      await apiManager.post('/users', yeniKullanici).then(data=>{
        if(data){
          console.log('Kullanıcı kaydı başarılı.');
          alert('Yeni kullanıcı oluşturuldu.');
                window.location.reload();
       }else{
        alert('Hata Oluştu.')
        console.error('Hata oluştu.')
       }
     })
  } catch (error) {
      console.error('Sunucu hatası:', error);
      // Hata yönetimi
  }
  };
  
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2 no-padding-left">
          <LeftSidebar />
        </div>
        <div className="col-md-10">
          <h2 style={{ backgroundColor: '#2c3e50', color: 'white', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', fontSize: '1.6rem' }}>Kullanıcı Yönetimi</h2>
          {/* Kullanıcı Ekleme Butonu */}
          <button className="btn btn-success mb-2" onClick={() => setKullaniciEklePaneliGoster(true)}>
                Kullanıcı Ekle
            </button>
            {/* Kullanıcı Ekleme Paneli */}
{ kullaniciEklePaneliGoster && (
  <div className="add-user-panel card my-4">
    <div className="card-header">
      <h3>Kullanıcı Ekle</h3>
    </div>
    <div className="card-body">
      <form onSubmit={handleKullaniciEkle} className="row g-3">
        <div className="col-md-6">
          <label htmlFor="userEmail" className="form-label">E-Posta:</label>
          <input
            type="email"
            className="form-control"
            id="userEmail"
            required
            value={yeniKullanici.email}
            onChange={(e) => setYeniKullanici({ ...yeniKullanici, email: e.target.value })}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="userPassword" className="form-label">Şifre:</label>
          <input
            type="password"
            className="form-control"
            id="userPassword"
            required
            value={yeniKullanici.password}
            onChange={(e) => setYeniKullanici({ ...yeniKullanici, password: e.target.value })}
          />
        </div>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <button type="submit" className="btn btn-primary me-md-2">Ekle</button>
          <button type="button" onClick={() => setKullaniciEklePaneliGoster(false)} className="btn btn-secondary">İptal</button>
        </div>
      </form>
    </div>
  </div>
)}

          {duzenlemeFormuGoster && (
            <div className="update-panel card">
              <div className="card-header">
                <h3>Kayıt Güncelle</h3>
              </div>
              <div className="card-body">
                <form onSubmit={handleUpdate} className="row g-3">
                  <div className="col-md-6">
                    <label htmlFor="email" className="form-label">E-Posta Adresi</label>
                    <input type="email" className="form-control" id="email" value={duzenlenecekKayit.email} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, email: e.target.value })} disabled />

                    <label htmlFor="currentPassword" className="form-label">Mevcut Şifre</label>
                    <input type="password" className="form-control" id="currentPassword" placeholder="Mevcut Şifrenizi Girin" onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, currentPassword: e.target.value })} />

                    <label htmlFor="newPassword" className="form-label">Yeni Şifre</label>
                    <input type="password" className="form-control" id="newPassword" placeholder="Yeni Şifrenizi Girin" onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, newPassword: e.target.value })} />
                  </div>

                  {/* Diğer form elemanları burada yer alabilir. */}

                  <div className="col-12">
                    <button type="submit" className="btn btn-primary me-2">Güncelle</button>
                    <button type="button" onClick={kapatGuncellemePaneli} className="btn btn-danger">Kapat</button>
                  </div>
                </form>
              </div>
            </div>
          )}

          <div className="table-responsive" style={{ maxHeight: '75vh', overflowX: 'auto' }}>
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                <th class="col-islemler">İşlemler</th>
    <th class="col-id">Kullanıcı ID</th>
    <th class="col-email">E-Posta</th>
                </tr>
              </thead>
              <tbody>
                {kayitlar.map((kayit, index) => (
                  <tr key={index}>
                    <td>
                      <button onClick={() => handleEdit(kayit)} className="btn btn-primary btn-sm me-1">
                        <FontAwesomeIcon icon={faKey} />
                      </button>
                      <button onClick={() => handleDelete(kayit.id)} className="btn btn-danger btn-sm">
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </td>
                    <td>{kayit.id}</td>
                    <td>{kayit.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  );
}

export default KullaniciYonetimi;
