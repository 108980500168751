import React, { useState, useEffect } from 'react';
import LeftSidebar from '../components/LeftSidebar';
import './css/HastaKayitRaporu.css';
import apiManager from '../service/apiManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal'

Modal.setAppElement('#root');


function formatDate(dateStr) {
    const date = new Date(dateStr);
    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();
    const year = date.getFullYear();

    day = day.length < 2 ? '0' + day : day;
    month = month.length < 2 ? '0' + month : month;

    return `${day}/${month}/${year}`;
}

const DetayPaneli = ({ kayit, kapat, dosyalar, onEmailSend }) => {
    // Yazdırma işlemi için fonksiyon
    const yazdirKayitDetaylari = () => {
        const yeniSekme = window.open('', '_blank');
        yeniSekme.document.open();

        let htmlContent = `
            <html>
                <head>
                <title>Seçilen Kayıt Detayı</title>
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">
                <style>
                    body { padding: 20px; }
                    .table-responsive {
                        display: block;
                        width: 100%;
                        overflow-x: auto;
                        -webkit-overflow-scrolling: touch;
                    }
                    .table {
                        width: 100% !important; /* Genişliği %100 olarak ayarla */
                        margin-bottom: 20px;
                        table-layout: fixed; /* Tablonun layoutunu sabitle */
                    }
                    .table th, .table td {
                        word-wrap: break-word; /* Uzun metinleri kır */
                        overflow-wrap: break-word; /* İçerik taşarsa alt satıra geç */
                    }
                    .dosyalar-section { margin-top: 20px; }
                </style>
            </head>
            <body>
                    <h2 class="mb-3">Seçilen Kayıt Detayları</h2>
                    <table class="table table-bordered">
                        <tbody>
                            <tr><th>Hasta Takip No</th><td>${kayit.hastaTakipNo}</td></tr>
                            <tr><th>Tarih</th><td>${formatDate(kayit.tarih)}</td></tr>
                            <tr><th>Adı</th><td>${kayit.adi}</td></tr>
                            <tr><th>Soyadı</th><td>${kayit.soyadi}</td></tr>
                            <tr><th>Doğum Tarihi</th><td>${formatDate(kayit.dogumTarihi)}</td></tr>
                            <tr><th>Cinsiyet</th><td>${kayit.cinsiyet}</td></tr>
                            <tr><th>E-nabız</th><td>${kayit.eNabiz}</td></tr>
                            <tr><th>TC Kimlik No</th><td>${kayit.tcKimlikNo}</td></tr>
                            <tr><th>Açıklama</th><td>${kayit.aciklama}</td></tr>
                            <tr><th>Fizik Muayene</th><td>${kayit.fizikMuayene}</td></tr>
                            <tr><th>Görüntüleme</th><td>${kayit.goruntulemeLab}</td></tr>
                            <tr><th>Sonuç / Karar</th><td>${kayit.sonucKarar}</td></tr>
                        </tbody>
                    </table>
                    <div class="dosyalar-section">
                        <h4>Dosyalar</h4>
                        ${dosyalarVeLinkleriOlustur(dosyalar)}
                    </div>
                    <script>
                        window.onload = function() {
                            window.print();
                        };
                    </script>
                </body>
            </html>
        `;

        yeniSekme.document.write(htmlContent);
        yeniSekme.document.close();
    };

    // Dosyaları ve linklerini oluşturan yardımcı fonksiyon
    const dosyalarVeLinkleriOlustur = (dosyalar) => {
        if (!dosyalar || dosyalar.length === 0) return '<p>Bu kayda ait dosya bulunmamaktadır.</p>';

        let dosyaListesiHTML = '<ul class="list-group">';
        dosyalar.forEach(dosya => {
            dosyaListesiHTML += `<li class="list-group-item">${dosya.dosyaAdi} - <a href="${dosya.url}" target="_blank">Görüntüle</a></li>`;
        });
        dosyaListesiHTML += '</ul>';
        return dosyaListesiHTML;
    };
    if (!kayit) return <div></div>;

    return (
        <div className={`detay-panel ${kayit ? 'acik' : ''}`}>
            <div className="card-header">
                <h3 className="card-title mb-2">Seçilen Kayıt Detayları</h3>
                <div data-bs-theme="dark">
                    <button className="btn btn-primary me-2" onClick={() => yazdirKayitDetaylari(kayit, dosyalar)}>Yazdır</button>
                    <button className="btn btn-success me-2" onClick={() => onEmailSend(kayit)}>E-posta Gönder</button>
                    <button onClick={kapat} class="btn btn-danger" aria-label="Close">Kapat</button>

                </div>
            </div>
            {/* Card body ve içerikleri */}
            <div className="card-body" style={{ maxWidth: '100%', overflowWrap: 'break-word' }}>
                <p>Hasta Takip No: {kayit.hastaTakipNo}</p>
                <p>Tarih: {formatDate(kayit.tarih)}</p>
                <p>Adı: {kayit.adi}</p>
                <p>Soyadı: {kayit.soyadi}</p>
                <p>Doğum Tarihi: {formatDate(kayit.dogumTarihi)}</p>
                <p>Cinsiyet: {kayit.cinsiyet}</p>
                <p>E-nabız: {kayit.eNabiz}</p>
                <p>TC Kimlik No: {kayit.tcKimlikNo}</p>
                <p>Açıklama: {kayit.aciklama}</p>
                <p>Fizik Muayene: {kayit.fizikMuayene}</p>
                <p>Görüntüleme: {kayit.goruntulemeLab}</p>
                <p>Sonuç / Karar: {kayit.sonucKarar}</p>
                <h5>Dosyalar</h5>
                {dosyalar && dosyalar.length > 0 ? (
                    <ul>
                        {dosyalar.map((dosya, index) => (
                            <li key={index}>
                                {dosya.dosyaAdi} - <a href={dosya.url} target="_blank" rel="noopener noreferrer">Görüntüle</a>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>Bu kayda ait dosya bulunmamaktadır.</p>
                )}
                {/* Diğer detaylar */}
            </div>
        </div>
    );
};




const HastaKayitRaporu = () => {
    const [kayitlar, setKayitlar] = useState([]);
    const [filtreler, setFiltreler] = useState({
        hastaTakipNo: '',
        adi: '',
        soyadi: '',
        cinsiyet: '',
        enabiz: '',
        tcKimlikNo: '',
        aciklama: '',
        fizikMuayene: '',
        goruntulemeLab: '',
        sonucKarar: ''
    });
    
    const [secilenKayit, setSecilenKayit] = useState(null);
    const [duzenlemeFormuGoster, setDuzenlemeFormuGoster] = useState(false);
    const [duzenlenecekKayit, setDuzenlenecekKayit] = useState({});
    const [secilenKayitDosyalari, setSecilenKayitDosyalari] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [baslangicTarihi, setBaslangicTarihi] = useState('');
    const [bitisTarihi, setBitisTarihi] = useState('');
    const [doctors, setDoctors] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPatientRegistration, setSelectedPatientRegistration] = useState(null);


    const handleFileChange = (e) => {
        if (e.target.files) {
            const filesArray = Array.from(e.target.files);
            setSelectedFiles(prevFiles => [...prevFiles, ...filesArray]);
        }
    };



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiManager.get('/hasta-kayit-raporu');
                console.log(response); // API yanıtını konsola loglayın
                if (Array.isArray(response)) {
                    setKayitlar(response);
                } else {
                    console.error('Beklenen dizi formatında veri gelmedi.');
                    setKayitlar([]); // Varsayılan olarak boş bir dizi ayarlayın
                }
            } catch (error) {
                console.error('Sunucu hatası:', error);
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        const fetchDosyalar = async () => {
            if (!secilenKayit) {
                setSecilenKayitDosyalari([]);
                return;
            }

            try {
                // `apiManager` kullanarak `GET` isteğini yap
                const dosyalar = await apiManager.get(`/hasta-dosyalari/${secilenKayit.hastaTakipNo}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}` // Eğer API token gerektiriyorsa
                    }
                });
                setSecilenKayitDosyalari(dosyalar);
            } catch (error) {
                console.error('Sunucu hatası:', error);
                setSecilenKayitDosyalari([]);
            }
        };

        fetchDosyalar();
    }, [secilenKayit]);

    useEffect(() => {
        const fetchDoctors = async () => {
            try {
                const response = await apiManager.get('/doctors');
                setDoctors(response);
                console.log(response)
            } catch (error) {
                console.error('Doktorlar yüklenirken bir hata oluştu:', error);

            }
        };

        fetchDoctors();
    }, []);

    const handleUpdate = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        // Düzenlenecek kayıttaki diğer alanları formData'ya ekleyin
        formData.append('adi', duzenlenecekKayit.adi);
        formData.append('soyadi', duzenlenecekKayit.soyadi);
        formData.append('dogumTarihi', duzenlenecekKayit.dogumTarihi);
        formData.append('eNabiz', duzenlenecekKayit.eNabiz);
        formData.append('aciklama', duzenlenecekKayit.aciklama);
        formData.append('tarih', duzenlenecekKayit.tarih);
        formData.append('cinsiyet', duzenlenecekKayit.cinsiyet);
        formData.append('tcKimlikNo', duzenlenecekKayit.tcKimlikNo);
        formData.append('fizikMuayene', duzenlenecekKayit.fizikMuayene);
        formData.append('goruntulemeLab', duzenlenecekKayit.goruntulemeLab);
        formData.append('sonucKarar', duzenlenecekKayit.sonucKarar);
        // Diğer form alanlarını buraya ekleyin...

        // Seçilen dosyaları formData'ya ekleyin
        for (let file of selectedFiles) {
            formData.append('dosyalar', file);
        }

        try {
            // apiManager üzerinden PATCH isteği gönder
            await apiManager.patch(`/hasta-kayit/${duzenlenecekKayit.hastaTakipNo}`, formData);

            // Güncellenmiş kayıt bilgisi alındıktan sonra tüm kayıtları yeniden çekmek için örnek bir fonksiyon (fetchAllRecords) çağırılabilir.
            // Örneğin:
            await fetchAllRecords();

            // Düzenleme formunu ve seçilen dosyaları sıfırla
            setDuzenlemeFormuGoster(false);
            setSelectedFiles([]);

            alert('Kayıt ve dosyalar başarıyla güncellendi.');
        } catch (error) {
            console.error('Güncelleme sırasında hata oluştu:', error);
            alert('Güncelleme işlemi sırasında bir hata oluştu.');
        }
    };


    const fetchAllRecords = async () => {
        try {
            await apiManager.get('/hasta-kayit-raporu').then((data) => {
                if (data) {
                    setKayitlar(data);
                } else {
                    console.error('Veri yüklenemedi.');
                }
            });
        } catch (error) {
            console.error('Sunucu hatası:', error);
        }
    }





    const handleDelete = async (kayitId) => {
        if (window.confirm(`Kaydı silmek istediğinize emin misiniz? ID: ${kayitId.hastaTakipNo}`)) {
            try {
                // `apiManager` kullanarak `DELETE` isteğini yap
                await apiManager.del(`/hasta-kayit/${kayitId.hastaTakipNo}`);

                console.log('Kayıt başarıyla silindi');
                await fetchAllRecords(); // Tüm kayıtları yeniden çek
            } catch (error) {
                console.error('Silme işlemi sırasında hata oluştu:', error);
            }
        }
    };



    const handleRowClick = (kayit) => {
        setSecilenKayit(kayit);
    };
    const kapatDetayPaneli = () => {
        setSecilenKayit(null);
        setSecilenKayitDosyalari([]);
    };
    const handleFilterChange = (e) => {
        setFiltreler({ ...filtreler, [e.target.name]: e.target.value.toLowerCase() });
    };
    const handleEdit = (kayit) => {
        // Tarih formatını düzelt
        const duzenlenmisKayit = {
            ...kayit,
            dogumTarihi: formatDateToInput(kayit.dogumTarihi),
            tarih: formatDateToInput(kayit.tarih)
        };

        setDuzenlenecekKayit(duzenlenmisKayit);
        setDuzenlemeFormuGoster(true);
    };
    const kapatGuncellemePaneli = () => {
        setDuzenlemeFormuGoster(false);
    };

    function formatDateToInput(dateStr) {
        if (!dateStr) return '';

        const date = new Date(dateStr);
        const year = date.getFullYear();
        let month = (date.getMonth() + 1).toString();
        let day = date.getDate().toString();

        month = month.length < 2 ? '0' + month : month;
        day = day.length < 2 ? '0' + day : day;

        return `${year}-${month}-${day}`;
    }




    const filteredKayitlar = kayitlar.filter(kayit => {
        const kayitTarihi = new Date(kayit.tarih);
    
        // Başlangıç tarihini ayarla
        let basTarihi = baslangicTarihi ? new Date(baslangicTarihi) : new Date('1900-01-01');
        basTarihi.setHours(0, 0, 0, 0); // Günün başlangıcı
    
        // Bitiş tarihini ayarla
        let bitTarihi = bitisTarihi ? new Date(bitisTarihi) : new Date('2100-12-31');
        bitTarihi.setHours(23, 59, 59, 999); // Günün sonu
    
        // Tarih aralığı kontrolü
        const tarihAraliginda = kayitTarihi >= basTarihi && kayitTarihi <= bitTarihi;
    
        // Diğer filtreler için kontrol
        const digerFiltrelerUyuyor = Object.keys(filtreler).every(key => {
            // Belirli alanlar için ek kontrol
            if (['eNabiz', 'fizikMuayene', 'goruntulemeLab', 'sonucKarar', 'aciklama'].includes(key)) {
                // Filtre değeri varsa ve kayıt değeri boş ise false dön
                if (filtreler[key] && (!kayit[key] || kayit[key].toString().toLowerCase().trim() === '')) return false;
            }
            
            // Kayıtta ilgili alan boş ise, bu filtreyi geç
            if (!kayit[key]) return true;
            
            return kayit[key].toString().toLowerCase().includes(filtreler[key].toLowerCase());
        });
    
        return tarihAraliginda && digerFiltrelerUyuyor;
    });
    
    
    // const filteredAndSortedKayitlar = React.useMemo(() => {
    //     return kayitlar
    //         .filter(kayit => {
    //             const kayitTarihi = new Date(kayit.tarih);
    //             const basTarihi = baslangicTarihi ? new Date(baslangicTarihi) : new Date('1900-01-01');
    //             const bitTarihi = bitisTarihi ? new Date(bitisTarihi) : new Date('2100-12-31');

    //             // Tarih aralığı kontrolü
    //             const tarihAraliginda = kayitTarihi >= basTarihi && kayitTarihi <= bitTarihi;

    //             // Diğer filtreler için kontrol
    //             const digerFiltrelerUyuyor = Object.keys(filtreler).every(key => 
    //                 kayit[key].toString().toLowerCase().includes(filtreler[key])
    //             );

    //             return tarihAraliginda && digerFiltrelerUyuyor;
    //         })
    //         .sort((a, b) => new Date(a.tarih) - new Date(b.tarih)); // Tarih sırasına göre sıralama
    // }, [kayitlar, baslangicTarihi, bitisTarihi, filtreler]);


    const handleFileRemove = (indexToRemove) => {
        setSelectedFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
    };
    const yazdirTumKayitlari = () => {
        const yeniSekme = window.open('', '_blank');
        yeniSekme.document.open();

        let htmlContent = `
            <html>
            <head>
                <title>Tüm Kayıtlar</title>
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">
                <style>
                    body { font-size: 10px; }
                    .table { width: 100%; table-layout: fixed; }
                    .table th, .table td { word-wrap: break-word; overflow-wrap: break-word; }
                    @media print {
                        body { font-size: 8px; }
                        .table { font-size: 8px; }
                    }
                </style>
            </head>
            <body>
                <h2>Hasta Kayıt Raporları</h2>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Hasta Takip No</th>
                            <th>Tarih</th>
                            <th>Adı</th>
                            <th>Soyadı</th>
                            <th>Doğum Tarihi</th>
                            <th>Cinsiyet</th>
                            <th>E-nabız</th>
                            <th>TC Kimlik No</th>
                            <th>Açıklama</th>
                            <th>Fizik Muayene</th>
                            <th>Görüntüleme</th>
                            <th>Sonuç / Karar</th>
                        </tr>
                    </thead>
                    <tbody>`;

        kayitlar.forEach(kayit => {
            htmlContent += `
                        <tr>
                            <td>${kayit.hastaTakipNo}</td>
                            <td>${formatDate(kayit.tarih)}</td>
                            <td>${kayit.adi}</td>
                            <td>${kayit.soyadi}</td>
                            <td>${formatDate(kayit.dogumTarihi)}</td>
                            <td>${kayit.cinsiyet}</td>
                            <td>${kayit.eNabiz}</td>
                            <td>${kayit.tcKimlikNo}</td>
                            <td>${kayit.aciklama}</td>
                            <td>${kayit.fizikMuayene}</td>
                            <td>${kayit.goruntulemeLab}</td>
                            <td>${kayit.sonucKarar}</td>
                        </tr>`;
        });

        htmlContent += `
                    </tbody>
                </table>
                <script>
                    window.onload = function() {
                        window.print();
                    };
                </script>
            </body>
            </html>`;

        yeniSekme.document.write(htmlContent);
        yeniSekme.document.close();
    };

    function formatPatientRegistrationDetails(details) {
        // Tarihi daha anlaşılır bir formata çevirme
        const date = new Date(details.tarih);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;

        return `
        <p>Adı: ${details.adi}</p>
        <p>Soyadı: ${details.soyadi}</p>
        <p>Tarih: ${formatDate(details.tarih)}</p>
        <p>Doğum Tarihi: ${formatDate(details.dogumTarihi)}</p>
        <p>E-nabız: ${details.eNabiz}</p>
        <p>Cinsiyet: ${details.cinsiyet}</p>
        <p>TC Kimlik No: ${details.tcKimlikNo}</p>
        <p>Açıklama: ${details.aciklama}</p>
        <p>Fizik Muayene: ${details.fizikMuayene}</p>
        <p>Görüntüleme: ${details.goruntulemeLab}</p>    
        <p>Sonuç / Karar: ${details.sonucKarar}</p>`;
    }
    const sendPatientRegistrationEmail = async (doctorEmail, PatientRegistrationDetails, additionalComments, files) => {
        const formData = new FormData();
        formData.append('email', doctorEmail);
        formData.append('subject', 'Hasta Kayıt Bilgileri');
        formData.append('PatientRegistrationDetails', formatPatientRegistrationDetails(PatientRegistrationDetails));
        formData.append('additionalComments', additionalComments);
    
        // Dosyaları form verisine ekleyin
        files.forEach(file => {
            formData.append('files', file);
        });
    
        try {
            await apiManager.post('/email/send-patient-registration', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            alert('E-posta başarıyla gönderildi.');
        } catch (error) {
            console.error('E-posta gönderilirken bir hata oluştu:', error);
        }
    };
    
    useEffect(() => {
        console.log(doctors);
    }, [doctors]);
    function PatientRegistrationtModal({ isOpen, onClose, onSend, doctors }) {
        const [selectedDoctorEmail, setSelectedDoctorEmail] = useState('');
        const [additionalComments, setAdditionalComments] = useState(''); // Ek açıklamalar için state
    
        // Doktor seçimi ve ek açıklamaları handle eden fonksiyonlar
        const handleDoctorChange = (e) => setSelectedDoctorEmail(e.target.value);
        const handleCommentsChange = (e) => setAdditionalComments(e.target.value);
        const [selectedFiles, setSelectedFiles] = useState([]); // Seçilen dosyalar için state

const handleFileChange = (event) => {
    setSelectedFiles([...selectedFiles, ...event.target.files]);
};

const handleFileRemove = (index) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
};
    
        return (
            <Modal isOpen={isOpen} onRequestClose={onClose} style={{ content: { top: '50%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)', width: 'auto', maxWidth: '500px' }, overlay: { background: "rgba(0, 0, 0, 0.75)" } }}>
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h5 className="modal-title w-100 mb-4">Doktor Seçimi ve Ek Açıklamalar</h5>
                    </div>
                    <div className="modal-body">
                        <div className="d-flex justify-content-center">
                            <div className="w-100">
                                <form>
                                    <div className="form-group mb-3">
                                        <label htmlFor="doctorSelect">Doktor Seçiniz</label>
                                        <select id="doctorSelect" className="form-control" onChange={handleDoctorChange} value={selectedDoctorEmail}>
                                            <option value="">Seçiniz...</option>
                                            {doctors && doctors.length > 0 && doctors.map((doctor) => (
                                            <option key={doctor.uyeId} value={doctor.email}>{doctor.unvani}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="additionalComments">Ek Açıklamalar</label>
                                        <textarea id="additionalComments" className="form-control" rows="3" onChange={handleCommentsChange} value={additionalComments}></textarea>
                                    </div>
                                    <div className="form-group mb-3">
    <label htmlFor="fileUpload">Dosyalar</label>
    <input type="file" id="fileUpload" className="form-control" onChange={handleFileChange} multiple />
    <ul className="margin-top">
        {selectedFiles.map((file, index) => (
            <li key={index}>{file.name} <button type="button" className="btn btn-danger btn-sm btn-extra-sm" onClick={() => handleFileRemove(index)}>Sil</button></li>
        ))}
    </ul>
</div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center mt-4">
                        <button type="button" className="btn btn-primary me-2" onClick={() => onSend(selectedDoctorEmail, additionalComments, selectedFiles)}>Gönder</button>
                        <button type="button" className="btn btn-secondary" onClick={onClose}>Kapat</button>
                    </div>
                </div>
            </Modal>
        );
    }
    
    

    const handleModalSend = (doctorEmail, additionalComments,selectedFiles) => {
        if (selectedPatientRegistration && doctorEmail) {
            sendPatientRegistrationEmail(doctorEmail, selectedPatientRegistration, additionalComments, selectedFiles);
            setIsModalOpen(false); // Modalı kapat
            setSelectedFiles([]); // Seçilen dosyaları sıfırla
        } else {
            alert('Lütfen bir doktor seçiniz.');
        }
    };

    const handleEmailSend = (kayit) => {
        // E-posta gönderme işlemi için gerekli kodlar
        // Örneğin:
        setSelectedPatientRegistration(kayit);
        setIsModalOpen(true);
    };


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="custom-sidebar no-padding-left">
                    <LeftSidebar />
                </div>
                <div className="custom-content">
                <h2 style={{ backgroundColor: '#2c3e50',color:'white', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', fontSize:'1.6rem'}}>Hasta Kayıt Raporu</h2>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px', padding: '5px 10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9' }}>
                        <p style={{ margin: 0 }}>Kayıt Detayları ve Yazdırma İçin İlgili Kaydın Üzerine Tıklayınız.</p>
                        <button type="button" onClick={yazdirTumKayitlari} className="btn btn-primary" >Tüm Kayıtları Yazdır</button>
                    </div>
                    <div className="filter-section" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <div style={{ marginRight: '20px' }}>
                            <label htmlFor="baslangicTarihi">Başlangıç Tarihi:</label>
                            <input
                                type="date"
                                id="baslangicTarihi"
                                value={baslangicTarihi}
                                onChange={(e) => setBaslangicTarihi(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div>
                            <label htmlFor="bitisTarihi">Bitiş Tarihi:</label>
                            <input
                                type="date"
                                id="bitisTarihi"
                                value={bitisTarihi}
                                onChange={(e) => setBitisTarihi(e.target.value)}
                                className="form-control"
                            />
                        </div>
                    </div>



                    {/* Düzenleme formu ve DetayPaneli */}
                    <DetayPaneli kayit={secilenKayit} kapat={kapatDetayPaneli} dosyalar={secilenKayitDosyalari} onEmailSend={handleEmailSend} />
                    {duzenlemeFormuGoster && (
                        <div className="update-panel card">
                            <div className="card-header">
                                <h3>Kayıt Güncelle</h3>
                            </div>
                            <div className="card-body" >
                                <form onSubmit={handleUpdate} className="row g-3" >
                                    <div className="col-md-6">
                                        <label htmlFor="adi">Adı:</label>
                                        <input type="text" className="form-control" value={duzenlenecekKayit.adi} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, adi: e.target.value })} placeholder="Adı" />
                                        <label htmlFor="soyadi">Soyadı:</label>
                                        <input type="text" className="form-control mt-2" value={duzenlenecekKayit.soyadi} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, soyadi: e.target.value })} placeholder="Soyadı" />
                                        <label htmlFor="dogumTarihi">Doğum Tarihi:</label>
                                        <input type="date" className="form-control mt-2" value={duzenlenecekKayit.dogumTarihi} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, dogumTarihi: e.target.value })} placeholder="Doğum Tarihi" />
                                        <label htmlFor="eNabiz">E-Nabız:</label>
                                        <input type="text" className="form-control mt-2" value={duzenlenecekKayit.eNabiz} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, eNabiz: e.target.value })} placeholder="E-nabız" />
                                        <label htmlFor="aciklama">Açıklama:</label>
                                        <textarea className="form-control mt-2" value={duzenlenecekKayit.aciklama} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, aciklama: e.target.value })} placeholder="Açıklama"></textarea>
                                        <label htmlFor="tarih">Tarih:</label>
                                        <input type="date" className="form-control" value={duzenlenecekKayit.tarih} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, tarih: e.target.value })} placeholder="Tarih" />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="cinsiyet">Cinsiyet:</label>
                                        <input type="text" className="form-control mt-2" value={duzenlenecekKayit.cinsiyet} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, cinsiyet: e.target.value })} placeholder="Cinsiyet" />
                                        <label htmlFor="tcKimlikNo">TC Kimlik No:</label>
                                        <input type="text" className="form-control mt-2" value={duzenlenecekKayit.tcKimlikNo} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, tcKimlikNo: e.target.value })} placeholder="TC Kimlik No" />
                                        <label htmlFor="fizikMuayene">Fizik Muayene:</label>
                                        <input type="text" className="form-control mt-2" value={duzenlenecekKayit.fizikMuayene} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, fizikMuayene: e.target.value })} placeholder="Fizik Muayene" />
                                        <label htmlFor="goruntulemeLab">Görüntüleme/Lab:</label>
                                        <input type="text" className="form-control mt-2" value={duzenlenecekKayit.goruntulemeLab} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, goruntulemeLab: e.target.value })} placeholder="Görüntüleme/Lab" />
                                        <label htmlFor="sonucKarar">Sonuç Karar:</label>
                                        <textarea className="form-control mt-2" value={duzenlenecekKayit.sonucKarar} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, sonucKarar: e.target.value })} placeholder="Sonuç / Karar"></textarea>
                                        <label htmlFor="dosyalar">Hasta Dosyaları:</label>
                                        <input type="file" multiple onChange={handleFileChange} className="form-control mt-2" />
                                        {selectedFiles.length > 0 && (
                                            <div className="mt-2">
                                                <strong>Seçilen Dosyalar:</strong>
                                                <ul>
                                                    {selectedFiles.map((file, index) => (
                                                        <li key={index}>
                                                            {file.name} <button type="button" className="btn btn-danger btn-sm" onClick={() => handleFileRemove(index)}>Sil</button>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary me-2">Güncelle</button>
                                        <button type="button" onClick={kapatGuncellemePaneli} className="btn btn-danger">Kapat</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    )}
                    {/* Filtreleme formu (eğer varsa) */}

                    <div className="table-responsive" style={{ maxHeight: '75vh', overflowX: 'auto' }}>
                        <table className="table table-striped table-bordered" >
                            <thead >
                                <tr >
                                    <th>İşlemler</th>
                                    <th>Hasta Takip No<input type="text" name="hastaTakipNo" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>Tarih</th>
                                    <th>Adı<input type="text" name="adi" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>Soyadı<input type="text" name="soyadi" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>Doğum Tarihi</th>
                                    <th>Cinsiyet<input type="text" name="cinsiyet" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>E-nabız<input type="text" name="eNabiz" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>
                                        TC Kimlik No
                                        <input type="text" name="tcKimlikNo" onChange={handleFilterChange} className="form-control" />
                                    </th>
                                    <th>Açıklama<input type="text" name="aciklama" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>Fizik Muayene<input type="text" name="fizikMuayene" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>Görüntüleme<input type="text" name="goruntulemeLab" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>Sonuç / Karar<input type="text" name="sonucKarar" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>Dosyalar</th>
                                    {/* Diğer başlık sütunları (eğer varsa) */}
                                </tr>
                            </thead>
                            <tbody>
                                {filteredKayitlar.map((kayit, index) => (
                                    <tr key={index} onClick={() => handleRowClick(kayit)} className="clickable-row">
                                        <td>
                                            <button onClick={(e) => { e.stopPropagation(); handleEdit(kayit); }} className="btn btn-primary btn-sm btn-extra-sm me-1">
                                                <FontAwesomeIcon icon={faEdit} />
                                            </button>
                                            <button onClick={(e) => { e.stopPropagation(); handleDelete(kayit); }} className="btn btn-danger btn-sm btn-extra-sm">
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </button>

                                        </td>
                                        <td>{kayit.hastaTakipNo}</td>
                                        <td>{formatDate(kayit.tarih)}</td>
                                        <td>{kayit.adi ? (kayit.adi.length > 10 ? kayit.adi.substring(0, 10) + '...' : kayit.adi) : ''}</td>
                                        <td>{kayit.soyadi ? (kayit.soyadi.length > 10 ? kayit.soyadi.substring(0, 10) + '...' : kayit.soyadi) : ''}</td>
                                        <td>{formatDate(kayit.dogumTarihi)}</td>
                                        <td>{kayit.cinsiyet}</td>
                                        <td>{kayit.eNabiz ? (kayit.eNabiz.length > 10 ? kayit.eNabiz.substring(0, 10) + '...' : kayit.eNabiz) : ''}</td>
                                        <td>{kayit.tcKimlikNo}</td>
                                        <td>
                                            {kayit.aciklama ? (kayit.aciklama.length > 10 ? kayit.aciklama.substring(0, 10) + '...' : kayit.aciklama) : ''}
                                        </td>

                                        <td>{kayit.fizikMuayene ? (kayit.fizikMuayene.length > 10 ? kayit.fizikMuayene.substring(0, 10) + '...' : kayit.fizikMuayene) : ''}</td>

                                        <td>{kayit.goruntulemeLab ? (kayit.goruntulemeLab.length > 10 ? kayit.goruntulemeLab.substring(0, 10) + '...' : kayit.goruntulemeLab) : ''}</td>
                                        <td>{kayit.sonucKarar ? (kayit.sonucKarar.length > 10 ? kayit.sonucKarar.substring(0, 10) + '...' : kayit.sonucKarar) : ''}</td>

                                        <td>
                                            {kayit.dosyaSayisi > 0 ?
                                                `${kayit.dosyaSayisi} Dosya` :
                                                'Dosya Yok'
                                            }
                                        </td>
                                        {/* Diğer hücreler (eğer varsa) */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <PatientRegistrationtModal
                            isOpen={isModalOpen}
                            onClose={() => setIsModalOpen(false)}
                            onSend={handleModalSend}
                            doctors={doctors} // Bu satır çok önemli
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HastaKayitRaporu;
