import React, { useState } from 'react';
import { useEffect } from 'react';
import LeftSidebar from '../components/LeftSidebar';
import apiManager from '../service/apiManager';



const TetkikTakipIstekFormu = () => {
    // State'ler
    const [hastaTakipNo, setHastaTakipNo] = useState('');
    const [tarih, setTarih] = useState(null);
    const [adi, setAdi] = useState('');
    const [soyadi, setSoyadi] = useState('');
    const [secilenHemotolojikTestler, setSecilenHemotolojikTestler] = useState([]);
    const [kullanilabilirHemotolojikTestler, setKullanilabilirHemotolojikTestler] = useState([
        "Hemogram(24 parametre)", "Sedimentasyon", "Direkt Coombs", "indirekt Coombs", "Kan Grubu Tayini", "Periferik Yayma"
    ]);
    const [secilenKoagulasyonTestleri, setSecilenKoagulasyonTestleri] = useState([]);
    const [kullanilabilirKoagulasyonTestleri, setKullanilabilirKoagulasyonTestleri] = useState([
        "APTT", "Protombin Zamani", "Fibrinojen", "Kanama Zamanı", "Pıhtılaşma Zamanı"
    ]);
    // Bio Kimyasal Testler için state ve handler
    const [secilenBioKimyasalTestler, setSecilenBioKimyasalTestler] = useState([]);
    const [kullanilabilirBioKimyasalTestler, setKullanilabilirBioKimyasalTestler] = useState([
        "Glikoz(Açlık)", "Glikoz(Tokluk)", "HbA1C(Glikolize hemoglobin)", "Üre", "Kreatinin", "GFR", "Ürük Asit", "TotalProtein", "Albümin",
        "Globulin", "BilirubinTotal(Total)", "BilirubinTotal(Direkt)", "Trigliserid", "HDL", "LDL", "VLDL", "ALT", "AST", "GGT", "ALP", "LDH", "Amilaz",
        "Lipaz", "ASO", "CRP", "RF", "Sodyum", "Potasyum", "Klor", "Kalsiyum", "Magnezyum", "Fosfor"                             // ve diğerleri...
    ]);
    // Kardiyak Testler için state ve handler
    const [secilenKardiyakTestler, setSecilenKardiyakTestler] = useState([]);
    const [kullanilabilirKardiyakTestler, setKullanilabilirKardiyakTestler] = useState([
        "CK", "CK-MB(PT)", "Troponin I"
    ]);
    // Hormon Testleri için state ve handler
    const [secilenHormonTestleri, setSecilenHormonTestleri] = useState([]);
    const [kullanilabilirHormonTestleri, setKullanilabilirHormonTestleri] = useState([
        "Beta-hCG", "FSH", "LH", "Progesteron", "Prolaktin", "DHEA-SO4", "17-OH Progesteron", "Testesteron(Total)", "Testesteron(Serbest)",
        "Kortizol", "insülin(Aclik)", "insülin(Tokluk)", "VitaminB12", "25-OH Vitamin D", "Ferritin", "Folat"                          // ve diğerleri...
    ]);
    // Triod Testleri için state ve handler
    const [secilenTriodTestleri, setSecilenTriodTestleri] = useState([]);
    const [kullanilabilirTriodTestleri, setKullanilabilirTriodTestleri] = useState([
        "TSH", "Serbast T3", "Serbest T4", "Antri Tiroglubulin", "Anti TPO", "Parathormon"
    ]);
    // Tümör Markerları için state ve handler
    const [secilenTumorMarkerlar, setSecilenTumorMarkerlar] = useState([]);
    const [kullanilabilirTumorMarkerlar, setKullanilabilirTumorMarkerlar] = useState([
        "AFP", "CA125", "CA 15-3", "CA 19-9", "CA 72-4", "CEA", "PSA(Total)", "PSA(Serbest)"
    ]);
    // Hepatit Markerları için state ve handler
    const [secilenHepatitMarkerlar, setSecilenHepatitMarkerlar] = useState([]);
    const [kullanilabilirHepatitMarkerlar, setKullanilabilirHepatitMarkerlar] = useState([
        "Tam idrar Tetkiki", "Mikroalbumin(spot)", "Mikroalbumin(24 saat)", "idrarda Protein(24 saat)",
        "idrarda Protein(spot)", "Gaita Mikroskopik Incelenmesi", "Gaitada Gizli Kan",
        "Gaitada Reduktan Madde", "Helicobacter Pylori Antijeni(Gaita)", "Kıl Kurdu(Enterobius Vermicularis)"
    ]);
    // Mikrobiolojik Testler için state ve handler
    const [secilenMikrobiolojikTestler, setSecilenMikrobiolojikTestler] = useState([]);
    const [kullanilabilirMikrobiolojikTestler, setKullanilabilirMikrobiolojikTestler] = useState([
        "Brucella agl.(rose bengal)", "Brucella tüp(wright) agl", "Brucella tüp agl.(coobs)",
        "Brucella tüp agl(2-Merkaptoetanol)", "Helicobacter pylori anikoru(serum)", "Salmonella Tüp agl(grubel-widal)",
        "Sifiliz(VDRL)Kaset Test"
    ]);
    // Kültür Testleri için state ve handler
    const [secilenKulturTestleri, setSecilenKulturTestleri] = useState([]);
    const [kullanilabilirKulturTestleri, setKullanilabilirKulturTestleri] = useState([
        "Balgam Külturü", "Boğaz Külturü", "Gaita Külturü", "idrar Kültürü", "Yara Kültürü"
    ]);
    const resetForm = () => {
        setTarih(null);
        setAdi('');
        setSoyadi('');
        setSecilenHemotolojikTestler([]);
        setKullanilabilirHemotolojikTestler(["Hemogram(24 parametre)", "Sedimentasyon", "Direkt Coombs", "indirekt Coombs", "Kan Grubu Tayini", "Periferik Yayma"]);
        // Diğer state'leri de sıfırlama işlemleri
        setSecilenKoagulasyonTestleri([]);
        setKullanilabilirKoagulasyonTestleri(["APTT", "Protombin Zamani", "Fibrinojen", "Kanama Zamanı", "Pıhtılaşma Zamanı"]);

        setSecilenBioKimyasalTestler([]);
        setKullanilabilirBioKimyasalTestler(["Glikoz(Açlık)", "Glikoz(Tokluk)", "HbA1C(Glikolize hemoglobin)", "Üre", "Kreatinin", "GFR", "Ürük Asit", "TotalProtein", "Albümin",
        "Globulin", "BilirubinTotal(Total)", "BilirubinTotal(Direkt)", "Trigliserid", "HDL", "LDL", "VLDL", "ALT", "AST", "GGT", "ALP", "LDH", "Amilaz",
        "Lipaz", "ASO", "CRP", "RF", "Sodyum", "Potasyum", "Klor", "Kalsiyum", "Magnezyum", "Fosfor" ]);

        setSecilenKardiyakTestler([]);
        setKullanilabilirKardiyakTestler(["CK", "CK-MB(PT)", "Troponin I"]);
        setSecilenHormonTestleri([]);
        setKullanilabilirHormonTestleri([ "Beta-hCG", "FSH", "LH", "Progesteron", "Prolaktin", "DHEA-SO4", "17-OH Progesteron", "Testesteron(Total)", "Testesteron(Serbest)",
        "Kortizol", "insülin(Aclik)", "insülin(Tokluk)", "VitaminB12", "25-OH Vitamin D", "Ferritin", "Folat"]);
        setSecilenTriodTestleri([]);
        setKullanilabilirTriodTestleri(["TSH", "Serbast T3", "Serbest T4", "Antri Tiroglubulin", "Anti TPO", "Parathormon"]);
        setSecilenTumorMarkerlar([]);
        setKullanilabilirTumorMarkerlar(["AFP", "CA125", "CA 15-3", "CA 19-9", "CA 72-4", "CEA", "PSA(Total)", "PSA(Serbest)"]);
        setSecilenHepatitMarkerlar([]);
        setKullanilabilirHepatitMarkerlar(["Tam idrar Tetkiki", "Mikroalbumin(spot)", "Mikroalbumin(24 saat)", "idrarda Protein(24 saat)",
        "idrarda Protein(spot)", "Gaita Mikroskopik Incelenmesi", "Gaitada Gizli Kan",
        "Gaitada Reduktan Madde", "Helicobacter Pylori Antijeni(Gaita)", "Kıl Kurdu(Enterobius Vermicularis)"]);
        setSecilenMikrobiolojikTestler([]);
        setKullanilabilirMikrobiolojikTestler(["Brucella agl.(rose bengal)", "Brucella tüp(wright) agl", "Brucella tüp agl.(coobs)",
        "Brucella tüp agl(2-Merkaptoetanol)", "Helicobacter pylori anikoru(serum)", "Salmonella Tüp agl(grubel-widal)",
        "Sifiliz(VDRL)Kaset Test"]);
        setSecilenKulturTestleri([]);
        setKullanilabilirKulturTestleri(["Balgam Külturü", "Boğaz Külturü", "Gaita Külturü", "İdrar Kültürü", "Yara Kültürü"]);
        // İlk başta tanımladığınız kullanılabilir test listelerini de sıfırlamanız gerekebilir.
    };

    const handleKoagulasyonTestSelect = (e) => {
        if (e.target.value === "") return;
        const secilenTest = e.target.value;
        setSecilenKoagulasyonTestleri(prev => [...prev, secilenTest]);
        setKullanilabilirKoagulasyonTestleri(prev => prev.filter(test => test !== secilenTest));
    };

    // Hemotolojik testleri seçmek için handler
    const handleHemotolojikTestSelect = (e) => {
        if (e.target.value === "") return; // Boş değeri seçimi engelle
        const secilenTest = e.target.value;
        setSecilenHemotolojikTestler(prev => [...prev, secilenTest]);
        setKullanilabilirHemotolojikTestler(prev => prev.filter(test => test !== secilenTest));
    };
    const handleBioKimyasalTestSelect = (e) => {
        if (e.target.value === "") return;
        const secilenTest = e.target.value;
        setSecilenBioKimyasalTestler(prev => [...prev, secilenTest]);
        setKullanilabilirBioKimyasalTestler(prev => prev.filter(test => test !== secilenTest));
    };
    const handleKardiyakTestSelect = (e) => {
        if (e.target.value === "") return;
        const secilenTest = e.target.value;
        setSecilenKardiyakTestler(prev => [...prev, secilenTest]);
        setKullanilabilirKardiyakTestler(prev => prev.filter(test => test !== secilenTest));
    };
    const handleHormonTestSelect = (e) => {
        if (e.target.value === "") return;
        const secilenTest = e.target.value;
        setSecilenHormonTestleri(prev => [...prev, secilenTest]);
        setKullanilabilirHormonTestleri(prev => prev.filter(test => test !== secilenTest));
    };
    const handleTriodTestSelect = (e) => {
        if (e.target.value === "") return;
        const secilenTest = e.target.value;
        setSecilenTriodTestleri(prev => [...prev, secilenTest]);
        setKullanilabilirTriodTestleri(prev => prev.filter(test => test !== secilenTest));
    };
    const handleTumorMarkerlarSelect = (e) => {
        if (e.target.value === "") return;
        const secilenTest = e.target.value;
        setSecilenTumorMarkerlar(prev => [...prev, secilenTest]);
        setKullanilabilirTumorMarkerlar(prev => prev.filter(test => test !== secilenTest));
    };
    const handleHepatitMarkerlarSelect = (e) => {
        if (e.target.value === "") return;
        const secilenTest = e.target.value;
        setSecilenHepatitMarkerlar(prev => [...prev, secilenTest]);
        setKullanilabilirHepatitMarkerlar(prev => prev.filter(test => test !== secilenTest));
    };
    const handleMikrobiolojikTestSelect = (e) => {
        if (e.target.value === "") return;
        const secilenTest = e.target.value;
        setSecilenMikrobiolojikTestler(prev => [...prev, secilenTest]);
        setKullanilabilirMikrobiolojikTestler(prev => prev.filter(test => test !== secilenTest));
    };
    const handleKulturTestSelect = (e) => {
        if (e.target.value === "") return;
        const secilenTest = e.target.value;
        setSecilenKulturTestleri(prev => [...prev, secilenTest]);
        setKullanilabilirKulturTestleri(prev => prev.filter(test => test !== secilenTest));
    };
    // Form gönderildiğinde yapılacak işlemler
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Bütün seçilen testleri tek bir objede topluyoruz
        const tetkikler = {
            hemotolojikTestler: secilenHemotolojikTestler,
            koagulasyonTestleri: secilenKoagulasyonTestleri,
            bioKimyasalTestler: secilenBioKimyasalTestler,
            kardiyakTestler: secilenKardiyakTestler,
            hormonTestleri: secilenHormonTestleri,
            triodTestleri: secilenTriodTestleri,
            tumorMarkerlar: secilenTumorMarkerlar,
            hepatitMarkerlar: secilenHepatitMarkerlar,
            mikrobiolojikTestler: secilenMikrobiolojikTestler,
            kulturTestleri: secilenKulturTestleri
            // Diğer test türleri...
        };

        // Form verileri
        const formData = {
            hastaTakipNo,
            tarih,
            adi,
            soyadi,
            tetkikler // Tetkikleri de ekleyin
        };

        // API isteği
        try {
            await apiManager.post('/tetkik-takip-istek-formu', formData ).then(data=>{
                if (data) {
                    console.log('Form kaydı başarılı.');
                    alert('Form başarıyla oluşturuldu.');
                    window.location.reload();
                } else {
                    console.error('Hata oluştu.');
                }
            })    
        } catch (error) {
            console.error('Sunucu hatası:', error);
        }  
    };
    useEffect(() => {
        const fetchHastaTakipNo = async () => {
            try {
                await apiManager.get('/yeni-tetkik-takip-no').then((data) => {
                    if (data) {
                        setHastaTakipNo(data.hastaTakipNo);
                    } else {
                        console.error('Veri yüklenemedi.');
                    }
                });
            } catch (error) {
                console.error('Sunucu hatası:', error);
            }
          };
    
        fetchHastaTakipNo();
      }, []);
    return (
        <div className="container-fluid">
            <div className="row">
            <div className="col-md-2 no-padding-left">
  <LeftSidebar />
</div>
                <div className="col-md-10">
                <h2 style={{ backgroundColor: '#2c3e50',color:'white', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', fontSize:'1.6rem'}}>Tetkik Takip İstek Formu</h2>
                    <form onSubmit={handleSubmit} className="row g-3">
                        <div className="col-md-4">
                            <div className="form-group mt-3">
                                <label htmlFor="hastaTakipNo">Hasta Takip No:<span className="required">*</span></label>
                                <input type="text" className="form-control" id="hastaTakipNo" value={hastaTakipNo} onChange={(e) => setHastaTakipNo(e.target.value)} readOnly/>
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="tarih">Tarih:<span className="required">*</span></label>
                                <input type="date" className="form-control" id="tarih" value={tarih} onChange={(e) => setTarih(e.target.value)} required/>
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="adi">Adı:<span className="required">*</span></label>
                                <input type="text" className="form-control" id="adi" value={adi} onChange={(e) => setAdi(e.target.value)} required/>
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="soyadi">Soyadı:<span className="required">*</span></label>
                                <input type="text" className="form-control" id="soyadi" value={soyadi} onChange={(e) => setSoyadi(e.target.value)} required/>
                            </div>
                        </div>

                        <div className="col-md-4">
                            {/* Hemotolojik Testler */}
                            <div className="form-group mt-3">
                                <label htmlFor="hemotolojikTestler">Hemotolojik Testler</label>
                                <select className="form-control" id="hemotolojikTestler" value="" onChange={handleHemotolojikTestSelect}>
                                    <option value="" disabled>Seçiniz</option>
                                    {kullanilabilirHemotolojikTestler.map(test => (
                                        <option key={test} value={test}>{test}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="test-list">
                                {secilenHemotolojikTestler.map(test => <div key={test} className="badge bg-primary me-2">{test}</div>)}
                            </div>

                            {/* Koagülasyon Testleri */}
                            <div className="form-group mt-3">
                                <label htmlFor="koagulasyonTestleri">Koagülasyon Testleri</label>
                                <select className="form-control" id="koagulasyonTestleri" value="" onChange={handleKoagulasyonTestSelect}>
                                    <option value="" disabled>Seçiniz</option>
                                    {kullanilabilirKoagulasyonTestleri.map(test => (
                                        <option key={test} value={test}>{test}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="test-list">
                                {secilenKoagulasyonTestleri.map(test => <div key={test} className="badge bg-secondary me-2">{test}</div>)}
                            </div>

                            <div className="form-group mt-3">
                                <label htmlFor="kardiyakTestler">Kardiyak Testler</label>
                                <select className="form-control" id="kardiyakTestler" value="" onChange={handleKardiyakTestSelect}>
                                    <option value="" disabled>Seçiniz</option>
                                    {kullanilabilirKardiyakTestler.map(test => (
                                        <option key={test} value={test}>{test}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="test-list">
                                {secilenKardiyakTestler.map(test => <div key={test} className="badge bg-warning me-2">{test}</div>)}
                            </div>

                            {/* Hormon Testleri */}
                            <div className="form-group mt-3">
                                <label htmlFor="hormonTestleri">Hormon Testleri</label>
                                <select className="form-control" id="hormonTestleri" value="" onChange={handleHormonTestSelect}>
                                    <option value="" disabled>Seçiniz</option>
                                    {kullanilabilirHormonTestleri.map(test => (
                                        <option key={test} value={test}>{test}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="test-list">
                                {secilenHormonTestleri.map(test => <div key={test} className="badge bg-info me-2">{test}</div>)}
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="bioKimyasalTestler">Biyokimyasal Testler</label>
                                <select className="form-control" id="bioKimyasalTestler" value="" onChange={handleBioKimyasalTestSelect}>
                                    <option value="" disabled>Seçiniz</option>
                                    {kullanilabilirBioKimyasalTestler.map(test => (
                                        <option key={test} value={test}>{test}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="test-list">
                                {secilenBioKimyasalTestler.map(test => <div key={test} className="badge bg-success me-2">{test}</div>)}
                            </div>
                            {/* ... Diğer test seçimleri ve listeleme ... */}
                        </div>

                        <div className="col-md-4">
                            {/* Bio Kimyasal Testler */}
                            {/* ... */}

                            <div className="form-group mt-3">
                                <label htmlFor="triodTestleri">Triod Testleri</label>
                                <select className="form-control" id="triodTestleri" value="" onChange={handleTriodTestSelect}>
                                    <option value="" disabled>Seçiniz</option>
                                    {kullanilabilirTriodTestleri.map(test => (
                                        <option key={test} value={test}>{test}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="test-list">
                                {secilenTriodTestleri.map(test => <div key={test} className="badge bg-danger me-2">{test}</div>)}
                            </div>

                            {/* Tümör Markerlar */}
                            <div className="form-group mt-3">
                                <label htmlFor="tumorMarkerlar">Tümör Markerlar</label>
                                <select className="form-control" id="tumorMarkerlar" value="" onChange={handleTumorMarkerlarSelect}>
                                    <option value="" disabled>Seçiniz</option>
                                    {kullanilabilirTumorMarkerlar.map(test => (
                                        <option key={test} value={test}>{test}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="test-list">
                                {secilenTumorMarkerlar.map(test => <div key={test} className="badge bg-success me-2">{test}</div>)}
                            </div>

                            {/* Hepatit Markerlar */}
                            <div className="form-group mt-3">
                                <label htmlFor="hepatitMarkerlar">Hepatit Markerlar</label>
                                <select className="form-control" id="hepatitMarkerlar" value="" onChange={handleHepatitMarkerlarSelect}>
                                    <option value="" disabled>Seçiniz</option>
                                    {kullanilabilirHepatitMarkerlar.map(test => (
                                        <option key={test} value={test}>{test}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="test-list">
                                {secilenHepatitMarkerlar.map(test => <div key={test} className="badge bg-primary me-2">{test}</div>)}
                            </div>

                            {/* Mikrobiolojik Testler */}
                            <div className="form-group mt-3">
                                <label htmlFor="mikrobiolojikTestler">Mikrobiolojik Testler</label>
                                <select className="form-control" id="mikrobiolojikTestler" value="" onChange={handleMikrobiolojikTestSelect}>
                                    <option value="" disabled>Seçiniz</option>
                                    {kullanilabilirMikrobiolojikTestler.map(test => (
                                        <option key={test} value={test}>{test}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="test-list">
                                {secilenMikrobiolojikTestler.map(test => <div key={test} className="badge bg-secondary me-2">{test}</div>)}
                            </div>

                            {/* Kültür Testleri */}
                            <div className="form-group mt-3">
                                <label htmlFor="kulturTestleri">Kültür Testleri</label>
                                <select className="form-control" id="kulturTestleri" value="" onChange={handleKulturTestSelect}>
                                    <option value="" disabled>Seçiniz</option>
                                    {kullanilabilirKulturTestleri.map(test => (
                                        <option key={test} value={test}>{test}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="test-list">
                                {secilenKulturTestleri.map(test => <div key={test} className="badge bg-info me-2">{test}</div>)}
                            </div>
                        </div>


                        {/* Kardiyak Testler */}
                        {/* ... */}
                        {/* Hormon Testleri */}
                        {/* ... */}
                        {/* Triod Testleri */}
                        {/* ... */}
                        {/* Tümör Markerlar */}
                        {/* ... */}
                        {/* Hepatit Markerlar */}
                        {/* ... */}
                        {/* Mikrobiolojik Testler */}
                        {/* ... */}
                        {/* Kültür Testleri */}
                        {/* ... */}

                        <div className="col-12">
                            <button type="submit" className="btn btn-primary me-2">Formu Gönder</button>
                            <button type="button" className="btn btn-secondary" onClick={resetForm}>Sıfırla</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    );
}

export default TetkikTakipIstekFormu;
