import React from 'react';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap'i dahil et

const UpcomingAppointments = ({ appointments }) => {
  // Şu anki tarih ve saati al
  const now = moment();

  // Yaklaşan randevuları filtrele, saati de dikkate alarak
  const upcomingAppointments = appointments
    .filter(appointment => 
      moment(appointment.randevuTarihi).isAfter(now, 'day') ||
      (moment(appointment.randevuTarihi).isSame(now, 'day') && moment(appointment.randevuSaati, 'HH:mm:ss').isAfter(now))
    )
    .sort((a, b) => moment(a.randevuTarihi).diff(moment(b.randevuTarihi)))
    .slice(0, 3); // İlk 3 randevuyu al

  return (
    <div className="container mt-4">
      <div className="upcoming-appointments-container">
        <div className="card">
          <div className="card-header">
            <h2 className='text-red' style={{ fontSize: '1.2rem' }}>Yaklaşan Randevular</h2>
          </div>
          <div className="card-body" style={{ fontSize: '0.7rem' }}>
            {upcomingAppointments.length > 0 ? (
              upcomingAppointments.map((appointment, index) => (
                <div key={index} className="card mb-3">
                  <div className="card-body text-white" style={{ backgroundColor: '#17a2b8' }}>
                    <h5 className="card-title" style={{ fontSize: '1rem' }}>Randevu Tarihi: {moment(appointment.randevuTarihi).format('DD-MM-YYYY')}</h5>
                    <h6 className="card-subtitle mb-2" style={{ fontSize: '1rem' }}>Saat: {appointment.randevuSaati ? moment(appointment.randevuSaati, 'HH:mm:ss').format('HH:mm') : 'Bilinmiyor'}</h6>
                    <p className="card-text" style={{ marginBottom: '0.5rem', fontSize:'0.8rem'}}>Doktor: {appointment.doktor}</p>
                    <p className="card-text" style={{ fontSize: '0.8rem' }}>Hasta: {appointment.hastaAdi} {appointment.hastaSoyadi}</p>
                  </div>
                </div>
              ))
            ) : (
              <p>Yaklaşan randevu yok.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpcomingAppointments;
