// src/pages/HastaKayitFormu.js
import React, { useState,useEffect } from 'react';
import LeftSidebar from '../components/LeftSidebar';
import apiManager from '../service/apiManager';


const YatanHastaTakipFormu = () => {
  // State'ler
  const [hastaTakipNo, setHastaTakipNo] = useState('');
  const [adi, setAdi] = useState('');
  const [soyadi, setSoyadi] = useState('');
  const [dogumTarihi, setDogumTarihi] = useState(null);
  const [cinsiyet, setCinsiyet] = useState('');
  const [eNabiz, setENabiz] = useState('');
  const [tcKimlikNo, setTcKimlikNo] = useState('');
  const [hastalikKodu, setHastalikKodu] = useState('');
  const [yatisTarihi, setYatisTarihi] = useState(null);
  const [cikisTarihi, setCikisTarihi] = useState(null);
  const [yapilanAmeliyat, setYapilanAmeliyat] = useState('');
  const [ameliyatYapilanHastane, setAmeliyatYapilanHastane] = useState();
  const [ameliyatNotu, setAmeliyatNotu] = useState();

  // Form gönderildiğinde yapılacak işlemler
  useEffect(() => {
    const fetchHastaTakipNo = async () => {
      try {
        await apiManager.get('/yeni-yatan-hasta-takip-no').then((data) => {
            if (data) {
                setHastaTakipNo(data.hastaTakipNo);
            } else {
                console.error('Veri yüklenemedi.');
            }
        });
    } catch (error) {
        console.error('Sunucu hatası:', error);
    }
    };

    fetchHastaTakipNo();
  }, []);
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form verilerini içeren nesneyi oluştur
    const formData = {
      hastaTakipNo,
      adi,
      soyadi,
      dogumTarihi,
      cinsiyet,
      eNabiz,
      yatisTarihi,
      cikisTarihi,
      tcKimlikNo,
      hastalikKodu,
      yapilanAmeliyat,
      ameliyatYapilanHastane,
      ameliyatNotu
    };

    try {
        // API yöneticisi üzerinden POST isteğini yap
        await apiManager.post('/yatan-hasta-kayit', formData).then(data=>{
          if(data){
            console.log('Hasta kaydı başarılı.');
            alert('Form başarıyla oluşturuldu.');
                  window.location.reload();
         }else{
          console.error('Hata oluştu.')
         }
       })
    } catch (error) {
        console.error('Sunucu hatası:', error);
        // Hata yönetimi
    }
  }

const handleReset = () => {
      setAdi('');
      setSoyadi('');
      setCinsiyet('');
      setENabiz('');
      setTcKimlikNo('');
      setHastalikKodu('');
      setYatisTarihi(null);
      setCikisTarihi(null);
      setYapilanAmeliyat('');
      setAmeliyatYapilanHastane('');
      setAmeliyatNotu('');
};
return (
    <div className="container-fluid">
      <div className="row">
      <div className="col-md-2 no-padding-left">
  <LeftSidebar />
</div>
        <div className="col-md-10">
        <h2 style={{ backgroundColor: '#2c3e50',color:'white', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', fontSize:'1.6rem'}}>Yatan Hasta Takip Formu</h2>
  <form onSubmit={handleSubmit} className="row g-3">
    <div className="col-md-6">
    <label className="form-label">Hasta Takip No:<span className="required">*</span></label>
                <input type="text" className="form-control" style={{ width: '90%' }} value={hastaTakipNo} onChange={(e) => setHastaTakipNo(e.target.value)} readOnly/>

      <label htmlFor="adi" className="form-label">Adı:<span className="required">*</span></label>
      <input type="text" className="form-control" id="adi" value={adi} onChange={(e) => setAdi(e.target.value)} required/>

      <label htmlFor="soyadi" className="form-label">Soyadı:<span className="required">*</span></label>
      <input type="text" className="form-control" id="soyadi" value={soyadi} onChange={(e) => setSoyadi(e.target.value)} required/>

      <label htmlFor="dogumTarihi" className="form-label mt-2">Doğum Tarihi:<span className="required">*</span></label>
      <input type="date" className="form-control" id="dogumTarihi" value={dogumTarihi} onChange={(e) => setDogumTarihi(e.target.value)} required/>

      <label htmlFor="eNabiz" className="form-label mt-2">E-nabız</label>
      <input type="text" className="form-control" id="eNabiz" value={eNabiz} onChange={(e) => setENabiz(e.target.value)} />

      

      <label htmlFor="yapilanAmeliyat" className="form-label mt-2">Yapılan Ameliyat</label>
      <textarea className="form-control" id="yapilanAmeliyat" value={yapilanAmeliyat} onChange={(e) => setYapilanAmeliyat(e.target.value)}></textarea>

      <label htmlFor="hastalikKodu" className="form-label mt-2">Hastalık Kodu</label>
      <input type="text" className="form-control" id="hastalikKodu" value={hastalikKodu} onChange={(e) => setHastalikKodu(e.target.value)}/>
    </div>

    <div className="col-md-6">
      

      <label htmlFor="cinsiyet" className="form-label mt-2">Cinsiyet:<span className="required">*</span></label>
      <select className="form-control" id="cinsiyet" value={cinsiyet} onChange={(e) => setCinsiyet(e.target.value)} required>
        <option value="">Seçiniz...</option>
        <option value="Erkek">Erkek</option>
        <option value="Kadın">Kadın</option>
      </select>

      <label htmlFor="tcKimlikNo" className="form-label mt-2">TC Kimlik No:<span className="required">*</span></label>
      <input type="text" className="form-control" id="tcKimlikNo" value={tcKimlikNo} onChange={(e) => setTcKimlikNo(e.target.value)} required/>

      <label htmlFor="yatisTarihi" className="form-label mt-2">Yatış Tarihi:<span className="required">*</span></label>
      <input type="date" className="form-control" id="yatisTarihi" value={yatisTarihi} onChange={(e) => setYatisTarihi(e.target.value)} required/>
      
      <label htmlFor="cikisTarihi" className="form-label mt-2">Çıkış Tarihi</label>
      <input type="date" className="form-control" id="cikisTarihi" value={cikisTarihi} onChange={(e) => setCikisTarihi(e.target.value)} />

      <label htmlFor="ameliyatYapilanHastane" className="form-label mt-2">Ameliyat Yapılan Hastane</label>
      <input type="text" className="form-control" id="ameliyatYapilanHastane" value={ameliyatYapilanHastane} onChange={(e) => setAmeliyatYapilanHastane(e.target.value)} />

      <label htmlFor="ameliyatNotu" className="form-label mt-2">Ameliyat Notu</label>
      <textarea className="form-control" id="ameliyatNotu" value={ameliyatNotu} onChange={(e) => setAmeliyatNotu(e.target.value)}></textarea>
    </div>

    <div className="col-12">
    <button type="submit" className="btn btn-primary me-2">Formu Oluştur</button>
              <button type="reset" onClick={handleReset} className="btn btn-secondary">Sıfırla</button>
    </div>
  </form>
</div>

      </div>
    </div>
  );
}

export default YatanHastaTakipFormu;
