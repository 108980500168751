import axios from 'axios';
    
const source = axios.CancelToken.source();
const domain = 'https://doktor-panel-1e744dda808e.herokuapp.com/api';
const domainDosya = 'https://doktor-panel-heroku-storage.s3.eu-central-1.amazonaws.com';

const get = async (URI) => {
  try {
    const response = await axios.get(domain.concat(URI), {
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token') || '')
      }
    });
    console.log(response.data);
    return response.data
} catch (error) {
  if (axios.isCancel(error)) {
    console.log('Request canceled:', error.message);
  } else {
    console.error('Error', error.message);
  }
}
};

const post = async (URI, body) => {
  try {
    const response = await axios.post(domain.concat(URI), body, {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token') || '')
      },

    }); 
    console.log(response.data);
    return response.data;
} catch (error) {
  if (axios.isCancel(error)) {
    console.log('Request canceled:', error.message);
  } else {
    console.error('Error', error.message);
  }
}
};
const patch = async (URI, body) => {
  try {
    const response = await axios.patch(domain.concat(URI), body, {
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token') || '')
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled:', error.message);
    } else if (error.response) {
      // Sunucu tarafından bir hata yanıtı alındı
      console.error('Error', error.response.status, error.response.data);
      // Hata ile ilgili spesifik bilgileri döndürmek
      throw error.response;
    } else {
      // Bir yanıt alınamadı veya başka bir hata oluştu
      console.error('Error', error.message);
      throw error; // Bu hatayı dışarı fırlat
    }
  }
};

const del = async (URI) => {
  try {
    const response = await axios.delete(domain.concat(URI), {
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token') || '')
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled:', error.message);
    } else {
      console.error('Error', error.message);
    }
  }
};

export default { get, post, patch, del,domainDosya };