import React, { useState, useEffect } from 'react';
import LeftSidebar from '../components/LeftSidebar';
import apiManager from '../service/apiManager';

const Recete = () => {
  // State'ler
  const [hastaTakipNo, setHastaTakipNo] = useState('');
  const [tarih, setTarih] = useState(null);
  const [adi, setAdi] = useState('');
  const [soyadi, setSoyadi] = useState('');
  const [tcKimlikNo, setTcKimlikNo] = useState('');
  const [adres, setAdres] = useState('');
  const [drAdiSoyadi, setDrAdiSoyadi] = useState('');
  const [diplomaNo, setDiplomaNo] = useState('');
  const [tescilNo, setTescilNo] = useState('');
  const [tani, setTani] = useState('');
  const [ilaclar, setIlaclar] = useState('');
  const [receteNo, setReceteNo] = useState('');
  const [receteImza, setReceteImza] = useState([]);

  const handleFileChange = (e) => {
    setReceteImza(prevFiles => [...prevFiles, ...e.target.files]);
  };
  const handleFileRemove = (indexToRemove) => {
    setReceteImza(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
  };
  useEffect(() => {
    const fetchHastaTakipNo = async () => {
      try {
        await apiManager.get('/yeni-recete-no').then((data) => {
            if (data) {
                setHastaTakipNo(data.hastaTakipNo);
            } else {
                console.error('Veri yüklenemedi.');
            }
        });
    } catch (error) {
        console.error('Sunucu hatası:', error);
    }
    };

    fetchHastaTakipNo();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('hastaTakipNo', hastaTakipNo);
    formData.append('tarih', tarih);
    formData.append('adi', adi);
    formData.append('soyadi', soyadi);
    formData.append('tcKimlikNo', tcKimlikNo);
    formData.append('adres', adres);
    formData.append('drAdiSoyadi', drAdiSoyadi);
    formData.append('diplomaNo', diplomaNo);
    formData.append('tescilNo', tescilNo);
    formData.append('tani', tani);
    formData.append('ilaclar', ilaclar);
    formData.append('receteNo', receteNo);
    // Dosyaları FormData'ya ekleyin
    for (let i = 0; i < receteImza.length; i++) {
      formData.append('receteImza', receteImza[i]);
    }
    // API çağrısı
    // ...
    try {
      await apiManager.post('/recete', formData ).then(data=>{
        if (data) {
            console.log('Hasta kaydı başarılı.');
            alert('Form başarıyla oluşturuldu.');
            window.location.reload();
        } else {
            console.error('Hata oluştu.');
        }
        })
    } catch (error) {
        console.error('Sunucu hatası:', error);
    }  

      };

  
  const handleReset = () => {
    setTarih('');
    setAdi('');
    setSoyadi('');
    setTcKimlikNo('');
    setAdres('');
    setDrAdiSoyadi('');
    setDiplomaNo('');
    setTescilNo('');
    setTani('');
    setIlaclar('');
    setReceteNo('');
    setReceteImza('');
  };

  return (
    <div className="container-fluid">
      <div className="row">
      <div className="col-md-2 no-padding-left">
  <LeftSidebar />
</div>
        <div className="col-md-10">
        <h2 style={{ backgroundColor: '#2c3e50',color:'white', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', fontSize:'1.6rem'}}>Reçete Formu</h2>
          <form onSubmit={handleSubmit} className="row g-3">
            <div className="col-md-6">
              <label htmlFor="hastaTakipNo" className="form-label">Hasta Takip No:<span className="required">*</span></label>
              <input type="text" className="form-control" id="hastaTakipNo" value={hastaTakipNo} readOnly />

              <label htmlFor="tarih" className="form-label">Tarih:<span className="required">*</span></label>
              <input type="date" className="form-control" id="tarih" value={tarih} onChange={(e) => setTarih(e.target.value)} required/>

              <label htmlFor="adi" className="form-label">Adı:<span className="required">*</span></label>
              <input type="text" className="form-control" id="adi" value={adi} onChange={(e) => setAdi(e.target.value)} required/>

              <label htmlFor="soyadi" className="form-label">Soyadı:<span className="required">*</span></label>
              <input type="text" className="form-control" id="soyadi" value={soyadi} onChange={(e) => setSoyadi(e.target.value)} required/>

              <label htmlFor="tcKimlikNo" className="form-label">TC Kimlik No:<span className="required">*</span></label>
              <input type="text" className="form-control" id="tcKimlikNo" value={tcKimlikNo} onChange={(e) => setTcKimlikNo(e.target.value)}required />

              <label htmlFor="adres" className="form-label">Adres</label>
              <input type="text" className="form-control" id="adres" value={adres} onChange={(e) => setAdres(e.target.value)} />
              
              <label htmlFor="drAdiSoyadi" className="form-label">Dr. Adı Soyadı</label>
              <input type="text" className="form-control" id="drAdiSoyadi" value={drAdiSoyadi} onChange={(e) => setDrAdiSoyadi(e.target.value)} />
            </div>

            <div className="col-md-6">

              <label htmlFor="diplomaNo" className="form-label">Diploma No</label>
              <input type="text" className="form-control" id="diplomaNo" value={diplomaNo} onChange={(e) => setDiplomaNo(e.target.value)} />

              <label htmlFor="tescilNo" className="form-label">Tescil No</label>
              <input type="text" className="form-control" id="tescilNo" value={tescilNo} onChange={(e) => setTescilNo(e.target.value)} />

              <label htmlFor="tani" className="form-label">Tanı</label>
              <textarea className="form-control" id="tani" value={tani} onChange={(e) => setTani(e.target.value)}></textarea>

              <label htmlFor="ilaclar" className="form-label">İlaçlar</label>
              <textarea className="form-control" id="ilaclar" value={ilaclar} onChange={(e) => setIlaclar(e.target.value)}></textarea>

              <label htmlFor="receteNo" className="form-label">Reçete No</label>
              <input type="text" className="form-control" id="receteNo" value={receteNo} onChange={(e) => setReceteNo(e.target.value)} />

              <label htmlFor="file-upload" className="form-label">Reçete İmza:</label>
              <input id="file-upload" type="file" className="form-control" multiple onChange={handleFileChange} />
              {receteImza.length > 0 && (
                <div className="mt-2">
                  <strong>Seçilen Reçete İmza:</strong>
                  <ul>
                    {receteImza.map((file, index) => (
                      <li key={index}>
                        {file.name} <button type="button" className="btn btn-danger btn-sm" onClick={() => handleFileRemove(index)}>Sil</button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            <div className="col-12">
              <button type="submit" className="btn btn-primary me-2">Formu Gönder</button>
              <button type="button" className="btn btn-secondary" onClick={handleReset}>Sıfırla</button>
            </div>
          </form>

        </div>
      </div>
    </div>
  );
};

export default Recete;
