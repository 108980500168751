import React, { useEffect, useState } from 'react';
import apiManager from '../service/apiManager';
import LeftSidebar from '../components/LeftSidebar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import Modal from 'react-modal'
import { Button, Modal as BootstrapModal } from 'react-bootstrap';
import './css/EmailList.css'

Modal.setAppElement('#root');


const EmailList = () => {
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null); // Seçilen e-postayı saklamak için
  const [isModalOpen, setIsModalOpen] = useState(false); // Modalın açık olup olmadığını kontrol etmek için

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const data = await apiManager.get('/emails');
        if (data) {
          setEmails(data.reverse());
        } else {
          console.error('Email Yüklenemedi.');
        }
      } catch (error) {
        console.error('Sunucu hatası:', error);
      }
    };

    fetchEmails();
  }, []);

  // E-postaları okundu olarak işaretleme fonksiyonu
  const markAsRead = async (id) => {
    try {
      // Bu örnekte, e-postanın okundu olarak işaretlenmesi için PATCH isteği gönderiliyor.
      // API'nizin bu isteği nasıl kabul ettiğine bağlı olarak, bu kısım değişiklik gösterebilir.
      await apiManager.patch(`/emails/mark-read/${id}`, {
        isRead: 1 // E-postayı okundu olarak işaretle
      });


      // E-posta başarıyla okundu olarak işaretlendiyse, UI'da bu değişikliği yansıtmak için
      // e-posta listesini güncelleyebilirsiniz. Örneğin:
      const updatedEmails = emails.map(email => {
        if (email.id === id) {
          return { ...email, isRead: 1 }; // Güncellenen e-postanın isRead durumunu 1 yap
        }
        return email;
      });
      setEmails(updatedEmails); // State'i güncellenmiş e-posta listesi ile güncelle

      // alert('E-posta okundu olarak işaretlendi');

    } catch (error) {
      console.error('Error marking email as read:', error);
    }
  };

  const fetchEmails = async () => {
    try {
      const data = await apiManager.get('/emails');
      if (data) {
        setEmails(data.reverse());
      } else {
        console.error('Email Yüklenemedi.');
      }
    } catch (error) {
      console.error('Sunucu hatası:', error);
    }
  }
  // E-posta silme fonksiyonu
  const deleteEmail = async (uniqueId) => {
    try {
      await apiManager.del(`/emails/${uniqueId}`);

      alert('E-posta başarıyla silindi.');
      await fetchEmails(); // E-posta listesini yenile

    } catch (error) {
      console.error('E-postaları silme hatası:', error);
    }
  };
  const openModal = (email) => {
    setSelectedEmail(email); // Seçilen e-postayı ayarla
    setIsModalOpen(true); // Modalı aç
    if (!email.isRead) {
      markAsRead(email.id); // Eğer e-posta okunmadıysa, okundu olarak işaretle
    }
  };

  const EmailModal = ({ isOpen, onRequestClose, email }) => {
    if (!email) return null;

    // Düz metni HTML yapısına çeviren fonksiyon
    // Bu örnekte basit bir dönüşüm yapılıyor. İhtiyaca göre düzenlenebilir.
    const renderEmailBody = (body) => {
      // URL'leri tanımak için basit bir regex
      const urlPattern = /(https?:\/\/[^\s]+)/g;
      // Basit bir başlık tanıma (örneğin, "Başlık:" ile başlayan satırlar)
      const headerPattern = /^Başlık:\s*(.*)/;

      return body.split('\n').map((line, index) => {
        // Başlık desenini kontrol et
        const headerMatch = line.match(headerPattern);
        if (headerMatch) {
          return <h4 key={index} className="mt-3 mb-2">{headerMatch[1]}</h4>;
        }

        // URL desenini kontrol et ve link olarak dönüştür
        const newLine = line.replace(urlPattern, (url) => {
          return `<a href="${url}" target="_blank" class="text-info">${url}</a>`;
        });

        // URL'lerin HTML olarak render edilmesini sağla
        if (newLine !== line) {
          return <p key={index} className="mb-2" dangerouslySetInnerHTML={{ __html: newLine }}></p>;
        }

        // Diğer her şey normal paragraf olarak dönüştürülür
        return <p key={index} className="mb-2">{line}</p>;
      });
    };


    return (
      <BootstrapModal show={isOpen} onHide={onRequestClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title id="contained-modal-title-vcenter">
            {email.subject}
          </BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          <p className="text-muted">Gönderen: {email.from}</p>
          <div className="email-body mt-3">
            {renderEmailBody(email.body)}
            {/* Email body işleme fonksiyonunuzun çağrısı burada olacak */}
          </div>
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
          <Button variant="secondary" onClick={onRequestClose}>Kapat</Button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    );
  };
  const formatDate = (datetimeStr) => {
    const date = new Date(datetimeStr);
    return new Intl.DateTimeFormat('tr-TR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }).format(date);
  };


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2 no-padding-left">
          <LeftSidebar />
        </div>
        <div className="col-md-10">
        <h2 style={{ backgroundColor: '#2c3e50', color: 'white', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', fontSize: '1.6rem' }}>E-Postalar</h2>
        <a href="https://profdrburhankabay.com/roundcube" target="_blank" rel="noopener noreferrer" className="btn btn-success" style={{ marginBottom: '10px' }}>E-Posta Arayüzüne Gitmek İçin Tıklayınız.</a>
          <TableContainer component={Paper} style={{ maxHeight: '80vh', overflowX: 'auto' }}>
  <Table size="small"> {/* MUI Table için "small" boyutunu kullanabilirsiniz */}
    <TableHead>
      <TableRow>
        <TableCell style={{ padding: '4px 12px' }}>İşlemler</TableCell>
        <TableCell style={{ padding: '4px 12px' }}>Konu</TableCell>
        <TableCell style={{ padding: '4px 12px' }}>Gönderen</TableCell>
        <TableCell style={{ padding: '4px 12px' }}>Tarih</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {emails.map((email) => (
        <TableRow
          key={email.uniqueId}
          hover
          onClick={() => openModal(email)}
          className={email.isRead ? "email-read" : "email-unread"}
          style={{ cursor: 'pointer' }} // Satıra tıklandığında el işareti göster
        >
          <TableCell style={{ padding: '4px 12px' }}>
            <IconButton onClick={(event) => {
              event.stopPropagation(); // Olayın üst elementlere yayılmasını durdur
              deleteEmail(email.uniqueId);
            }}>
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={(event) => {
              event.stopPropagation();
              markAsRead(email.id);
            }}>
              {email.isRead ? <MarkEmailReadIcon /> : <MailOutlineIcon />}
            </IconButton>
          </TableCell>
          <TableCell style={{ padding: '4px 12px' }}>{email.subject}</TableCell>
          <TableCell style={{ padding: '4px 12px' }}>{email.from}</TableCell>
          <TableCell style={{ padding: '4px 12px' }}>{formatDate(email.date)}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>
          {/* Modal bileşenini koşullu olarak göster */}
          <EmailModal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            email={selectedEmail}
          />
        </div>
      </div>
    </div>
  );
};

export default EmailList;
