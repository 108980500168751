import { useEffect, useState } from 'react';
import LeftSidebar from '../components/LeftSidebar';
import apiManager from '../service/apiManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

function formatDate(dateStr) {
    if (!dateStr) return '';

    const date = new Date(dateStr);
    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();
    const year = date.getFullYear();

    day = day.length < 2 ? '0' + day : day;
    month = month.length < 2 ? '0' + month : month;

    return `${day}/${month}/${year}`;
}

const DetayPaneli = ({ kayit, kapat }) => {
    // Eğer kayıt null ise, boş bir div döndür
    const yazdirKayitDetaylari = () => {
        const yeniSekme = window.open('', '_blank');
        yeniSekme.document.open();

        let htmlContent = `
        <html>
            <head>
            <title>Seçilen Kayıt Detayı</title>
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">
            <style>
                body { padding: 20px; }
                .table-responsive {
                    display: block;
                    width: 100%;
                    overflow-x: auto;
                    -webkit-overflow-scrolling: touch;
                }
                .table {
                    width: 100% !important; /* Genişliği %100 olarak ayarla */
                    margin-bottom: 20px;
                    table-layout: fixed; /* Tablonun layoutunu sabitle */
                }
                .table th, .table td {
                    word-wrap: break-word; /* Uzun metinleri kır */
                    overflow-wrap: break-word; /* İçerik taşarsa alt satıra geç */
                }
                .dosyalar-section { margin-top: 20px; }
            </style>
        </head>
        <body>
                <h2 class="mb-3">Seçilen Kayıt Detayları</h2>
                <table class="table table-bordered">
                    <tbody>
                        <tr><th>Üye Kayıt No</th><td>${kayit.uyeId}</td></tr>
                        <tr><th>Unvanı</th><td>${kayit.unvani}</td></tr>
                        <tr><th>Üye Olunan Tarih</th><td>${formatDate(kayit.uyeOlunanTarih)}</td></tr>
                        <tr><th>Üyelik Tipi</th><td>${(kayit.tipi)}</td></tr>
                        <tr><th>Telefon No</th><td>${(kayit.telefonNo)}</td></tr>
                        <tr><th>E-Posta Adresi</th><td>${kayit.email}</td></tr>
                    </tbody>
                </table>
                <script>
                    window.onload = function() {
                        window.print();
                    };
                </script>
            </body>
        </html>
    `;

        yeniSekme.document.write(htmlContent);
        yeniSekme.document.close();
    };
    // Eğer kayıt null ise, boş bir div döndür
    if (!kayit) return <div></div>;

    return (
        <div className={`detay-panel ${kayit ? 'acik' : ''}`}>
            <div className="card-header">
                <h3 className="card-title mb-2">Seçilen Kayıt Detayları</h3>
                <div data-bs-theme="dark">
                    <button className="btn btn-primary me-2" onClick={() => yazdirKayitDetaylari(kayit)}>Yazdır</button>
                    <button onClick={kapat} class="btn btn-danger" aria-label="Close">Kapat</button>

                </div>
            </div>
            <div className="card-body" style={{ maxWidth: '100%', overflowWrap: 'break-word' }}>
                <p>Hasta Takip No: {kayit.hastaTakipNo}</p>
                <p>Üye Kayıt No: {kayit.uyeId}</p>
                <p>Unvanı: {kayit.unvani}</p>
                <p>Üye Olunan Tarih: {formatDate(kayit.uyeOlunanTarih)}</p>
                <p>Üyelik Tipi: {kayit.tipi}</p>
                <p>Telefon No: {kayit.telefonNo}</p>
                <p>E-Posta Adresi: {(kayit.email)}</p>
                {/* Diğer detaylar */}
            </div>
        </div>
    );
};

const UyeTakipRaporu = () => {
    const [kayitlar, setKayitlar] = useState([]);
    const [filtreler, setFiltreler] = useState(
        {
            uyeId: '',
            unvani: '',
            tipi: '',
            telefonNo: '',
            email: ''
        }
    );

    const [secilenKayit, setSecilenKayit] = useState(null);
    const [duzenlemeFormuGoster, setDuzenlemeFormuGoster] = useState(false);
    const [duzenlenecekKayit, setDuzenlenecekKayit] = useState({});
    const [baslangicUyeOlunanTarihi, setBaslangicUyeOlunanTarihi] = useState('');
    const [bitisUyeOlunanTarihi, setBitisUyeOlunanTarihi] = useState('');
    // const [receteler, setReceteler] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                await apiManager.get('/uye-kayit-raporu').then((data) => {
                    if (data) {
                        setKayitlar(data);
                    } else {
                        console.error('Veri yüklenemedi.');
                    }
                });
            } catch (error) {
                console.error('Sunucu hatası:', error);
            }
        };

        fetchData();
    }, []);

    // useEffect(() => {
    //   const fetchReceteler = async () => {
    //     try {
    //       const response = await fetch('http://localhost:5000/api/recete-raporu');
    //       if (!response.ok) throw new Error('Reçeteler yüklenemedi');
    //       const data = await response.json();
    //       setReceteler(data);
    //     } catch (error) {
    //       console.error('Reçeteleri yüklerken bir hata oluştu:', error);
    //     }
    //   };

    //   fetchReceteler();
    // }, []);

    const handleUpdate = async (e) => {
        e.preventDefault();

        try {
            // `apiManager` kullanarak `PATCH` isteğini yap
            await apiManager.patch(`/uye-kayit/${duzenlenecekKayit.uyeId}`, duzenlenecekKayit);

            // Güncellenmiş kayıt bilgisi alındıktan sonra tüm kayıtları yeniden çek
            await fetchAllRecords();

            // Düzenleme formunu kapat
            setDuzenlemeFormuGoster(false);

            // Kullanıcıya başarılı güncelleme hakkında bilgi verin
            alert('Kayıt başarıyla güncellendi.');
        } catch (error) {
            console.error('Güncelleme sırasında hata oluştu:', error);
            alert('Güncelleme işlemi sırasında bir hata oluştu.');
        }
    };

    const fetchAllRecords = async () => {
        try {
            await apiManager.get('/uye-kayit-raporu').then((data) => {
                if (data) {
                    setKayitlar(data);
                } else {
                    console.error('Veri yüklenemedi.');
                }
            });
        } catch (error) {
            console.error('Sunucu hatası:', error);
        }
    }





    const handleDelete = async (kayitId) => {
        if (window.confirm(`Kaydı silmek istediğinize emin misiniz? ID: ${kayitId.uyeId}`)) {
            try {
                // `apiManager` kullanarak `DELETE` isteğini yap
                await apiManager.del(`/uye-kayit/${kayitId.uyeId}`);

                console.log('Kayıt başarıyla silindi');
                await fetchAllRecords(); // Tüm kayıtları yeniden çek
            } catch (error) {
                console.error('Silme işlemi sırasında hata oluştu:', error);
            }
        }
    };



    const handleRowClick = (kayit) => {
        setSecilenKayit(kayit);
    };
    const kapatDetayPaneli = () => {
        setSecilenKayit(null);
    };
    const handleFilterChange = (e) => {
        setFiltreler({ ...filtreler, [e.target.name]: e.target.value.toLowerCase() });
    };
    const handleEdit = (kayit) => {
        // Tarih formatını düzelt
        const duzenlenmisKayit = {
            ...kayit,
            uyeOlunanTarih: formatDateToInput(kayit.uyeOlunanTarih),
        };

        setDuzenlenecekKayit(duzenlenmisKayit);
        setDuzenlemeFormuGoster(true);
    };
    const kapatGuncellemePaneli = () => {
        setDuzenlemeFormuGoster(false);
    };

    // const sendEmail = async (kayit) => {
    //   if (kayit.tipi === "Eczane") {
    //     console.log("Üye ID: ", kayit.uyeId);
    //     const receteNumarasi = prompt("Lütfen göndermek istediğiniz reçete numarasını girin:");
    //     const secilenRecete = receteler.find(recete => recete.hastaTakipNo.toString() === receteNumarasi);


    //     if (!secilenRecete) {
    //       alert('Geçerli bir reçete numarası girilmedi.');
    //       return;
    //     }

    //     // Seçilen reçeteyle e-posta içeriğini oluştur
    //     const emailContent = `
    //       Eczane Adı: ${kayit.unvani}
    //       Reçete Bilgileri:
    //       Adı Soyadı: ${secilenRecete.adi} ${secilenRecete.soyadi}
    //       TC Kimlik No: ${secilenRecete.tcKimlikNo}
    //       Reçete No: ${secilenRecete.receteNo}
    //     `;

    //     // E-posta gönderme işlemi
    //     try {
    //       const response = await fetch('http://localhost:5000/api/send-email', {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({
    //           to: kayit.email,
    //           subject: "Reçete Bilgileri",
    //           text: emailContent,
    //         }),
    //       });

    //       if (!response.ok) {
    //         throw new Error('E-posta gönderilemedi');
    //       }
    //       alert('E-posta başarıyla gönderildi.');
    //     } catch (error) {
    //       console.error('E-posta gönderme sırasında bir hata oluştu:', error);
    //       alert('E-posta gönderme işlemi sırasında bir hata oluştu.');
    //     }
    //   }
    // };


    // // ReceteRaporu'ndan veri almak için örnek fonksiyon (API'ye bağlı olarak değişebilir)
    // const fetchReceteBilgileri = async (kayit) => {
    //   try {
    //     const response = await fetch(`http://localhost:5000/api/recete-bilgileri/${kayit.uyeId}`);
    //     if (!response.ok) {
    //       console.error('Reçete bilgileri alınamadı.');
    //       return null;
    //     }
    //     return await response.json();
    //   } catch (error) {
    //     console.error('API hatası:', error);
    //     return null;
    //   }
    // };






    function formatDateToInput(dateStr) {
        if (!dateStr) return '';

        const date = new Date(dateStr);
        const year = date.getFullYear();
        let month = (date.getMonth() + 1).toString();
        let day = date.getDate().toString();

        month = month.length < 2 ? '0' + month : month;
        day = day.length < 2 ? '0' + day : day;

        return `${year}-${month}-${day}`;
    }

    const yazdirTumKayitlari = () => {
        const yeniSekme = window.open('', '_blank');
        yeniSekme.document.open();

        let htmlContent = `
        <html>
        <head>
            <title>Tüm Kayıtlar</title>
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">
            <style>
                body { font-size: 10px; }
                .table { width: 100%; table-layout: fixed; }
                .table th, .table td { word-wrap: break-word; overflow-wrap: break-word; }
                @media print {
                    body { font-size: 8px; }
                    .table { font-size: 8px; }
                }
            </style>
        </head>
        <body>
            <h2>Üye Kayıt Raporları</h2>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Üye Kayıt No</th>
                        <th>Unvanı</th>
                        <th>Üye Olunan Tarih</th>
                        <th>Üyelik Tipi</th>
                        <th>Telefon No</th>
                        <th>E-Posta Adresi</th>
                    </tr>
                </thead>
                <tbody>`;

        kayitlar.forEach(kayit => {
            htmlContent += `
                    <tr>
                        <td>${kayit.uyeId}</td>
                        <td>${kayit.unvani}</td>
                        <td>${formatDate(kayit.uyeOlunanTarih)}</td>
                        <td>${kayit.tipi}</td>
                        <td>${(kayit.telefonNo)}</td>
                        <td>${kayit.email}</td>
                    </tr>`;
        });

        htmlContent += `
                </tbody>
            </table>
            <script>
                window.onload = function() {
                    window.print();
                };
            </script>
        </body>
        </html>`;

        yeniSekme.document.write(htmlContent);
        yeniSekme.document.close();
    };


    const filteredKayitlar = kayitlar.filter(kayit => {
        let uyeOlunanTarihBaslangic = baslangicUyeOlunanTarihi ? new Date(baslangicUyeOlunanTarihi) : new Date('1900-01-01');
        uyeOlunanTarihBaslangic.setHours(0, 0, 0, 0);
        let uyeOlunanTarihBitis = bitisUyeOlunanTarihi ? new Date(bitisUyeOlunanTarihi) : new Date('2100-12-31');
        uyeOlunanTarihBitis.setHours(23, 59, 59, 999);

        const kayitUyeOlunanTarih = new Date(kayit.uyeOlunanTarih);
        const uyeOlunanTarihinde = kayitUyeOlunanTarih >= uyeOlunanTarihBaslangic && kayitUyeOlunanTarih <= uyeOlunanTarihBitis;

        const digerFiltrelerUyuyor = Object.keys(filtreler).every(key => {
            if (key === 'tipi' || key === 'telefonNo' || key === 'email') {
                // Eğer filtre değeri boşsa (yani kullanıcı bu filtre için bir değer girmemişse), tüm kayıtları kabul et
                if (!filtreler[key]) return true;
                // Eğer kayıtta bu alan yoksa veya boşsa, bu kaydı kabul etme
                if (!kayit[key]) return false;
            }
            // Genel kontrol
            return kayit[key] ? kayit[key].toString().toLowerCase().includes(filtreler[key].toLowerCase()) : true;
        });

        return uyeOlunanTarihinde && digerFiltrelerUyuyor;
    });


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-2 no-padding-left">
                    <LeftSidebar />
                </div>
                <div className="col-md-10">
                    <h2 style={{ backgroundColor: '#2c3e50', color: 'white', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', fontSize: '1.6rem' }}>Üye Kayıt Raporu</h2>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px', padding: '5px 10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9' }}>
                        <p style={{ margin: 0 }}>Kayıt Detayları ve Yazdırma İçin İlgili Kaydın Üzerine Tıklayınız.</p>
                        <button type="button" onClick={yazdirTumKayitlari} className="btn btn-primary">Tüm Kayıtları Yazdır</button>
                    </div>
                    <div className="filter-section" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        {/* Doğum Tarihi için Başlangıç ve Bitiş Tarihi Seçicileri */}
                        <div style={{ marginRight: '20px' }}>
                            <label htmlFor="baslangicUyeOlunanTarihi">Üye Olunan Tarih Başlangıcı:</label>
                            <input
                                type="date"
                                id="baslangicUyeOlunanTarihi"
                                value={baslangicUyeOlunanTarihi}
                                onChange={(e) => setBaslangicUyeOlunanTarihi(e.target.value)}
                                className="form-control"
                            />
                        </div>
                        <div style={{ marginRight: '20px' }}>
                            <label htmlFor="bitisUyeOlunanTarihi">Üye Olunan Tarih Bitişi:</label>
                            <input
                                type="date"
                                id="bitisUyeOlunanTarihi"
                                value={bitisUyeOlunanTarihi}
                                onChange={(e) => setBitisUyeOlunanTarihi(e.target.value)}
                                className="form-control"
                            />
                        </div>
                    </div>
                    {duzenlemeFormuGoster && (
                        <div className="update-panel card">
                            <div className="card-header">
                                <h3>Kayıt Güncelle</h3>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleUpdate} className="row g-3">
                                    <div className="col-md-6">
                                        <label htmlFor="unvani" className="form-label">Unvanı</label>
                                        <input type="text" className="form-control" id="unvani" value={duzenlenecekKayit.unvani} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, unvani: e.target.value })} />

                                        <label htmlFor="uyeOlunanTarih" className="form-label">Üye Olunan Tarih</label>
                                        <input type="date" className="form-control" id="uyeOlunanTarih" value={duzenlenecekKayit.uyeOlunanTarih} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, uyeOlunanTarih: e.target.value })} />

                                        <label htmlFor="tipi" className="form-label">Üyelik Tipi</label>
                                        <input type="text" className="form-control" id="tipi" value={duzenlenecekKayit.tipi} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, tipi: e.target.value })} />
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="telefonNo" className="form-label">Telefon No</label>
                                        <input type="text" className="form-control" id="telefonNo" value={duzenlenecekKayit.telefonNo} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, telefonNo: e.target.value })} />

                                        <label htmlFor="email" className="form-label">E-Posta Adresi</label>
                                        <input type="email" className="form-control" id="email" value={duzenlenecekKayit.email} onChange={(e) => setDuzenlenecekKayit({ ...duzenlenecekKayit, email: e.target.value })} />
                                    </div>

                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary me-2">Güncelle</button>
                                        <button type="button" onClick={kapatGuncellemePaneli} className="btn btn-danger">Kapat</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    )}
                    <DetayPaneli kayit={secilenKayit} kapat={kapatDetayPaneli} />
                    <div className="table-responsive" style={{ maxHeight: '75vh', overflowX: 'auto' }}>
                        <table className="table table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>İşlemler</th>
                                    <th>Üye Kayıt No<input type="text" name="uyeId" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>Unvanı<input type="text" name="unvani" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>Üye Olunan Tarih</th>
                                    <th>Üyelik Tipi<input type="text" name="tipi" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>Telefon No<input type="text" name="telefonNo" onChange={handleFilterChange} className="form-control" /></th>
                                    <th>E-Posta Adresi<input type="text" name="email" onChange={handleFilterChange} className="form-control" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredKayitlar.map((kayit, index) => (
                                    <tr key={index} onClick={() => handleRowClick(kayit)} className="clickable-row">
                                        <td>
                                            <button onClick={(e) => { e.stopPropagation(); handleEdit(kayit); }} className="btn btn-primary btn-sm me-1">
                                                <FontAwesomeIcon icon={faEdit} />
                                            </button>
                                            <button onClick={(e) => { e.stopPropagation(); handleDelete(kayit); }} className="btn btn-danger btn-sm">
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </button>
                                            {/* <button onClick={(e) => { e.stopPropagation(); sendEmail(kayit); }} className="btn btn-info btn-sm">E-Posta Gönder</button> */}
                                        </td>
                                        <td>{kayit.uyeId}</td>
                                        <td>{kayit.unvani}</td>
                                        <td>{formatDate(kayit.uyeOlunanTarih)}</td>
                                        <td>{kayit.tipi}</td>
                                        <td>{kayit.telefonNo}</td>
                                        <td>{kayit.email}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default UyeTakipRaporu;
