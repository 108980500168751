// src/pages/HastaKayitFormu.js
import React, { useState,useEffect } from 'react';
import LeftSidebar from '../components/LeftSidebar';
import apiManager from '../service/apiManager';



const UyeKayitFormu = () => {
  
  // State'ler
  const [uyeId, setUyeId] = useState('');
  const [unvani, setUnvani] = useState('');
  const [uyeOlunanTarih, setUyeOlunanTarih] = useState(null);
  const [tipi, setTipi] = useState('');
  const [telefonNo, setTelefonNo] = useState('');
  const [email, setEmail] = useState('');


  // Form gönderildiğinde yapılacak işlemler
  useEffect(() => {
    const fetchHastaTakipNo = async () => {
      try {
        await apiManager.get('/yeni-uye-no').then((data) => {
            if (data) {
                setUyeId(data.uyeId);
            } else {
                console.error('Veri yüklenemedi.');
            }
        });
    } catch (error) {
        console.error('Sunucu hatası:', error);
    }
    };

    fetchHastaTakipNo();
  }, []);
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
        uyeId,
        unvani,
        uyeOlunanTarih,
        tipi,
        telefonNo,
        email
    };

    try {
      await apiManager.post('/uye-kayit', formData ).then(data=>{
        if (data) {
            console.log('Üye kaydı başarılı.');
            alert('Form başarıyla oluşturuldu.');
            window.location.reload();
        } else {
            console.error('Hata oluştu.');
        }
      });
  } catch (error) {
      console.error('Sunucu hatası:', error);
  }  
};
const handleReset = () => {
    setUnvani('');
    setUyeOlunanTarih(null);
    setTipi('');
    setTelefonNo('');
    setEmail('');
  };

  
return (
    <div className="container-fluid">
      <div className="row">
      <div className="col-md-2 no-padding-left">
  <LeftSidebar />
</div>
        <div className="col-md-10">
        <h2 style={{ backgroundColor: '#2c3e50',color:'white', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', fontSize:'1.6rem'}}>Üye Kayıt Formu</h2>
          <form onSubmit={handleSubmit} className="row g-3">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="uyeId" className="form-label">Üye Kayıt No:<span className="required">*</span></label>
                <input type="text" className="form-control" id="uyeId" value={uyeId} readOnly />
              </div>
              <div className="mb-3">
                <label htmlFor="unvani" className="form-label">Unvanı</label>
                <input type="text" className="form-control" id="unvani" value={unvani} onChange={(e) => setUnvani(e.target.value)} />
              </div>
              <div className="mb-3">
                <label htmlFor="uyeOlunanTarih" className="form-label">Üye Olunan Tarih</label>
                <input type="date" className="form-control" id="uyeOlunanTarih" value={uyeOlunanTarih} onChange={(e) => setUyeOlunanTarih(e.target.value)} />
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="tipi" className="form-label">Üyelik Tipi</label>
                <select className="form-control" id="tipi" value={tipi} onChange={(e) => setTipi(e.target.value)}>
                <option value="">Seçiniz...</option>
                  <option value="Laboratuvar">Laboratuvar</option>
                  <option value="Eczane">Eczane</option>
                  <option value="Doktor">Doktor</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="telefonNo" className="form-label">Telefon No</label>
                <input type="text" className="form-control" id="telefonNo" value={telefonNo} onChange={(e) => setTelefonNo(e.target.value)} />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">E-Posta Adresi</label>
                <input type="email" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
            </div>

            <div className="col-12">
            <button type="submit" className="btn btn-primary me-2">Formu Oluştur</button>
              <button type="reset" onClick={handleReset} className="btn btn-secondary">Sıfırla</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UyeKayitFormu;
